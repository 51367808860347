<!--The content below is only a placeholder and can be replaced.-->
<opr-loading-indicator></opr-loading-indicator>
<opr-notification></opr-notification>
<p-confirmDialog></p-confirmDialog>
<p-dialog header="Confirmation" [(visible)]="display" [style]="{width: '50vw'}" 
    [baseZIndex]="10000" (onShow)=onShow()>
    You will be logged out in {{countdownStart}}s. Do you want continue ?
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="accept()" label="Yes"></button>
        <button type="button" pButton icon="pi pi-times" (click)="forceLogout()" label="No" class="ui-button-secondary"></button>
    </p-footer>
</p-dialog>
<p-toast [style]="{
    color: '#fff',
    background: '#007ad9' }"
    position="bottom-right" key="tc">
</p-toast>

<div style="height: 100vh">
    <router-outlet></router-outlet>
</div>
