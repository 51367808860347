import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { AppUrl } from '@app/_config/constant';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenValidationGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isFirstLogin()) {
      return true;
    } else {
      return this.authService.isTokenValid().pipe(
        tap((e) => {
          if (!e) {
            this.router.navigate([AppUrl.LOGIN]);
          }
        })
      );
    }
  }
}
