import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';

@Injectable()
export class LoadingIndicatorService {

    statusSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    status$ = this.statusSubject.asObservable();

    inProcess = false;

    constructor() {
    }

    setStatus(status: boolean, timeout = 0) {
        if (timeout) {
            this.inProcess = true;
            if (status) {
              this.statusSubject.next(true);
            }
            timer(timeout).subscribe(done => {
              this.inProcess = false;
              this.statusSubject.next(false);
            });
        } else {
            if (!status && this.inProcess) {
                return;
            }
           this.statusSubject.next(status);
        }
    }

    setInLoading(status: boolean) {
        this.inProcess = status;
    }

    getStatus() {
        return this.status$;
    }
}
