import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { BillingSearchOption } from '@app/_shared/models/billing-search-option';

@Injectable()
export class DayCareService {
  private readonly URL = environment.api.server;

  constructor(private readonly http: HttpClient) {}

  getAuthorizationInfoList(options?: BillingSearchOption): Observable<any> {
    options.timeRange.startTime = moment.utc(options.timeRange.startTime).local().format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment.utc(options.timeRange.endDate).local().format('MM/DD/YYYY 23:59:59');
    return this.http.post(`${this.URL}/day-care/authorization/list`, options, { });
  }

  getClaimList(options?: BillingSearchOption): Observable<any> {
    options.timeRange.startTime = moment.utc(options.timeRange.startTime).local().format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment.utc(options.timeRange.endDate).local().format('MM/DD/YYYY 23:59:59');
    return this.http.post(`${this.URL}/day-care/claim/list`, options, { });
  }
}
