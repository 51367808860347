import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionAllowedDirective } from './directive';
import { AuthGuard, AuthService, TokenValidationGuard } from './service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PermissionAllowedDirective
  ],
  exports: [
    PermissionAllowedDirective
  ]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        AuthService,
        AuthGuard,
        TokenValidationGuard,
      ],
    };
  }

  static forChild(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [],
    };
  }
}
