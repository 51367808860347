import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

export enum NotificationType {
    SUCCESS = 'success',
    WARNING = 'warn',
    ERROR = 'error',
    INFO = 'info'
}

@Injectable()
export class NotificationService {
    constructor(private messageService: MessageService) {
    }

    open(options: { type?: NotificationType | string, title?: string, body?: string }) {
        this.messageService.add({
            severity: options.type || 'success',
            summary: options.title || '',
            detail: options.body || ''
        });
    }

    clear() {
        this.messageService.clear();
    }
}
