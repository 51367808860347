import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PrimeNGModule } from '@app/_primeng';
import { CookieService } from 'ngx-cookie-service';
import {
  LoginComponent, Page404Component, SignUpComponent, ForgotPasswordComponent, ResetPasswordComponent,
  ForgotUsernameComponent, Page500Component
} from './components';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TimeoutInterceptor, LoaderInterceptor, AddHttpHeaderInterceptor } from './http-interceptor';
import {ConfirmationDialogService, LoadingIndicatorService} from './service';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { MessageService } from 'primeng/api';
import {SignupService} from "@app/_service/mas/signup.service";
import {FormValidatorService} from "@app/_service/form-validator/form-validator.service";
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContractDialogComponent } from './components/contract-dialog/contract-dialog.component';
import {SignaturePadModule} from "angular2-signaturepad";
import { ContractFormComponent } from './components/contract-form/contract-form.component';
import { TelContractFormComponent } from './components/tel-contract-form/tel-contract-form.component';
import { TechnologyContractFormComponent } from './components/technology-contract-form/technology-contract-form.component';
import {MaterialModule} from '@app/material';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        PrimeNGModule,
        MaterialModule,
        SignaturePadModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    declarations: [
        LoginComponent,
        Page404Component,
        Page500Component,
        SignUpComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ForgotUsernameComponent,
        LoadingIndicatorComponent,
        ConfirmationDialogComponent,
        ContractDialogComponent,
        ContractFormComponent,
        TelContractFormComponent,
        TechnologyContractFormComponent,
    ],
    exports: [
        HttpClientModule,
        TranslateModule,
        PrimeNGModule,
        MaterialModule,
        LoginComponent,
        Page404Component,
        Page500Component,
        SignUpComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ForgotUsernameComponent,
        LoadingIndicatorComponent,
        ConfirmationDialogComponent,
        ContractFormComponent,
        TelContractFormComponent,
        TechnologyContractFormComponent,
    ],
    providers: [
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AddHttpHeaderInterceptor, multi: true },
        LoadingIndicatorService,
        MessageService,
        SignupService, FormValidatorService,
        ConfirmationDialogService
    ]
})
export class CoreModule { }
