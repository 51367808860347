import * as _ from 'lodash';
import { State } from '@ngxs/store';

// TODO may use ngxs later
@State<String[]>({
    name: 'app',
    defaults: []
})
export class AppState {
}
