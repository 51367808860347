import {Websiteinfo} from './websiteinfo.model';

export class BillingUser {


  constructor() {
    this.serviceFrequencys = new Array();
    this.websiteInfos = new Array();
  }

  medicaidProviderNum: string;
  email: string;
  oldEmail: string;

  eligibility: boolean;
  eligibilityServiceRateUnder: number;
  eligibilityServiceRateOver: number;
  eligibilityServiceOver: number;
  signOff: boolean;
  signOffServiceRate: number;
  generalBilling: boolean;
  generalBillingServiceRateLeg: number;
  generalBillingServicePercent: number;
  correction: boolean;
  correctionServiceRateLeg: number;
  correctionServicePercent: number;
  lookBack: boolean;
  lookBackServiceRateLeg: number;
  lookBackServicePercent: number;
  congestionReport: boolean;
  congestionReportServiceRateUnder: number;
  congestionReportServiceRateOver: number;
  congestionReportServiceOver: number;
  tlcReport: boolean;
  monthlyFee: boolean;
  monthlyAmount: number;
  tlcReportServiceRateUnder: number;
  tlcReportServiceRateOver: number;
  tlcReportServiceOver: number;

  username: string;
  oldUsername: string;
  password: string;
  confirmPassword: string;
  status: any;
  
  serviceFrequencys: number[];
  websiteInfos: Websiteinfo[];
}
