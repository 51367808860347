export enum AppUrl {
    LOGIN = '/login',
    UPLOAD = '/app/upload',
    BILLER = '/app/upload/biller',
    BASE_UPLOAD = '/app/upload/base-upload',
    BASE_DOWNLOAD = '/app/upload/base-download',
    UPLOAD_FILE_PROCESS = '/app/fileprocess',
    MAS = '/app/mas',
    MAS_BILLING_LOG = '/app/mas/billing-log',
    MAS_PAYMENT_LOG = '/app/mas/payment-log',
    MAS_JOB = '/app/mas/scheduler',
    SOAP = '/app/mas/soap',
    RATE = '/app/mas/rate',
    ELIGIBILITY = '/app/mas/eligibility',
    SIGNOFF = '/app/mas/signoff',
    CORRECTION = '/app/mas/correction',
    CLAIM = '/app/mas/claim',
    LOOKBACK = '/app/mas/lookback',
    REPORT = '/app/mas/report',
    HELP = '/app/mas/help',
    INVOICE = '/app/mas/invoice',
    LOGISTICARE = '/app/logisticare',
    MTM = '/app/mtm',
    SENTRY = '/app/sentry',
    SENTRY_SCHEDULER = '/app/sentry/scheduler',
    FIDELIS = '/app/fidelis',
    CTS = '/app/cts',
    CENTREK = '/app/centrek',
    MY_ACCOUNT = '/app/myaccount',
    BILLING_USER = '/app/myaccount/billingUser',
    CHANGE_PASSWORD = '/app/myaccount/changePassword',
    PAYMENT_METHOD = '/app/myaccount/payment',
    MY_CONTRACT = '/app/myaccount/contract-detail/info',
    BASE_SEARCH = '/app/myaccount/base-info',
    BILLING_LOG = '/app/myaccount/billing-log',
    PAYMENT_LOG = '/app/myaccount/payment-log',
    TRACKING = '/app/myaccount/tracking',
    FILE_TRANSFER = '/app/file-transfer',
    FILE_TRANSFER_UPLOAD = '/app/file-transfer/upload',
    FILE_TRANSFER_DOWNLOAD = '/app/file-transfer/download',
    DRIVER_LIST = '/app/myaccount/driver-list',
    APP_INVOICE = '/app/invoice',
    DAYCARE = '/app/day-care',
    AUTHORIZATION_INFO = '/app/day-care/authorization-info',
    DAYCARE_CLAIM = '/app/day-care/claim',
    MODIVCARE = '/app/modivcare',
}
