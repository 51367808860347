import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SignaturePad} from 'angular2-signaturepad';
import {ContractService} from '@app/_service/contract/contract.service';
import {ContractType} from "@app/_config/constant/contract-type.enum";
import {NotificationService, NotificationType} from "@app/_core/service";

@Component({
  selector: 'opr-tel-contract-form',
  templateUrl: './tel-contract-form.component.html',
  styleUrls: ['./tel-contract-form.component.scss']
})
export class TelContractFormComponent  implements OnInit, OnChanges {


  @ViewChild('licenseeSignaturePad') licenseeSignaturePad: SignaturePad;
  @ViewChild('licensorSignaturePad') licensorSignaturePad: SignaturePad;
  @Output() updateFormSuccess = new EventEmitter();
  @Input() data;
  @Input() formHeight;
  formGroup: UntypedFormGroup;
  signaturePadOptions = {
    minWidth: 2,
    canvasWidth: 400,
    canvasHeight: 200,
  };

  showSignature = false;
  signatureUrl;
  enableSaveBtn = true;
  constructor(private fb: UntypedFormBuilder, private contractService: ContractService, private notificationService: NotificationService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].previousValue !== changes['data'].currentValue) {
      this.formGroup.patchValue({
        effectiveDate: '',
        licensee: this.data.baseName,
        licenseeCorporation: this.data.baseName,
        licenseePlaceOfBusiness: '',
        licenseeMedicaidId: this.data.medicaidProviderNum,
        corporateName: '',
        address: '',
        phone: this.data.phone,
        licenseeAuthorizedOfficer: '',
        licensorAuthorizedOfficer: '',
        licenseeEmail: this.data.email,
        licensorEmail: '',
        licenseePhone: this.data.phone,
        licensorPhone: '',
        licenseeDate: null,
        licensorDate: null,
        eligibility: this.data.eligibilityServiceRateUnder,
        generalBilling: this.data.generalBillingServicePercent,
        correction: this.data.correctionServicePercent,
        lookBack: this.data.lookBackServicePercent,
        congestionReport: this.data.congestionReportServiceRateUnder,
        tlcReport: this.data.tlcReportServiceRateUnder,
        numberOfUser: this.data.numberOfUser,
        telephoneServiceAreaInternational: this.data.telephoneServiceAreaInternational,
        telephoneServiceAreaDomestic: this.data.telephoneServiceAreaDomestic,
      });

      if (this.data.licenseeSignatureUrl){
        this.showSignature = true;
        this.signatureUrl = this.data.licenseeSignatureUrl;
      };
    }
  }

  save() {

    const file: any = this.licenseeSignaturePad.toDataURL();

    if (!file) {
      return;
    }
    const blob: any = this.dataURItoBlob(file);
    this.contractService.upload(blob).subscribe((res: any) => {
      console.log('File Uploaded Url: ' + res.uploadedFileUrl);
      console.log('Calling create contract API...');
      const body = this.formGroup.value;
      body.licenseeSignatureUrl = res.uploadedFileUrl;
      body.contractType = ContractType.OPERR_TEL;
      this.contractService.createContract(this.formGroup.value).subscribe(res => {
        console.log('Successfully created contract');
        this.notificationService.open({type: NotificationType.SUCCESS, body: 'Successfully created the contract'});
        this.enableSaveBtn = false;
      });
    });
  }


  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.fb.group({
      effectiveDate: [null, Validators.required],
      licensee: [null, Validators.required],
      licenseeCorporation: [null, Validators.required],
      licenseePlaceOfBusiness: [null, Validators.required],
      licenseeMedicaidId: [null, Validators.required],
      corporateName: [null, Validators.required],
      address: [null, Validators.required],
      phone: [null, Validators.required],
      licenseeAuthorizedOfficer: [null, Validators.required],
      licensorAuthorizedOfficer: [null],
      licenseeEmail: [null, Validators.required],
      licensorEmail: [null],
      licenseePhone: [null, Validators.required],
      licensorPhone: [null],
      licenseeDate: [null, Validators.required],
      licensorDate: [null],
      eligibility: [null, Validators.required],
      generalBilling: [null, Validators.required],
      correction: [null, Validators.required],
      lookBack: [null, Validators.required],
      congestionReport: [null, Validators.required],
      tlcReport: [null, Validators.required],
      numberOfUser: [null, Validators.required],
      telephoneServiceAreaInternational: [null],
      telephoneServiceAreaDomestic: [null],
    });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  unescape(str: string): string {
    return str.replace(/%(?![\da-f]{2})/gi, function () {
      // If the match is not a valid percent-encoded string, return the original match
      return '%25';
    }).replace(/%([\da-f]{2})/gi, function (match, code) {
      // Convert the percent-encoded string to its corresponding character
      return String.fromCharCode(parseInt(code, 16));
    });
  }
}
