import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasScheduleService {

  private readonly URL = environment.api.root;

  constructor(private http: HttpClient) { }

  getMasSchedule(medicaidProviderNum: string, scheduleType?: string) {
    const url = scheduleType ? `${this.URL}/api/v1/mas/billing/schedule-detail?medicaidProviderNum=${medicaidProviderNum}&scheduleType=${scheduleType}` : `${this.URL}/api/v1/mas/billing/schedule-detail?medicaidProviderNum=${medicaidProviderNum}`
    return this.http.get(url);
  }

  createSchedule(options: any) {
    return this.http.post(`${this.URL}/api/v1/mas/billing/schedule`, options);
  }
  getCheckAllBase() {
    return this.http.get(`${this.URL}/api/v1/mas/billing/schedule/checkAllBase`);
  }

  updateCheckAllBase(checked: boolean) {
    return this.http.post(`${this.URL}/api/v1/mas/billing/schedule/checkAllBase`, {checked});
  }

  findAllSchedule(scheduleType: string){
    const url = `${this.URL}/api/v1/mas/billing/schedules-detail?scheduleType=${scheduleType}`
    return this.http.get(url);
  }

  deleteMasSchedule(medicaidProviderNum: string, scheduleType: string, createTime: number) {
    const url = `${this.URL}/api/v1/mas/billing/schedule-detail?medicaidProviderNum=${medicaidProviderNum}&scheduleType=${scheduleType}&createTime=${createTime}`;
    return this.http.delete(url);
  }

}
