import { SENTRY_URL } from '../constant';

export const SENTRY_MENU = [
  {
    label: 'Claim',
    routerLink: SENTRY_URL.CLAIM
  },
  {
    label: 'Scheduler',
    routerLink: SENTRY_URL.SCHEDULER
  }
];
