import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingLogService {

  private readonly URL = environment.api.root;
  private readonly scheduler = environment.api.scheduler;

  constructor(private http: HttpClient) {
  }

  getBillingLogs(options: any) {
    return this.http.post(`${this.URL}/api/v1/mas/billing/billing-log`, options);
  }

  getPaymentLogs(options: any) {
    return this.http.post(`${this.URL}/api/v1/mas/billing/payment-log`, options);
  }

  exportManualInvoice(options: any): Observable<any> {
    const URL = `${this.URL}/api/v1/mas/billing/payment-log/export-excel`;
    return this.http.post(URL, options,
      {
        observe: 'response',
        responseType: 'blob'
      },
    );
  }

  resubmitBillingLogs(edi: any): Observable<any> {
    const URL = `${this.scheduler}/api/data/download/files/${edi}/0011`
    return this.http.get(URL, {
      headers: new HttpHeaders()
        .set('api-key', 'YtFQZLAhYXsNKJGuAm3bbkpz0E9q4m2p')
    });
  }

  resubmitResponse(edi: any, filename: any): Observable<any> {
    const URL = `${this.scheduler}/api/data/download/file/${edi}/${filename}`;
    return this.http.get(URL, {
      headers: new HttpHeaders()
        .set('api-key', 'YtFQZLAhYXsNKJGuAm3bbkpz0E9q4m2p')
    });
  }

  resubmitEdi(edi: any, transactionId: any, status: any): Observable<any> {
    const URL = `${this.scheduler}/api/data/download/send/data/${edi}/${transactionId}/${status}/1`;
    return this.http.get(URL, {
      headers: new HttpHeaders()
        .set('api-key', 'YtFQZLAhYXsNKJGuAm3bbkpz0E9q4m2p')
    });
  }

  exportBillingLogsToPdf(options: any): Observable<any> {
    const URL = `${this.URL}/api/v1/mas/billing/payment-log/export`;
    // options.pickUpDate = moment.utc(options.pickUpDate).local().format('YYYY-MM-DD');
    return this.http.post(URL, options,
      {
        observe: 'response',
        responseType: 'blob'
      },
    );
  }

  createPaymentLogs(options: any) {
    return this.http.post(`${this.URL}/api/v1/mas/billing/payment`, options);
  }

  updatePaymentLogs(paymentLog: any) {
    return this.http.put(`${this.URL}/api/v1/mas/billing/payment`, paymentLog);
  }

  deletePaymentLogs(paymentLog: any) {
    return this.http.post(`${this.URL}/api/v1/mas/billing/delete-payment`, paymentLog);
  }

  edi276process(transactionId: any): Observable<any> {
    const URL = `${this.URL}/api/v1/mas/billing/edi276-process/${transactionId}`;
    return this.http.get(URL);
  }
}
