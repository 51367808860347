<div class="page-err">
  <div class="err-container text-center">
    <div class="err">
      <h1>500</h1>
      <h2>Sorry, server goes wrong</h2>
    </div>

    <div class="err-body">
      <a mat-raised-button [routerLink]="['/']" class="btn-lg">Go Back to Home Page</a>
    </div>
  </div>
</div>
