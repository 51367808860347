import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { BillingSearchOption } from '@app/_shared/models/billing-search-option';

@Injectable()
export class SignOffService {
  private readonly URL = environment.api.server;

  constructor(private readonly http: HttpClient) {}

  upload(
    fileToUploads: File[],
    objectId: string,
    forceOverwriteFlag: boolean = false,
    signOff = 0,
    uploadType,
  ): Observable<any> {
    const formData: FormData = new FormData();
    fileToUploads.forEach(fileToUpload => {
      formData.append('file', fileToUpload);
    });
    formData.append('medicaidProviderNum', objectId);
    formData.append(
      'forceOverwriteFlag',
      forceOverwriteFlag ? 'true' : 'false'
    );
    formData.append('signOffService', signOff === 1 ? '1' : '0');
    formData.append('uploadType', uploadType);
    const headerOption = {
      headers: new HttpHeaders().set('timeout', `20000`)
    };
    return this.http.post<any>(`${this.URL}/sign-off/upload`, formData, headerOption);
  }

  getStatusUploading(id: any) {
    const header = new HttpHeaders().set('X-Spinner', 'true');
    return this.http.get(`${this.URL}/upload-progresses/${id}`, {
      headers: header
    });
  }

  getUploadResult(id: any) {
    return this.http.get(`${this.URL}/upload-progresses/result/${id}`);
  }

  deleteFailedRecords(id: any) {
    return this.http.get(`${this.URL}/upload-progresses/delete-failed/${id}`);
  }
  getBillerClaimList(options?: BillingSearchOption): Observable<any> {
    options.timeRange.startTime = moment.utc(options.timeRange.startTime).local().format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment.utc(options.timeRange.endDate).local().format('MM/DD/YYYY 23:59:59');
    let headers: any;
    return this.http.post(`${this.URL}/sign-off/list`, options, {
      headers
    });
  }

  updateBiller(body: any, medicaidProviderNum): Observable<any> {
    const headerOption = { headers: new HttpHeaders().set('timeout', '1200000') };
    return this.http.post(`${this.URL}/sign-off/update/${medicaidProviderNum}`, body, headerOption);
  }

  updateAllBillingOnSelectedDateRange(body: any, medicaidProviderNum) {
    const options = _.cloneDeep(body);
    options.timeRange.startTime = moment.utc(options.timeRange.startTime).local().format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment.utc(options.timeRange.endDate).local().format('MM/DD/YYYY 23:59:59');
    const headerOption = { headers: new HttpHeaders().set('timeout', '1200000') };
    return this.http.post(`${this.URL}/sign-off/updateAll/${medicaidProviderNum}`, options, headerOption);
  }

  public downloadExportFile(options: BillingSearchOption): Observable<any> {
    const URL = `${this.URL}/sign-off/export`;
    options.timeRange.startTime = moment.utc(options.timeRange.startTime).format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment.utc(options.timeRange.endDate).format('MM/DD/YYYY 23:59:59');
    options.pageable.pageSize = 999999;
    return this.http.post(URL, options, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  public importDriverAndToll(options: BillingSearchOption): Observable<any> {
    const URL = `${this.URL}/sign-off/import-driver-and-toll`;
    options.timeRange.startTime = moment.utc(options.timeRange.startTime).format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment.utc(options.timeRange.endDate).format('MM/DD/YYYY 23:59:59');
    options.pageable.pageSize = 999999;
    const headerOption = { headers: new HttpHeaders().set('timeout', '1200000') };
    return this.http.post(URL, options, headerOption);
  }

  getSignOffList(options?: BillingSearchOption): Observable<any> {
    options.timeRange.startTime = moment.utc(options.timeRange.startTime).local().format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment.utc(options.timeRange.endDate).local().format('MM/DD/YYYY 23:59:59');
    let headers: any;
    return this.http.post(`${this.URL}/sign-off/list`, options, {
      headers
    });
  }

  fetchVendor(options?: BillingSearchOption): Observable<any> {
    options.timeRange.startTime = moment.utc(options.timeRange.startTime).local().format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment.utc(options.timeRange.endDate).local().format('MM/DD/YYYY 23:59:59');
    let headers: any;
    return this.http.post(`${this.URL}/sign-off/fetch-vendor`, options, {
      headers
    });
  }

  updateFleetNumber(data: {billingId: string, fleetNumber: string, medicaidProviderNum: string, invoiceNumber: string, legId: string}) {
    return this.http.put(`${this.URL}/sign-off/fleetNumber`, data);
  }
  
  updateTollFee(data: {billingId: string, tollFee: string, medicaidProviderNum: string, invoiceNumber: string, legId: string}) {
    return this.http.put(`${this.URL}/sign-off/tollFee`, data);
  }
}
