import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EligibilityService {

  private readonly URL = environment.api.server;

  constructor(private http: HttpClient) { }

  getListBilling(options?: {
    pickUpDate: any,
    medicaidProviderNum: any,
    page: any,
    size: any,
  }): Observable<any> {
    const URL = `${this.URL}/eligibilities/list`;
    options.pickUpDate = moment.utc(options.pickUpDate).local().format('YYYY-MM-DD');
    let headers: any
    
    return this.http.post(URL, options, {
      headers: headers
    });
  }

  public downloadExportFile(options?: {
    pickUpDate?: any,
    medicaidProviderNum: any,
    page: any,
    size: any
  }): Observable<any> {
    const URL = `${this.URL}/eligibilities/export`;
    options.pickUpDate = moment.utc(options.pickUpDate).local().format('YYYY-MM-DD');
    return this.http.post(URL, options,
      {
        observe: 'response',
        responseType: 'blob'
      },
    );
  }

}
