import { Injectable } from '@angular/core';
import { FileUploadObjectType } from '@app/_config/constant';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillerService {

  private static readonly URL = environment.api.server;

  constructor(private http: HttpClient) { }

  upload(fileToUpload: File, objectType: FileUploadObjectType, objectId: string,
         objectSubType: string, forceOverwriteFlag = false): Observable<any> {
    const suffixUrl = this.getSuffixUrl(objectType);

    const URL = BillerService.URL + suffixUrl;

    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('medicaidProviderNum', objectId);
    formData.append('documentType ', objectSubType);
    formData.append('forceOverwriteFlag', forceOverwriteFlag ? 'true' : 'false');

    return this.http.post<any>(URL, formData);
  }

  getSuffixUrl(objectType: any) {
    let suffixUrl = '';
    switch (objectType) {
      case FileUploadObjectType.TOTAL_JOB:
        suffixUrl = '/sign-off/upload';
        break;
      case FileUploadObjectType.SIGN_OFF:
        suffixUrl = '/sign-off-details/upload';
        break;
      case FileUploadObjectType.TO_CLIENT:
        suffixUrl = '/to-clients/upload';
        break;
      case FileUploadObjectType.INVOICE:
        suffixUrl = '/invoices/upload';
        break;
      case FileUploadObjectType.DRIVER:
        suffixUrl = '/bases/upload/driver';
        break;
    }
    return suffixUrl;
  }

  getListBases() {
    const headerOption = { headers: new HttpHeaders().set('timeout', '600000') };
    const URL = `${BillerService.URL}/bases`;
    return this.http.get(URL, headerOption);
  }

  findById(id: any) {
    return this.http.get(`${BillerService.URL}/eligibilities/${id}`);
  }

  getStatusUploading(id: any, objectType: any) {
    return this.http.get(`${BillerService.URL}/upload-progresses/${id}`);
  }
}
