import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {environment} from 'environments/environment';
import {FileUploadObjectType} from '@app/_config/constant';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private static readonly URL = environment.api.server;
  
  private static readonly fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private static readonly fileExtension = '.xlsx';

  constructor(private http: HttpClient) {
  }

  uploadFile(fileType: FileUploadObjectType, files: File[], medicaidProviderNumber: string, forceOverwrite: boolean) {
    const formData: FormData = new FormData();
    files.forEach(fileToUpload => {
      formData.append('file', fileToUpload);
    });
    formData.append('medicaidProviderNum', medicaidProviderNumber);
    formData.append(
      'forceOverwrite',
      forceOverwrite ? 'true' : 'false'
    );
    if (fileType) {
      formData.append('fileType', FileUploadObjectType[fileType].toString());
    }
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000')
    };

    return this.http.post(`${FileUploadService.URL}/upload`, formData, headerOption);
  }

  uploadFileTransfer(sendToUserType: string, files: File[], medicaidProviderNumber: string, forceOverwrite: boolean) {
    const formData: FormData = new FormData();
    files.forEach(fileToUpload => {
      formData.append('file', fileToUpload);
    });
    formData.append('medicaidProviderNum', medicaidProviderNumber);
    formData.append('forceOverwrite', forceOverwrite ? 'true' : 'false');
    formData.append('sendToUserType', sendToUserType);
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000')
    };

    return this.http.post(`${FileUploadService.URL}/upload/file-transfer`, formData, headerOption);
  }

  uploadSoap(url: string, formData: FormData) {
    const headerOption = {
      headers: new HttpHeaders()
        .set('api-key', 'YtFQZLAhYXsNKJGuAm3bbkpz0E9q4m2p')
    };
    return this.http.post(url, formData, headerOption);
  }

  uploadFileProcess(files: File[], medicaidProviderNumber: string,
                    functionality: string, broker: string, fileType: string, forceOverwrite: boolean) {
    const formData: FormData = new FormData();
    files.forEach(fileToUpload => {
      formData.append('files', fileToUpload);
    });
    formData.append('medicaidProviderNum', medicaidProviderNumber);
    formData.append('functionality', functionality);
    formData.append('broker', broker);
    formData.append('fileType', fileType);
    formData.append('forceOverwrite', forceOverwrite.toString()
    );
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000').set('X-Spinner', 'true')
    };

    return this.http.post(`${FileUploadService.URL}/upload/file-process`, formData, headerOption);
  }

  getStatusUploading(id: any) {
    const header = new HttpHeaders().set('X-Spinner', 'true');
    return this.http.get(`${FileUploadService.URL}/upload-progresses/${id}`, {
      headers: header
    });
  }

  listFile() {
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000').set('X-Spinner', 'true')
    };
    return this.http.get(`${FileUploadService.URL}/upload`, headerOption);
  }

  getUrlDownload(file: string) {
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000').set('X-Spinner', 'true')
    };
    return this.http.get(`${FileUploadService.URL}/upload/url?file=${file}`, headerOption);
  }

  public exportExcel(jsonData: any[], fileName: string): void {

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData);
    const wb: XLSX.WorkBook = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    this.saveExcelFile(excelBuffer, fileName);
  }
  
  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: FileUploadService.fileType});
    FileSaver.saveAs(data, fileName + FileUploadService.fileExtension);
  }

}
