import { LoginType } from './login-type.enum';
import { AppUrl } from './app-url.enum';

export const APP_URL_PERMISSION_CONFIG = {
  [AppUrl.MY_ACCOUNT]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.FIDELIS]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.LOGISTICARE]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.MTM]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.SENTRY]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.CTS]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.CENTREK]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.MAS]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.MAS_BILLING_LOG]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.MAS_PAYMENT_LOG]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.ELIGIBILITY]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.SIGNOFF]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER, LoginType.BASE_ADMIN],
  },
  [AppUrl.CORRECTION]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER, LoginType.BASE_ADMIN],
  },
  [AppUrl.CLAIM]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.LOOKBACK]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.REPORT]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.HELP]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.BILLING_LOG]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.PAYMENT_LOG]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.MAS_JOB]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.SOAP]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.RATE]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.INVOICE]: {
    // permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
    permissionAllowed: [],
  },
  [AppUrl.UPLOAD_FILE_PROCESS]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER, LoginType.BASE_ADMIN, LoginType.BASE_USER],
  },
  [AppUrl.BILLER]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.MY_CONTRACT]: {
    // permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN],
    permissionAllowed: [LoginType.BASE_ADMIN, LoginType.BASE_USER],
  },
  [AppUrl.PAYMENT_METHOD]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN],
  },
  [AppUrl.BILLING_USER]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.CHANGE_PASSWORD]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.BASE_SEARCH]: {
    permissionAllowed: [LoginType.BASE_ADMIN, LoginType.BASE_USER],
  },
  [AppUrl.TRACKING]: {
    permissionAllowed: [LoginType.BASE_ADMIN, LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.BASE_UPLOAD]: {
    permissionAllowed: [LoginType.BASE_ADMIN, LoginType.BASE_USER ],
  },
  [AppUrl.BASE_DOWNLOAD]: {
    permissionAllowed: [LoginType.BASE_ADMIN, LoginType.BASE_USER ],
  },
  [AppUrl.DRIVER_LIST]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER, LoginType.BASE_ADMIN, LoginType.BASE_USER ],
  },
  [AppUrl.APP_INVOICE]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER, LoginType.BASE_ADMIN, LoginType.BASE_USER ],
  },
  [AppUrl.DAYCARE]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BILLER_USER],
  },
  [AppUrl.AUTHORIZATION_INFO]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.DAYCARE_CLAIM]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  },
  [AppUrl.MODIVCARE]: {
    permissionAllowed: [LoginType.BILLER_ADMIN, LoginType.BASE_ADMIN, LoginType.BILLER_USER, LoginType.BASE_USER],
  }
};
