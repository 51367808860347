import { Injectable } from '@angular/core';
import { WindowRefService } from '../window-ref/window-ref.service';
import { UntypedFormGroup, Form } from '@angular/forms';

@Injectable()
export class FormValidatorService {

  constructor(private windowObj:WindowRefService) { }

  validateForm(formGroupObj:UntypedFormGroup,form:any){
    let control:any;   
    if (formGroupObj.valid) {
      return true;
    } else {
      let formArr = Object.keys(formGroupObj.controls)
      for(let field of formArr) {
        if (!!formGroupObj.controls[field].invalid) {          
          control = formGroupObj.controls[field];
          control.markAsDirty();          
        }        
      }
      if(control) {        
        for(let el of form.elements){
          let invalidEle:any
          if(el.className.includes('ng-invalid')){
            invalidEle = el;
            this.windowObj.nativeWindow.scrollTo({ left: 0, top: invalidEle.offsetTop - 150, behavior: 'smooth' });
            break;
          }
        }        
      }
      return false;
    }
  }

}
