import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private readonly URL = environment.api.server;

  constructor(private http: HttpClient) { }

  getSignOffDetails(options: {
    medicaidProviderNum: any,
    startDate: any,
    endDate: any,
    page: any,
    size: any
    pageSort: any,
  }) {
    options.startDate = moment.utc(options.startDate).local().format('MM/DD/YYYY 00:00:00');
    options.endDate = moment.utc(options.endDate).local().format('MM/DD/YYYY 23:59:59');
    const body = {
      pageable: {
        pageNumber: options.page,
        pageSize: options.size,
        pageSort: options.pageSort
      },
      timeRange: { startTime: options.startDate, endDate: options.endDate },
      medicaidProviderNum: options.medicaidProviderNum,
    }
    let headers: any

    return this.http.post(`${this.URL}/sign-off-details/list`, body, {
      headers: headers
    });
  }

  getToClients(options: {
    medicaidProviderNum?: any,
    startDate?: any,
    endDate?: any,
    page: any,
    size: any
    pageSort: any,
  }) {
    options.startDate = moment.utc(options.startDate).local().format('MM/DD/YYYY 00:00:00');
    options.endDate = moment.utc(options.endDate).local().format('MM/DD/YYYY 23:59:59');
    const body = {
      pageable: {
        pageNumber: options.page,
        pageSize: options.size,
        pageSort: options.pageSort
      },
      timeRange: { startTime: options.startDate, endDate: options.endDate },
      medicaidProviderNum: options.medicaidProviderNum,
    }
    let headers: any

    return this.http.post(`${this.URL}/to-clients/list`, body, {
      headers: headers
    });
  }

  downloadExportDetailTotalJobFile(reportType: any, options?: {
    startDate: any,
    endDate: any,
    page: any,
    size: any,
    medicaidProviderNum: any,
    pageSort: any,
    invoiceNumber?: any,
    searchText?: any,
    reportStatus?: any,
    legID?: any,
    loadingTimeout?: any,
    hasFinishedFlag?:any,
    finishedFlag?:any
  }) {

    let api = `${this.URL}/mas/claims/export/reportDetail`
    if (reportType !== "claim_report") {
      api = `${this.URL}/lookback/export-detail`
    }

    options.startDate = moment.utc(options.startDate).local().format('MM/DD/YYYY 00:00:00');
    options.endDate = moment.utc(options.endDate).local().format('MM/DD/YYYY 23:59:59');

    const body = {
      pageable: {
        pageNumber: 0,
        pageSize: 2147483647,
        pageSort: options.pageSort
      },
      timeRange: { startTime: options.startDate, endDate: options.endDate },
      medicaidProviderNum: options.medicaidProviderNum,
      invoiceNumber: options.invoiceNumber,
      searchText: options.searchText,
      reportStatus: options.reportStatus,
      loadingTimeout: options.loadingTimeout,
      legID: options.legID,
      hasFinishedFlag: options.hasFinishedFlag,
      finishedFlag: options.finishedFlag
    }

    return this.http.post(api, body, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  downloadExportSummaryTotalJobFile(reportType: any, options?: {
    startDate: any,
    endDate: any,
    page: any,
    size: any,
    medicaidProviderNum: any,
    pageSort: any,
    invoiceNumber?: any,
    searchText?: any,
    reportStatus?: any,
    legID?: any,
    loadingTimeout?: any,
    hasFinishedFlag?:any,
    finishedFlag?:any
  }) {

    let api = `${this.URL}/mas/claims/export/reportSummary`
    if (reportType !== "claim_report") {
      api = `${this.URL}/lookback/export-summary`
    }

    options.startDate = moment.utc(options.startDate).local().format('MM/DD/YYYY 00:00:00');
    options.endDate = moment.utc(options.endDate).local().format('MM/DD/YYYY 23:59:59');

    const body = {
      pageable: {
        pageNumber: 0,
        pageSize: 2147483647,
        pageSort: options.pageSort
      },
      timeRange: { startTime: options.startDate, endDate: options.endDate },
      medicaidProviderNum: options.medicaidProviderNum,
      invoiceNumber: options.invoiceNumber,
      searchText: options.searchText,
      reportStatus: options.reportStatus,
      loadingTimeout: options.loadingTimeout,
      legID: options.legID,
      hasFinishedFlag: options.hasFinishedFlag,
      finishedFlag: options.finishedFlag
    }

    return this.http.post(api, body, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  getClaimReport(reportType: any, options?: {
                startDate: any,
                endDate: any,
                page: any,
                size: any,
                medicaidProviderNum: any,
                pageSort: any,
                invoiceNumber?: any,
                searchText?: any,
                reportStatus?: any,
                legID?: any,
                loadingTimeout?: any,
                circleNumber?:any,
                submitStartDate?:any,
                submitEndDate?:any,
                hasFinishedFlag?:any,
                finishedFlag?:any
              }) {

    let api = `${this.URL}/mas/claims/report`
    if (reportType !== "claim_report") {
      api = `${this.URL}/lookback/report`
    }

    options.startDate = moment.utc(options.startDate).local().format('MM/DD/YYYY 00:00:00');
    options.endDate = moment.utc(options.endDate).local().format('MM/DD/YYYY 23:59:59');

    let submitDateTimeRange = null;
    if (options.submitStartDate && options.submitEndDate) {
      const submitStartDate = moment.utc(options.submitStartDate).local().format('MM/DD/YYYY 00:00:00');
      const submitEndDate = moment.utc(options.submitEndDate).local().format('MM/DD/YYYY 23:59:59');
      submitDateTimeRange = { startTime: submitStartDate, endDate: submitEndDate };
    }
    const body = {
      pageable: {
        pageNumber: options.page,
        pageSize: options.size,
        pageSort: options.pageSort
      },
      timeRange: { startTime: options.startDate, endDate: options.endDate },
      submitDateTimeRange: submitDateTimeRange,
      medicaidProviderNum: options.medicaidProviderNum,
      invoiceNumber: options.invoiceNumber,
      searchText: options.searchText,
      reportStatus: options.reportStatus,
      loadingTimeout: options.loadingTimeout,
      legID: options.legID,
      circleNumber: options.circleNumber,
      hasFinishedFlag: options.hasFinishedFlag,
      finishedFlag: options.finishedFlag
    }
    let headers: any

    return this.http.post(api, body, {
      headers: headers
    });
  }



}
