import { Injectable } from '@angular/core';
import { Key, Shortcuts } from './key.enum';
import * as _ from 'lodash';
import { Select, Store } from '@ngxs/store';
import { AppState } from '../app-state/app-state.service';
import { Observable } from 'rxjs';

@Injectable()
export class KeyboardShortcutService {
    private _keyEntered: string[] = [];
    @Select(AppState) appState$: Observable<string[]>;

    constructor(private store: Store) {
    }

    addKey(key: string) {
        _.pull(this._keyEntered, key);
        this._keyEntered.push(key);
    }

    removeKey(key: string) {
        _.pull(this._keyEntered, key);
    }

    getkeyEntered() {
        return this._keyEntered;
    }

    equalsIgnoreCase(anotherKeys: string[]): boolean {
        if (anotherKeys) {
            const keyEntered = _.sortBy(this._keyEntered.map(e => _.toUpper(e)));
            const another = _.sortBy(anotherKeys.map(e => _.toUpper(e)));
            return _.isEqual(keyEntered, another);
        } else {
            return false;
        }
    }

    handleShortcuts() {
        // this.store.select(state => state.firstData)
        this.store.dispatch(['Hello']);
        this.appState$.subscribe(e => {
            console.log(e);
        });
    }
}
