import {Websiteinfo} from './websiteinfo.model';
import {Claim_Services} from './claim_services.model';

export class Base {
  baseName: string;
  baseAddress: string;
  city: string;
  state: string;
  zipCode: string;
  etin: string;
  npi: string;
  medicaidProviderNum: string;
  taxID: string;
  contactName: string;
  contactTel: string;
  locationCode: string;
  api: string;
  startDate: any;

  oldBaseName: string;
  oldMedicaidProviderNum: string;

  eligibility: boolean;
  eligibilityServiceRateUnder: number;
  eligibilityServiceRateOver: number;
  eligibilityServiceOver: number;
  eligibilityServiceFrequency: string;
  eligibilityCycleStart: any;
  eligibilityCycleEnd: any;

  signOff: boolean;
  signOffServiceRate: number;
  signOffCycleStart: any;
  signOffCycleEnd: any;
  
  generalBilling: boolean;
  generalBillingServiceRateLeg: number;
  generalBillingServicePercent: number;
  generalBillingCycleStart: any;
  generalBillingCycleEnd: any;
  
  correction: boolean;
  correctionServiceRateLeg: number;
  correctionServicePercent: number;
  correctionCycleStart: any;
  correctionCycleEnd: any;
  
  lookBack: boolean;
  lookBackServiceRateLeg: number;
  lookBackServicePercent: number;
  lookBackCycleStart: any;
  lookBackCycleEnd: any;
  
  congestionReport: boolean;
  congestionReportServiceRateUnder: number;
  congestionReportServiceRateOver: number;
  congestionReportServiceOver: number;

  monthlyFee: boolean;
  monthlyAmount: number;
  monthlyFeeCycleStart: any;
  monthlyFeeCycleEnd: any;

  contractStart: any;
  contractEnd: any;
  contractFiles: any[];
  biller: any;

  tlcReport: boolean;
  tlcReportServiceRateUnder: number;
  tlcReportServiceRateOver: number;
  tlcReportServiceOver: number;

  serviceFrequencys: any[];
  websiteInfos: Websiteinfo[];

  fid: Claim_Services;
  mtm: Claim_Services;
  sentry: Claim_Services;
  log: Claim_Services;
  status: string;
}
