export enum LOGISTI_CARE_URL {
  LOGIN = '/login',
  CLAIM = '/app/logisticare/claim',
  LOOK_BACK = '/app/logisticare/look-back',
  LOGISTICARE = '/app/logisticare',
  MY_ACCOUNT = '/app/myaccount',
  PAYMENT_METHOD = '/app/myaccount/payment',
  MY_CONTRACT = '/app/myaccount/contract-detail/info',
  BILLING_LOG = '/app/logisticare/billing-log',
  REPORT = '/app/logisticare/report',
  LOS_RATE = '/app/logisticare/claim/rate',
}
