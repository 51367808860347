import { AppUrl } from '../constant';

export const DAYCARE_MENU = [
  {
    label: 'Authorization Info',
    routerLink: AppUrl.AUTHORIZATION_INFO,
  },
  {
    label: 'Claim',
    routerLink: AppUrl.DAYCARE_CLAIM,
  }
]
