import { Component, OnInit, ViewChild } from '@angular/core';
import {SignupService} from '@app/_service/mas/signup.service';
import {Account} from '@app/_model/account.model';
import {Websiteinfo} from '@app/_model/websiteinfo.model';
import {Claim_Services} from '@app/_model/claim_services.model';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {FormValidatorService} from '@app/_service/form-validator/form-validator.service';
import {CustomValidators} from '@app/_core/components/sign-up/custom-validators';
import {Router} from '@angular/router';
import {MyAccountService} from '@app/my-account/services/my-account.service';
import * as CryptoJS from 'crypto-js';
import {Base} from '@app/_model/base.model';
import {BillingUser} from '@app/_model/billing-user.model';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import * as _ from 'lodash';
import {AesUtilService} from "@app/_service/util";
import * as moment from 'moment';
import { MessageService } from 'primeng';

@Component({
  selector: 'opr-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  account: Account;
  base: Base;
  billingUser: BillingUser;
  serviceFrequencys: any[];
  fid_serviceFrequencys: any[];
  mtm_serviceFrequencys: any[];
  eligibilityFrequencys: any [];
  sentry_serviceFrequencys: any[];
  log_serviceFrequencys: any[];
  websiteinfos: Websiteinfo[] = [];
  dayNames: any[];
  states: any[];
  types: any[];
  claimServices: any[];
  chooseSelected: any[];
  signUpForm: UntypedFormGroup;
  mapWebInfo: Map<string, Websiteinfo> = new Map<string, Websiteinfo>();

  statusOptions: any[];
  requiredValidate = false;

  @ViewChild('fileUpload') fileUpload: any;

  constructor(private router: Router, private signupService: SignupService,
              private myaccountservice: MyAccountService,
              private formValidator: FormValidatorService, private fb: UntypedFormBuilder,
              private aesUtilService: AesUtilService,
              private messageService: MessageService
  ) { }
  ngOnInit() {
    this.signUpForm = this.fb.group({
      // baseName: ['', [ Validators.pattern('^[A-Za-z0-9.\\s_-]+$')]],
      baseName: ['', [ Validators.required, Validators.pattern('^(.(?!,))*$')]],
      baseAddress: ['', [ Validators.required, Validators.pattern('^(.(?!,))*$')]],
      city: ['', [Validators.required, Validators.pattern('^(.(?!,))*$')]],
      state: [''],
      zipCode: ['', [Validators.required, Validators.maxLength(5),  Validators.pattern('^[0-9]*$')]],
      etin: ['', [Validators.required, Validators.pattern('^[0-9a-zA-Z]+$')]],
      npi: ['', [Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      medicaidProviderNum: ['', [Validators.required, Validators.maxLength(8),  Validators.pattern('^[0-9]*$')]],
      taxID: ['', [Validators.required,  Validators.pattern('^[0-9]*$')]],
      contactName: ['', [ Validators.required, Validators.pattern('^[A-Za-z0-9.\\s_-]+$')]],
      contactTel: ['', [Validators.required, Validators.maxLength(10),  Validators.pattern('^[0-9]*$')]],
      locationCode: ['', [Validators.required]],
      type: [''],
      api: ['', [ Validators.pattern('^[a-zA-Z0-9]+$')]],
      startDate: [''],

      // status: [],
      email: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]],
      eligibility: [''],
      eligibilityServiceRateUnder: [10, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      eligibilityServiceRateOver: ['0.10', [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      eligibilityServiceOver: [100, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      eligibilityServiceFrequency: [''],
      eligibilityCycleStart: [''],
      eligibilityCycleEnd: [''],
      weekly_eligibilityCycleStart: [''],
      weekly_eligibilityCycleEnd: [''],

      signOff: [''],
      signOffServiceRate: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      signOffServiceFrequency: [''],
      signOffCycleStart: [''],
      signOffCycleEnd: [''],
      weekly_signOffCycleStart: [''],
      weekly_signOffCycleEnd: [''],

      generalBilling: [''],
      generalBillingServiceRateLeg: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      generalBillingServicePercent: [3, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      generalBillingCycleStart: [''],
      generalBillingCycleEnd: [''],
      generalBillingServiceFrequency: [''],
      weekly_generalBillingCycleStart: [''],
      weekly_generalBillingCycleEnd: [''],

      correction: [''],
      correctionServiceRateLeg: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      correctionServicePercent: [3, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      correctionCycleStart: [''],
      correctionCycleEnd: [''],
      weekly_correctionCycleStart: [''],
      weekly_correctionCycleEnd: [''],
      correctionServiceFrequency: [''],

      lookBack: [''],
      lookBackServiceRateLeg: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      lookBackServicePercent: [3, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      lookBackCycleStart: [''],
      lookBackCycleEnd: [''],
      weekly_lookBackCycleStart: [''],
      weekly_lookBackCycleEnd: [''],
      lookBackServiceFrequency: [''],

      congestionReport: [''],
      congestionReportServiceRateUnder: [10, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      congestionReportServiceRateOver: [0.10, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      congestionReportServiceOver: [100, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],

      monthlyFee: [''],
      monthlyAmount: [''],
      monthlyFeeCycleStart: [''],
      monthlyFeeCycleEnd: [''],
      weekly_monthlyFeeCycleStart: [''],
      weekly_monthlyFeeCycleEnd: [''],

      masBiller: [''],

      tlcReport: [''],
      tlcReportServiceRateUnder: [10, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      tlcReportServiceRateOver: [0.10, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      tlcReportServiceOver: [100, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],

      fid_generalBilling: [''],
      fid_generalBillingServiceRateLeg: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      fid_generalBillingServicePercent: [3, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      fid_monthlyAmount: [''],
      fid_contractStart: [''],
      fid_contractEnd: [''],
      fid_generalBillingCycleStart: [''],
      fid_generalBillingCycleEnd: [''],
      fid_monthlyFeeCycleStart: [''],
      fid_monthlyFeeCycleEnd: [''],
      weekly_fid_generalBillingCycleStart: [''],
      weekly_fid_generalBillingCycleEnd: [''],
      weekly_fid_monthlyFeeCycleStart: [''],
      weekly_fid_monthlyFeeCycleEnd: [''],
      fid_generalBillingServiceFrequency: [''],

      mtm_generalBilling: [''],
      mtm_generalBillingServiceRateLeg: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      mtm_generalBillingServicePercent: [3, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      mtm_contractStart: [''],
      mtm_contractEnd: [''],
      mtm_generalBillingServiceFrequency: [''],

      sentry_generalBilling: [''],
      sentry_generalBillingServiceRateLeg: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      sentry_generalBillingServicePercent: [3, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      sentry_contractStart: [''],
      sentry_contractEnd: [''],
      sentry_generalBillingServiceFrequency: [''],

      log_lookBack: [''],
      log_generalBillingServiceRateLeg: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      log_generalBillingServicePercent: [3, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      log_generalBilling: [''],
      log_lookBackServiceRateLeg: [0.45, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      log_lookBackServicePercent: [3, [Validators.pattern('^[0-9]*\\.?[0-9]+$')]],
      log_contractStart: [''],
      log_contractEnd: [''],
      
      log_GeneralBillingCycleStart: [''],
      log_GeneralBillingCycleEnd: [''],
      log_LookBackCycleStart: [''],
      log_LookBackCycleEnd: [''],
      weekly_log_GeneralBillingCycleStart: [''],
      weekly_log_GeneralBillingCycleEnd: [''],
      weekly_log_LookBackCycleStart: [''],
      weekly_log_LookBackCycleEnd: [''],
      log_generalBillingServiceFrequency: [''],
      log_lookBackServiceFrequency: [''],

      contractStart: [''],
      contractEnd: [''],

      username: ['', [Validators.required], this.validateUserNameNotTaken.bind(this)],
      passwordSalt: [
        null,
        Validators.compose([
          Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ ~`!@#$%%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true
            }
          ),
          Validators.minLength(8)
        ])
      ],
      // passwordSalt: ['', [Validators.required,
      // Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[~`!@#$%^&*()+=_-{}[]|:""?/<>,.])[A-Za-z\\d~`!@#$%^&*()+=_-{}[]|:""?/<>,]{8,}$')]],
      confirmPassword: ['', [Validators.compose([Validators.required])]],
      serviceFrequencys: this.fb.array([])
    },
    {
      validator: CustomValidators.passwordMatchValidator
    });

    this.dayNames = [
      {label: "Sunday", value: "Sunday"},
      {label: "Monday", value: "Monday"},
      {label: "Tuesday", value: "Tuesday"},
      {label: "Wednesday", value: "Wednesday"},
      {label: "Thursday", value: "Thursday"},
      {label: "Friday", value: "Friday"},
      {label: "Saturday", value: "Saturday"}
    ];
    this.eligibilityFrequencys = [
        { label: '-', value: '' },
        { label: 'Daily', value: 'Daily' },
        { label: 'Weekly', value: 'Weekly' },
        { label: 'Monthly', value: 'Monthly' }
      ];

    this.claimServices = [
      { type: 'Eligibility', rate: '$10 (Under 100 trips); $0.10/trip over 100 trips', frequency: ''},
      { type: 'Sign Off', rate: '$0.45/trip', frequency: ''},
      { type: 'General Billing', rate: '$0.45/trip', frequency: ''},
      { type: 'Correction', rate: '20% * amount', frequency: ''},
      { type: 'Loop Back', rate: '20% * amount', frequency: ''},
      { type: 'Congestion Report', rate: '$100/report', frequency: ''},
      { type: 'TLC Report', rate: '$100/report', frequency: ''},
    ];

    this.types = [
      {label: 'MAS', value: 'MAS'},
      {label: 'LogistiCare', value: 'LOGISTICARE'},
      {label: 'MTM', value: 'MTM'},
      {label: 'SENTRY', value: 'SENTRY'},
      {label: 'FIDELIS', value: 'FIDELIS'},
      {label: 'ePACES', value: 'ePACES'},
      {label: 'OTHERS', value: 'OTHERS'}
    ];
    this.states = [
      {label: 'Alabama', value : 'AL'},
      {label: 'Alaska', value : 'AK'},
      {label: 'Arizona', value : 'AZ'},
      {label: 'Arkansas', value : 'AR'},
      {label: 'California', value : 'CA'},
      {label: 'Colorado', value : 'CO'},
      {label: 'Connecticut', value : 'CT'},
      {label: 'Delaware', value : 'DE'},
      {label: 'Florida', value : 'FL'},
      {label: 'Georgia', value : 'GA'},
      {label: 'Hawaii', value : 'HI'},
      {label: 'Idaho', value : 'ID'},
      {label: 'Illinois', value : 'IL'},
      {label: 'Indiana', value : 'IN'},
      {label: 'Iowa', value : 'IA'},
      {label: 'Kansas', value : 'KS'},
      {label: 'Kentucky', value : 'KY'},
      {label: 'Louisiana', value : 'LA'},
      {label: 'Maine', value : 'ME'},
      {label: 'Maryland', value : 'MD'},
      {label: 'Massachusetts', value : 'MA'},
      {label: 'Michigan', value : 'MI'},
      {label: 'Minnesota', value : 'MN'},
      {label: 'Mississippi', value : 'MS'},
      {label: 'Missouri', value : 'MO'},
      {label: 'Montana', value : 'MT'},
      {label: 'Nebraska', value : 'NE'},
      {label: 'Nevada', value : 'NV'},
      {label: 'New Hampshire', value : 'NH'},
      {label: 'New Jersey', value : 'NJ'},
      {label: 'New Mexico', value : 'NM'},
      {label: 'New York', value : 'NY'},
      {label: 'North Carolina', value : 'NC'},
      {label: 'North Dakota', value : 'ND'},
      {label: 'Ohio', value : 'OH'},
      {label: 'Oklahoma', value : 'OK'},
      {label: 'Oregon', value : 'OR'},
      {label: 'Pennsylvania', value : 'PA'},
      {label: 'Rhode Island', value : 'RI'},
      {label: 'South Carolina', value : 'SC'},
      {label: 'South Dakota', value : 'SD'},
      {label: 'Tennessee', value : 'TN'},
      {label: 'Texas', value : 'TX'},
      {label: 'Utah', value : 'UT'},
      {label: 'Vermont', value : 'VT'},
      {label: 'Virginia', value : 'VA'},
      {label: 'Washington', value : 'WA'},
      {label: 'West Virginia', value : 'WV'},
      {label: 'Wisconsin', value : 'WI'},
      {label: 'Wyoming', value : 'WY'}
    ];
    //this.serviceFrequencys = [new Freequency(), new Freequency(), new Freequency(), new Freequency(), new Freequency()];
    this.serviceFrequencys = ['', '', '', '', '']; // MAS
    this.fid_serviceFrequencys = [''];
    this.mtm_serviceFrequencys = [''];
    this.sentry_serviceFrequencys = [''];
    this.log_serviceFrequencys = [''];
    this.setServiceFreequencys();

    this.account = new Account();
    this.billingUser = new BillingUser();
    this.account.billingUser = this.billingUser;
    this.base = new Base();
    this.base.locationCode = '003';
    this.account.base = this.base;
    this.account.base.fid = new Claim_Services();
    this.account.base.mtm = new Claim_Services();
    this.account.base.sentry = new Claim_Services();
    this.account.base.log = new Claim_Services();

    this.account.base.contractFiles = [];
    this.account.base.fid.contractFiles = [];
    this.account.base.mtm.contractFiles = [];
    this.account.base.sentry.contractFiles = [];
    this.account.base.log.contractFiles = [];


    this.statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'InActive', value: 0 },
    ];
  }



  setServiceFreequencys() {
    // MAS
    const control = this.signUpForm.get('serviceFrequencys') as UntypedFormArray;
    this.serviceFrequencys.forEach(freequency => {
      control.push(this.setFreequency(freequency.value));
    });
    // FID
    /*const control = this.signUpForm.get('fid_serviceFrequencys') as FormArray;
    this.fid_serviceFrequencys.forEach(freequency => {
      control.push(this.setFreequency(freequency.value));
    });
    // MTM
    const control = this.signUpForm.get('mtm_serviceFrequencys') as FormArray;
    this.mtm_serviceFrequencys.forEach(freequency => {
      control.push(this.setFreequency(freequency.value));
    });
    // SENTRY
    const control = this.signUpForm.get('sentry_serviceFrequencys') as FormArray;
    this.sentry_serviceFrequencys.forEach(freequency => {
      control.push(this.setFreequency(freequency.value));
    });*/
  }
  setFreequency(value) {
    return this.fb.group({
      value: [value]
    });
  }

  removeWebInfo(index: number, row: Websiteinfo) {
    const i = this.chooseSelected.indexOf(row.websiteName);

    //console.log(i);
    //console.log(this.chooseSelected);
    // this.chooseSelected.splice(i, 1);
    // this.chooseSelected = [];
    this.websiteinfos.splice(index, 1);
    this.chooseSelected = [];
    this.websiteinfos.forEach(webInfo => {
      this.chooseSelected.push(webInfo.websiteName);
    });
  }

  addWebInfo(event) {
    this.websiteinfos = [];
    event.value.forEach(value => {
      const websiteInfo = new Websiteinfo();
      websiteInfo.websiteName = value;
      if (value == 'OTHERS') {
        websiteInfo.websiteNameOthers = value;
      }
      else {
        websiteInfo.websiteNameOthers = null;
      }
      websiteInfo.userName = null;
      websiteInfo.password = null;
      this.websiteinfos.push(websiteInfo);
    });
  }

  encryptWebInfos(websiteinfos: Websiteinfo []): Websiteinfo[] {
    websiteinfos.forEach((websiteinfo: Websiteinfo) => {
      const encryptUsername = this.aesUtilService.encrypt(websiteinfo.userName);
      const encryptPassword = this.aesUtilService.encrypt(websiteinfo.password);
      websiteinfo.userName = encryptUsername;
      websiteinfo.password = encryptPassword;
    });
    return  websiteinfos ;
  }
  submitSignUp(dataForm) {
    // validate form before submitting
    if (this.signUpForm.invalid) {
      this.requiredValidate = true;
      return;
    }
    if (this.formValidator.validateForm(this.signUpForm, dataForm)) {
      this.account.base.medicaidProviderNum = this.account.billingUser.medicaidProviderNum;
      this.account.base.websiteInfos = [];
      this.account.base.websiteInfos = this.encryptWebInfos(this.websiteinfos);
      this.account.billingUser.status = 1;
      // MAS
      this.account.base.serviceFrequencys = [];
      this.serviceFrequencys.forEach(value => {
        if (value != null) {
          this.account.base.serviceFrequencys.push(value);
        }
      });
      // FID
      this.account.base.fid.serviceFrequencys = [];
      this.fid_serviceFrequencys.forEach(value => {
        if (value != null) {
          this.account.base.fid.serviceFrequencys.push(value);
        }
      });
      // MTM
      this.account.base.mtm.serviceFrequencys = [];
      this.mtm_serviceFrequencys.forEach(value => {
        if (value != null) {
          this.account.base.mtm.serviceFrequencys.push(value);
        }
      });
      // SENTRY
      this.account.base.sentry.serviceFrequencys = [];
      this.sentry_serviceFrequencys.forEach(value => {
        if (value != null) {
          this.account.base.sentry.serviceFrequencys.push(value);
        }
      });
      //LOG
      this.account.base.log.serviceFrequencys = [];
      this.log_serviceFrequencys.forEach(value => {
        if (value != null) {
          this.account.base.log.serviceFrequencys.push(value);
        }
      });
      // Clone new acount to submit to server (for not changing passwordSalt in client's acount)
      const acountClone  = JSON.parse(JSON.stringify(this.account));

      acountClone.billingUser.password = CryptoJS.MD5(acountClone.billingUser.password).toString();
      acountClone.billingUser.confirmPassword = CryptoJS.MD5(acountClone.billingUser.confirmPassword).toString();

      this.signupService.create(acountClone).subscribe((res:any) => {
        // check the error return
        if (res.error) {
          this.messageService.add({severity: 'error', summary: 'created', detail: res.error});
        }
        else {
          this.messageService.add({severity: 'info', summary: 'created', detail: 'Sign up successfully!'});
          setTimeout(() => this.router.navigate(['/login']), 2000);
        }
      });
    }
  }

  masfileContract = [];
  uploadMasContractFile(event: any) {
    this.signupService.uploadContract(event.files).subscribe((res: any[]) => {
      res.forEach((r: any) => {
        let contract = {};
        contract["fileName"] = r.fileName;
        contract["fileUrl"] = r.fileUrl;
        this.account.base.contractFiles.push(contract);
      });
      this.masfileContract = this.account.base.contractFiles;
      console.log(this.masfileContract);
      this.fileUpload.clear();
    }, err => {
      this.fileUpload.clear();
    });
  }

  fidfileContract = [];
  uploadFidContractFile(event: any) {
    this.signupService.uploadContract(event.files).subscribe((res: any[]) => {
      console.log(res);
      res.forEach((r: any) => {
        let contract = {};
        contract["fileName"] = r.fileName;
        contract["fileUrl"] = r.fileUrl;
        this.account.base.fid.contractFiles.push(contract);
      });
      this.fidfileContract = this.account.base.fid.contractFiles;
      this.fileUpload.clear();
    }, err => {
      this.fileUpload.clear();
    });
  }

  mtmfileContract = [];
  uploadMTMContractFile(event: any) {
    this.signupService.uploadContract(event.files).subscribe((res: any[]) => {
      console.log(res);
      res.forEach((r: any) => {
        let contract = {};
        contract["fileName"] = r.fileName;
        contract["fileUrl"] = r.fileUrl;
        this.account.base.mtm.contractFiles.push(contract);
      });
      this.mtmfileContract = this.account.base.mtm.contractFiles;
      this.fileUpload.clear();
    }, err => {
      this.fileUpload.clear();
    });
  }

  sentryfileContract = [];
  uploadSentryContractFile(event: any) {
    this.signupService.uploadContract(event.files).subscribe((res: any[]) => {
      res.forEach((r: any) => {
        let contract = {};
        contract["fileName"] = r.fileName;
        contract["fileUrl"] = r.fileUrl;
        this.account.base.sentry.contractFiles.push(contract);
      });
      this.sentryfileContract = this.account.base.sentry.contractFiles;
      this.fileUpload.clear();
    }, err => {
      this.fileUpload.clear();
    });
  }

  logfileContract = [];
  uploadLogContractFile(event: any) {
    this.signupService.uploadContract(event.files).subscribe((res: any[]) => {
      console.log(res);
      res.forEach((r: any) => {
        let contract = {};
        contract["fileName"] = r.fileName;
        contract["fileUrl"] = r.fileUrl;
        this.account.base.log.contractFiles.push(contract);
      });
      this.logfileContract = this.account.base.log.contractFiles;
      this.fileUpload.clear();
    }, err => {
      this.fileUpload.clear();
    });
  }

  validateUserNameNotTaken(control: AbstractControl): Observable<ValidationErrors|null> {

    if (_.size(control.value) < 8) {
      return of({minlength: true});
    }

    return this.myaccountservice.isAccountNameAvail(control.value).pipe(
      map(isTaken => {
        //console.log('data', isTaken);
        return (isTaken ? {userTaken: true} : null);
      }),
      catchError(() => null)
    );
  }

  setCheckAll(checkAll: boolean) {
    this.account.base.eligibility = checkAll;
    this.account.base.signOff = checkAll;
    this.account.base.generalBilling = checkAll;
    this.account.base.correction = checkAll;
    this.account.base.lookBack = checkAll;
    this.account.base.congestionReport = checkAll;
    this.account.base.tlcReport = checkAll;
    this.account.base.monthlyFee = checkAll;
  }
}
