export enum MenuLabel {
  FILE_TRANSFER = 'file_transfer',
  UPLOAD_FILE_PROCESS = 'menu_upload_file_process',
  UPLOAD = 'menu_upload',
  TRANSPORTATION = 'menu_transportation',
  MAS = 'menu_mas',
  LOGISTICARE = 'menu_logisticare',
  MTM = 'menu_mtm',
  SENTRY = 'menu_sentry',
  FIDELIS = 'menu_fidelis',
  CTS = 'menu_cts',
  CENTREK = 'menu_centrek',
  MY_ACCOUNT = 'menu_my_account',
  CONTRACT = 'menu_my_contract',
  DRIVER_LIST = 'menu_driver_list',
  INVOICE = 'menu_invoice',
  DAYCARE = 'menu_daycare',
  MODIVCARE = 'menu_modivcare'
}
