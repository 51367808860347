import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ReportDetailType } from '@app/_config/constant';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  private readonly URL = environment.api.root;

  constructor(private http: HttpClient) { }

  create(options: any) {
    return this.http.put(`${this.URL}/billing-user`, options);
  }

  uploadContract(files: File[]) {
    const formData: FormData = new FormData();
    files.forEach(fileToUpload => {
      formData.append('files', fileToUpload);
    });
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000').set('X-Spinner', 'true')
    };
    return this.http.post(`${this.URL}/billing-user/upload-contract`, formData, headerOption);
  }
}
