import { AppUrl } from '../constant';

export const MAS_MENU = [
  {
    label: 'Eligibility',
    routerLink: AppUrl.ELIGIBILITY,
  },
  {
    label: 'Sign Off',
    routerLink: AppUrl.SIGNOFF,
  },
  // {
  //   label: 'Correction',
  //   routerLink: AppUrl.CORRECTION,
  // },
  {
    label: 'Claim',
    routerLink: AppUrl.CLAIM,
  },
  {
    label: 'Look Back',
    routerLink: AppUrl.LOOKBACK,
  },
  {
    label: 'Report',
    routerLink: AppUrl.REPORT,
  },
  {
    label: 'Billing Log',
    routerLink: AppUrl.MAS_BILLING_LOG,
  },
  {
    label: 'Payment Log',
    routerLink: AppUrl.MAS_PAYMENT_LOG,
  },
  {
    label: 'Scheduler',
    routerLink: AppUrl.MAS_JOB,
  },
  {
    label: 'SOAP',
    routerLink: AppUrl.SOAP,
  },
  {
    label: 'Rate',
    routerLink: AppUrl.RATE,
  }
];
