import { FIDELIS_MENU } from './fidelis-menu';
import { MAS_MENU } from './mas-menu';
import { SIDE_NAV_MENU } from './side-nav-menu';
import { MTM_MENU } from '@app/_config/menu/mtm-menu';
import { LOGISTI_CARE_MENU } from '@app/_config/menu/logisti-care-menu';
import { SENTRY_MENU } from '@app/_config/menu/sentry-menu';
import { CTS_MENU } from '@app/_config/menu/cts-menu';
import { CENTREK_MENU } from '@app/_config/menu/centrek-menu';
import { UPLOAD_FILE_PROCESS_MENU } from '@app/_config/menu/file-process-upload-menu';
import { DAYCARE_MENU } from './daycare-menu';
import { MODIVCARE_MENU } from './modivcare-menu';

export enum MenuType {
  SIDE_NAV,
  MAS,
  FIDELIS,
  MTM,
  LOGISTI_CARE,
  SENTRY,
  CTS,
  CENTREK,
  MODIVCARE,
  UPLOAD_FILE_PROCESS,
  APP_INVOICE,
  DAYCARE
}

export const APP_MENU = {
  [MenuType.SIDE_NAV]: SIDE_NAV_MENU,
  [MenuType.MAS]: MAS_MENU,
  [MenuType.FIDELIS]: FIDELIS_MENU,
  [MenuType.MTM]: MTM_MENU,
  [MenuType.LOGISTI_CARE]: LOGISTI_CARE_MENU,
  [MenuType.SENTRY]: SENTRY_MENU,
  [MenuType.CTS]: CTS_MENU,
  [MenuType.CENTREK]: CENTREK_MENU,
  [MenuType.MODIVCARE]: MODIVCARE_MENU,
  [MenuType.UPLOAD_FILE_PROCESS]: UPLOAD_FILE_PROCESS_MENU,
  [MenuType.DAYCARE]: DAYCARE_MENU,
};
