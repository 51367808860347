import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';
import * as _ from 'lodash';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import * as UrlPattern from 'url-pattern';

import { environment } from '../../../environments/environment';
import { LoginType, AppUrl } from '@app/_config/constant';
import { APP_URL_PERMISSION } from '@app/_config/config.module';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuType, APP_MENU } from '@app/_config/menu';

@Directive()
@Injectable()
export class AuthService {
  @Output() medicaidChange = new EventEmitter();
  private userInfo: {
    token?: string, userId?: number, loginType?: LoginType, firstName?: string, lastName?: string,
    baseName?: number, medicaidProviderNum?: string, userName?: string
  } = {};
  private loginInfo: any;
  // tslint:disable-next-line: variable-name
  private _isFirstLogin = false;

  private expirationTime = 3600 * 24;

  private SECRET_KEY = '13Xt2sMT';

  private readonly APP_URL_PATTERN: { url: AppUrl; pattern: UrlPattern; }[];

  private readonly INACTIVE_INTERVAL = 600000;

  // tslint:disable-next-line: variable-name
  private _userInactivityCounter;
  // tslint:disable-next-line: variable-name
  private _userActivitySubject: Subject<boolean> = new Subject();

  constructor(private http: HttpClient,
              private router: Router,
              private cookieService: CookieService,
              @Inject(APP_URL_PERMISSION) private appUrlPermission) {
    this.APP_URL_PATTERN = _.map(AppUrl, (e) => {
      return { url: e, pattern: new UrlPattern(e) };
    });
  }

  private saveUserInfoToEncryptedToken(token: string, userId: number, loginType: LoginType,
                                       firstName: string, lastName: string, baseName: number,
                                       medicaidProviderNum: string, userName: string) {

    this.userInfo = {
      token, userId, loginType,
      firstName, lastName, baseName, medicaidProviderNum, userName
    };
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(this.userInfo), this.SECRET_KEY).toString();
    // Add login info to sessionStorage to support force logout another tab and keep the current tab
    sessionStorage.setItem('bill_sys_user', cipherText);
    this.cookieService.set('bill_sys_user', cipherText, moment().add(this.expirationTime, 'seconds').toDate());
  }

  private loadUserInfoFromEncryptedToken() {
    const cipherText = sessionStorage.getItem('bill_sys_user') || this.cookieService.get('bill_sys_user');
    const infoString = CryptoJS.AES.decrypt(cipherText.toString(), this.SECRET_KEY).toString(CryptoJS.enc.Utf8);

    if (cipherText && infoString) {
      try {
        this.userInfo = JSON.parse(infoString);
      } catch (e) {
        this.userInfo = {};
      }
    } else {
      this.userInfo = {};
    }
  }

  setInactiveCounter() {
    this._userInactivityCounter = setTimeout(() => this._userActivitySubject.next(true), this.INACTIVE_INTERVAL);
  }

  resetInactiveCounter() {
    clearTimeout(this._userInactivityCounter);
    this.setInactiveCounter();
  }

  getToken() {
    return this.userInfo ? this.userInfo.token : undefined;
  }

  setToken(token: any) {
    if (token) {
      this.userInfo.token = token;
    }
  }

  getLoginInfo() {
    return this.loginInfo || {};
  }

  setLoginInfo(loginInfo: any) {
    this.loginInfo = loginInfo;
  }

  getUserName() {
    return this.getLoginInfo() && this.getLoginInfo().userName;
  }

  getLoginType(): LoginType {
    return this.userInfo.loginType;
  }

  isFirstLogin() {
    return this._isFirstLogin;
  }

  getMedicaidProviderNumber() {
    return this.getLoginInfo() && this.getLoginInfo().medicaidProviderNum;
  }

  setMedicaidProvider(medicaidProvider?: {
    label: any,
    value: any
  }) {
    this.loginInfo.medicaidProviderNum = medicaidProvider.value;
    this.loginInfo.baseName = medicaidProvider.label;
    this.medicaidChange.emit(medicaidProvider.value);
  }

  setFleetNumbers(fleetNumbers: { label: string, value: string }[]) {
    this.loginInfo.fleetNumbers = fleetNumbers;
  }

  isAppUrlAccessAllowed(url: string): boolean {
    const matchedUrlPatterns = _.filter(this.APP_URL_PATTERN, (e: any) => e.pattern.match(url));
    const isAccessAllowed = _.every(matchedUrlPatterns, (e: any) =>
      _.get(this.appUrlPermission[e.url], 'permissionAllowed', []).indexOf(this.getLoginType()) > -1);
    return isAccessAllowed;
  }

  private createMenuByPermission(menu: any) {
    // tslint:disable-next-line: variable-name
    const _menu = _.cloneDeep(menu);

    return _.filter(_menu, (childMenu: any) => {
      const url = childMenu.routerLink;
      if (this.isAppUrlAccessAllowed(url)) {
        if (childMenu.items) {
          childMenu.items = this.createMenuByPermission(childMenu.items);
        }
        return true;
      } else {
        return false;
      }
    });
  }

  getLoginDetailURL() {
    return environment.api.server + '/user-details';
  }

  isTokenValid(): Observable<boolean> {
    this.loadUserInfoFromEncryptedToken();
    // get user details
    // mock
    this.setLoginInfo({
      userId: this.userInfo.userId,
      baseName: this.userInfo.baseName,
      userName: this.userInfo.userName,
      firstName: this.userInfo.firstName,
      lastName: this.userInfo.lastName,
      medicaidProviderNum: this.userInfo.medicaidProviderNum
    });

    // return this.http.get(this.getLoginDetailURL())
    //   .pipe(
    //     tap((e) => this.setLoginInfo(e)),
    //     switchMap(() => of(true)),
    //     catchError(() => of(false))
    //   );

    // mock
    return of(this.getToken() ? true : false);
  }

  isExternalSystem() {
    return (this.getLoginType() === LoginType.BASE_ADMIN) || (this.getLoginType() === LoginType.BASE_USER);
  }

  isInternalSystem() {
    return (this.getLoginType() === LoginType.BILLER_ADMIN) || (this.getLoginType() === LoginType.BILLER_USER);
  }

  getLoginURL() {
    return `${environment.api.server}/login`;
  }

  login(loginValue: {
    username: string,
    password: string
  }): Observable<boolean> {
    const URL = this.getLoginURL();

    const params = {
      username: loginValue.username,
      password: CryptoJS.MD5(loginValue.password).toString()
    };

    return this.http.post(URL, params).pipe(
      map((response: any) => {
        if (response) {
          this.saveUserInfoToEncryptedToken(this.userInfo.token, response.userId, LoginType[response.loginType] as any,
            response.firstName, response.lastName, response.baseName, response.medicaidProviderNum, response.userName);
          this.setLoginInfo(response);
          this._isFirstLogin = true;
          return true;
        } else {
          return false;
        }
      })
    );
  }

  getMenuByPermission(menuType: MenuType) {
    return this.createMenuByPermission(APP_MENU[menuType]);
  }

  getResetPasswordURL(loginType: LoginType) {
    switch (loginType) {
      case LoginType.BASE_ADMIN:
      case LoginType.BASE_USER:
      case LoginType.BILLER_ADMIN:
      case LoginType.BILLER_USER:
        return environment.api.server + '/password-reset';
      default:
        return '';
    }
  }

  getForgotPasswordURL() {
    return environment.api.root + '/passwords/forgotpassword';
  }

  getForgotUsernamedURL() {
    return environment.api.server + '/username-forgot';
  }

  getLogoutURL() {
    return environment.api.server + '/logout';
  }

  resetPassword(passcode: string, password: string): Observable<any> {
    const URL = environment.api.root + '/passwords/password/reset';
    return this.http.put(`${URL}?passcode=${passcode}&password=${CryptoJS.MD5(password).toString()}`, {});
  }

  forgotPassword(email: string): Observable<any> {
    const URL = this.getForgotPasswordURL();
    return this.http.post(`${URL}?email=${email}`, {});
  }

  forgotUsername(options: any) {
    const URL = this.getForgotUsernamedURL();
    return this.http.post(URL, options);
  }

  logout(): void {
    const URL = this.getLogoutURL();
    const authHeader = this.getToken();
    this.http.post(URL, {}).subscribe(res => {
      this.redirectLogin();
    });
  }

  redirectLogin() {
    this.cookieService.deleteAll();
    sessionStorage.removeItem('bill_sys_user');
    this.setLoginInfo({});
    this.userInfo = {};
    this.router.navigate([AppUrl.LOGIN]);
  }

  getUserActivitySubject(): Subject<boolean> {
    return this._userActivitySubject;
  }

}
