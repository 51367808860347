import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoadingIndicatorService } from '@app/_core/service';

@Component({
  selector: 'opr-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {

  isLoading$: Observable<boolean>;

  constructor(private loader: LoadingIndicatorService) {
    this.isLoading$ = this.loader.getStatus();
    // test
    // this.isLoading$ = of(true);
  }

  ngOnInit() {
  }
}
