import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { DEFAULT_HTTP_TIMEOUT } from '@app/_config/config.module';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_HTTP_TIMEOUT) private defaultTimeOut) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutHeader = req.headers.get('timeout');
    // Set timout = indefinite for skipping check timout for each request.
    if (timeoutHeader === 'indefinite') {
      return next.handle(req).pipe();
    }
    const cusTimeout = Number(timeoutHeader) || this.defaultTimeOut;
    return next.handle(req).pipe(
      timeout(cusTimeout)
    );
  }
}
