<p-toast></p-toast>
<div class="ui-g">
  <div class="ui-g-12">

    <p-card>
      <form [formGroup]="signUpForm" #signUpDataForm [name]="signUpDataForm">
        <div class="ui-g ui-fluid">
          <div class="ui-g-4">
            <p-panel header="Base Information">
              <div class="ui-g">
                <div class="ui-g-4"><span>Base Name: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input type="text" pInputText formControlName="baseName" required [(ngModel)]="account.base.baseName"/>
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                    <span *ngIf="requiredValidate && signUpForm.controls['baseName'].errors?.required">Base Name is required</span>
                	<span *ngIf="signUpForm.controls['baseName'].errors?.pattern">No special character is allowed for base name</span>
                  </div>
                </div>

              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>Address: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="baseAddress" required [(ngModel)]="account.base.baseAddress" type="text">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                  	<span *ngIf="requiredValidate && signUpForm.controls['baseAddress'].errors?.required">Base Address is required</span>
                                <span
                                  *ngIf="signUpForm.controls['baseAddress'].errors?.pattern">no comma allowed</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>City:</span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName = 'city' required [(ngModel)]="account.base.city" type="text">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                  	<span *ngIf="requiredValidate && signUpForm.controls['baseAddress'].errors?.required">Base Address is required</span>
                                <span
                                  *ngIf="signUpForm.controls['city'].errors?.pattern">only string, no comma</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>State:</span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <p-dropdown [options]="states"
                              formControlName="state"
                              [(ngModel)]="account.base.state"
                              placeholder="Select">
                  </p-dropdown>
                  <div class="ui-g-12 form-control-feedback left-padding-0">
	                <span *ngIf="requiredValidate && signUpForm.controls['state'].errors?.required">State is required</span>
	              </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>ZipCode:</span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="zipCode" required [(ngModel)]="account.base.zipCode" type="text" maxlength="5">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                  	<span *ngIf="requiredValidate && signUpForm.controls['zipCode'].errors?.required">ZipCode is required</span>
                                <span
                                  *ngIf="signUpForm.controls['zipCode'].errors?.pattern">only 5 numbers</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>ETIN: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="etin" required [(ngModel)]="account.base.etin" type="text">
                  <div class="ui-g-12 form-control-feedback">
                  	<span *ngIf="requiredValidate && signUpForm.controls['etin'].errors?.required">ETIN is required</span>
                                <span
                                  *ngIf="signUpForm.controls['etin'].errors?.pattern">only 4 letters</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>NPI: </span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="npi" [(ngModel)]="account.base.npi" type="text">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                   <span *ngIf="signUpForm.controls['npi'].errors?.pattern">only 10 numbers</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>Medicaid Provider#: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="medicaidProviderNum" required [(ngModel)]="account.billingUser.medicaidProviderNum" type="text" maxlength="8">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                  	<span *ngIf="requiredValidate && signUpForm.controls['medicaidProviderNum'].errors?.required">Medicaid Provider# is required</span>
                                <span
                                  *ngIf="signUpForm.controls['medicaidProviderNum'].errors?.pattern">only 8 numbers</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>TaxID: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName ="taxID" required [(ngModel)]="account.base.taxID" type="text">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                  	<span *ngIf="requiredValidate && signUpForm.controls['taxID'].errors?.required">TaxID is required</span>
                                <span
                                  *ngIf="signUpForm.controls['taxID'].errors?.pattern">only numbers</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>Contact Name: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="contactName" required [(ngModel)]="account.base.contactName" type="text">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                  	<span *ngIf="requiredValidate && signUpForm.controls['contactName'].errors?.required">Contact Name is required</span>
                                <span
                                  *ngIf="signUpForm.controls['contactName'].errors?.pattern">no special characters</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>Contact Tel.: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="contactTel" required [(ngModel)]="account.base.contactTel" type="text" maxlength="10">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                  	<span *ngIf="requiredValidate && signUpForm.controls['contactTel'].errors?.required">Contact Tel is required</span>
                                <span
                                  *ngIf="signUpForm.controls['contactTel'].errors?.pattern">only 10 numbers</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>Location Code: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText
                         formControlName="locationCode" required
                         [(ngModel)]="account.base.locationCode" type="text">
             		<div class="ui-g-12 form-control-feedback left-padding-0">
		                <span *ngIf="requiredValidate && signUpForm.controls['locationCode'].errors?.required">Location Code is required</span>
	              	</div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>Email: </span><span class="text-danger">*</span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="email" required [(ngModel)]="account.billingUser.email" type="text">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                  	<span *ngIf="requiredValidate && signUpForm.controls['email'].errors?.required">Email is required</span>
                                <span
                                  *ngIf="signUpForm.controls['email'].errors?.pattern">email is invalid</span>
                  </div>
                </div>
              </div>
              <div class="ui-g">
                <div class="ui-g-4"><span>API: </span></div>
                <div class="ui-g-8">
                  <input pInputText formControlName="api" [(ngModel)]="account.base.api" type="text">
                  <div class="ui-g-12 form-control-feedback left-padding-0">
                                <span
                                  *ngIf="signUpForm.controls['api'].errors?.pattern">string only</span>
                  </div>
                </div>
              </div>

              <div class="ui-g">
                <div class="ui-g-4"><span>Start Date: </span></div>
                <div class="ui-g-8">
                  <p-calendar formControlName="startDate" [(ngModel)]="account.base.startDate"></p-calendar>
                </div>
              </div>

              <!--<div class="ui-g">-->
                <!--<div class="ui-g-4"><span>Status: </span></div>-->
                <!--<div class="ui-g-8">-->
                  <!--<p-selectButton formControlName="status" [options]="statusOptions" [(ngModel)]="account.billingUser.status"></p-selectButton>-->
                <!--</div>-->
              <!--</div>-->

            </p-panel>
          </div>
          <!--div class="vertical-line"></div-->
          <div class="ui-g-8">
            <p-panel header="Claim Services">
              <p-accordion>
                <p-accordionTab header="MAS" [selected]="true">
                  <table class="table">
                    <tr>
                      <th>
                        <p-checkbox
                          label="Service Type" (onChange)="setCheckAll($event)"
                          binary="true">
                        </p-checkbox>
                      </th>
                      <th>Service Rate</th>
                      <th>Service Frequency (Days)</th>
                      <th>Cycle</th>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox
                          label="Eligibility"
                          [formControl]="signUpForm.controls['eligibility']"
                          [(ngModel)]="account.base.eligibility"
                          binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input  size="5" formControlName="eligibilityServiceRateUnder" [(ngModel)]="account.base.eligibilityServiceRateUnder" type="text">
                          </div>
                          <div class="ui-g-2">
                            + Over
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="eligibilityServiceOver" [(ngModel)]="account.base.eligibilityServiceOver" type="text">
                          </div>
                          <div class="ui-g-2">
                            trip
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="eligibilityServiceRateOver" [(ngModel)]="account.base.eligibilityServiceRateOver" type="text">
                          </div>
                          <div class="ui-g-2">
                            /trip
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown [options]="eligibilityFrequencys" [(ngModel)]="account.base.eligibilityServiceFrequency" formControlName="eligibilityServiceFrequency"></p-dropdown>
                          </div>
                        </div>
                      </td>
                      
                      <td>
                        <div class="ui-g" *ngIf="account.base.eligibilityServiceFrequency !== 'Daily'">
                          <div class="ui-g-5">
                            <p-calendar *ngIf="account.base.eligibilityServiceFrequency !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="eligibilityCycleStart" [(ngModel)]="account.base.eligibilityCycleStart"></p-calendar>
                            <p-dropdown *ngIf="account.base.eligibilityServiceFrequency === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.eligibilityCycleStart" formControlName="weekly_eligibilityCycleStart"></p-dropdown>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar *ngIf="account.base.eligibilityServiceFrequency !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="eligibilityCycleEnd" [(ngModel)]="account.base.eligibilityCycleEnd"></p-calendar>
                            <p-dropdown *ngIf="account.base.eligibilityServiceFrequency === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.eligibilityCycleEnd" formControlName="weekly_eligibilityCycleEnd"></p-dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox label="Sign off" [formControl]="signUpForm.controls['signOff']" [(ngModel)]="account.base.signOff" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="signOffServiceRate" [(ngModel)]="account.base.signOffServiceRate" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="signOffServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="serviceFrequencys[0]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceFrequencys[0]"> -->
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="ui-g" *ngIf="serviceFrequencys[0] !== 'Daily'">
                          <div class="ui-g-5">
                            <p-calendar *ngIf="serviceFrequencys[0] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="signOffCycleStart" [(ngModel)]="account.base.signOffCycleStart"></p-calendar>
                            <p-dropdown *ngIf="serviceFrequencys[0] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.signOffCycleStart" formControlName="weekly_signOffCycleStart"></p-dropdown>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar *ngIf="serviceFrequencys[0] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="signOffCycleEnd" [(ngModel)]="account.base.signOffCycleEnd"></p-calendar>
                            <p-dropdown *ngIf="serviceFrequencys[0] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.signOffCycleEnd" formControlName="weekly_signOffCycleEnd"></p-dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p-checkbox label="General billing" class="align-middle"
                                    [formControl]="signUpForm.controls['generalBilling']"
                                    [(ngModel)]="account.base.generalBilling" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="generalBillingServiceRateLeg" [(ngModel)]="account.base.generalBillingServiceRateLeg" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg +
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="generalBillingServicePercent" [(ngModel)]="account.base.generalBillingServicePercent" type="text">
                          </div>
                          <div class="ui-g-3">
                            %*Amount
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="generalBillingServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="serviceFrequencys[1]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceFrequencys[1]"> -->
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="ui-g" *ngIf="serviceFrequencys[1] !== 'Daily'">
                          <div class="ui-g-5">
                            <p-calendar *ngIf="serviceFrequencys[1] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="generalBillingCycleStart" [(ngModel)]="account.base.generalBillingCycleStart"></p-calendar>
                            <p-dropdown *ngIf="serviceFrequencys[1] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.generalBillingCycleStart" formControlName="weekly_generalBillingCycleStart"></p-dropdown>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar *ngIf="serviceFrequencys[1] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="generalBillingCycleEnd" [(ngModel)]="account.base.generalBillingCycleEnd"></p-calendar>
                            <p-dropdown *ngIf="serviceFrequencys[1] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.generalBillingCycleEnd" formControlName="weekly_generalBillingCycleEnd"></p-dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p-checkbox label="Correction"
                                    [formControl]="signUpForm.controls['correction']"
                                    [(ngModel)]="account.base.correction" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="correctionServiceRateLeg" [(ngModel)]="account.base.correctionServiceRateLeg" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg +
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="correctionServicePercent" [(ngModel)]="account.base.correctionServicePercent" type="text">
                          </div>
                          <div class="ui-g-3">
                            %*Amount
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="correctionServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="serviceFrequencys[2]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceFrequencys[2]"> -->
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="ui-g" *ngIf="serviceFrequencys[2] !== 'Daily'">
                          <div class="ui-g-5">
                            <p-calendar *ngIf="serviceFrequencys[2] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="correctionCycleStart" [(ngModel)]="account.base.correctionCycleStart"></p-calendar>
                            <p-dropdown *ngIf="serviceFrequencys[2] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.correctionCycleStart" formControlName="weekly_correctionCycleStart"></p-dropdown>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar *ngIf="serviceFrequencys[2] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="correctionCycleEnd" [(ngModel)]="account.base.correctionCycleEnd"></p-calendar>
                            <p-dropdown *ngIf="serviceFrequencys[2] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.correctionCycleEnd" formControlName="weekly_correctionCycleEnd"></p-dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p-checkbox label="Look Back" [formControl]="signUpForm.controls['lookBack']" [(ngModel)]="account.base.lookBack" binary="true"></p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="lookBackServiceRateLeg" [(ngModel)]="account.base.lookBackServiceRateLeg" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg +
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="lookBackServicePercent" [(ngModel)]="account.base.lookBackServicePercent" type="text">
                          </div>
                          <div class="ui-g-3">
                            %*Amount
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="lookBackServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="serviceFrequencys[3]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="serviceFrequencys[3]"> -->
                          </div>
                        </div>
                      </td>

                      <td>
                        <div class="ui-g" *ngIf="serviceFrequencys[3] !== 'Daily'">
                          <div class="ui-g-5">
                            <p-calendar *ngIf="serviceFrequencys[3] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="lookBackCycleStart" [(ngModel)]="account.base.lookBackCycleStart"></p-calendar>
                            <p-dropdown *ngIf="serviceFrequencys[3] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.lookBackCycleStart" formControlName="weekly_lookBackCycleStart"></p-dropdown>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar *ngIf="serviceFrequencys[3] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="lookBackCycleEnd" [(ngModel)]="account.base.lookBackCycleEnd"></p-calendar>
                            <p-dropdown *ngIf="serviceFrequencys[3] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.lookBackCycleEnd" formControlName="weekly_lookBackCycleEnd"></p-dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <p-checkbox label="Congestion Report"
                                    [formControl]="signUpForm.controls['congestionReport']"
                                    [(ngModel)]="account.base.congestionReport" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="congestionReportServiceRateUnder" [(ngModel)]="account.base.congestionReportServiceRateUnder" type="text">
                          </div>
                          <div class="ui-g-2">
                            + Over
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="congestionReportServiceOver" [(ngModel)]="account.base.congestionReportServiceOver" type="text">
                          </div>
                          <div class="ui-g-2">
                            leg
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="congestionReportServiceRateOver" [(ngModel)]="account.base.congestionReportServiceRateOver" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg
                          </div>
                      </div>
                      </td>
                      <td style="text-align: center;">
                        Monthly
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>
                        <p-checkbox label="TLC Report"
                                    [formControl]="signUpForm.controls['tlcReport']"
                                    [(ngModel)]="account.base.tlcReport" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="tlcReportServiceRateUnder" [(ngModel)]="account.base.tlcReportServiceRateUnder" type="text">
                          </div>
                          <div class="ui-g-2">
                            + Over
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="tlcReportServiceOver" [(ngModel)]="account.base.tlcReportServiceOver" type="text">
                          </div>
                          <div class="ui-g-2">
                            leg
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="tlcReportServiceRateOver" [(ngModel)]="account.base.tlcReportServiceRateOver" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg
                          </div>
                        </div>
                      </td>
                      <td style="text-align: center;">
                        Monthly
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox label="Monthly Fee"
                        [formControl]="signUpForm.controls['monthlyFee']"
                        [(ngModel)]="account.base.monthlyFee" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <input size="20" formControlName="monthlyAmount" [(ngModel)]="account.base.monthlyAmount" type="text">
                      </td>
                      <td></td>

                      <td>
                        <div class="ui-g">
                          <div class="ui-g-5">
                            <p-calendar formControlName="monthlyFeeCycleStart" dateFormat="yy-mm-dd" [(ngModel)]="account.base.monthlyFeeCycleStart"></p-calendar>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar formControlName="monthlyFeeCycleEnd" dateFormat="yy-mm-dd" [(ngModel)]="account.base.monthlyFeeCycleEnd"></p-calendar>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Contract Period</td>
                      <td colspan="4">
                        <div class="ui-g">
                          <div class="ui-g-3">
                            <p-calendar formControlName="contractStart" [(ngModel)]="account.base.contractStart"></p-calendar>
                          </div>
                          <div class="ui-g-1">
                            To
                          </div>
                          <div class="ui-g-3">
                            <p-calendar formControlName="contractEnd" [(ngModel)]="account.base.contractEnd"></p-calendar>
                          </div>
                          <div class="ui-g-5">
                            <p-fileUpload #fileUpload mode="basic" name="contractFile[]" customUpload="true" (uploadHandler)="uploadMasContractFile($event)" [auto]="true" chooseLabel="Upload Contract (PDF)"></p-fileUpload>
                            <ul>
                              <!-- {{masfileContract}} -->
                              <li *ngFor="let fileContract of masfileContract" >
                                <a href="{{fileContract.fileUrl}}" download="{{fileContract.fileName}}" target="_blank">{{fileContract.fileName}}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Biller</td>
                      <td>
                        <input size="20" formControlName="masBiller" [(ngModel)]="account.base.biller" type="text">
                      </td>
                    </tr>
                  </table>
                </p-accordionTab>

                <p-accordionTab header="FIDELIS">
                  <table class="table">
                    <tr>
                      <!-- <th>
                        <p-checkbox
                          label="Service Type" (onChange)="setCheckAll($event)"
                          binary="true">
                        </p-checkbox>
                      </th> -->
                      <th>Service Type</th>
                      <th>Service Rate</th>
                      <th>Service Frequency (Days)</th>
                      <th>Cycle</th>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox label="General billing" class="align-middle"
                                    formControlName="fid_generalBilling"
                                    [(ngModel)]="account.base.fid.generalBilling" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="fid_generalBillingServiceRateLeg" [(ngModel)]="account.base.fid.generalBillingServiceRateLeg" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg +
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="fid_generalBillingServicePercent" [(ngModel)]="account.base.fid.generalBillingServicePercent" type="text">
                          </div>
                          <div class="ui-g-3">
                            %*Amount
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="fid_generalBillingServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="fid_serviceFrequencys[0]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="fid_serviceFrequencys[0]"> -->
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g" *ngIf="fid_serviceFrequencys[0] !== 'Daily'">
                          <div class="ui-g-5">
                            <p-calendar *ngIf="fid_serviceFrequencys[0] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="fid_generalBillingCycleStart" [(ngModel)]="account.base.fid.generalBillingCycleStart"></p-calendar>
                            <p-dropdown *ngIf="fid_serviceFrequencys[0] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.fid.generalBillingCycleStart" formControlName="weekly_fid_generalBillingCycleStart"></p-dropdown>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar *ngIf="fid_serviceFrequencys[0] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="fid_generalBillingCycleEnd" [(ngModel)]="account.base.fid.generalBillingCycleEnd"></p-calendar>
                            <p-dropdown *ngIf="fid_serviceFrequencys[0] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.fid.generalBillingCycleEnd" formControlName="weekly_fid_generalBillingCycleEnd"></p-dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox label="Monthly Fee"
                        [formControl]="signUpForm.controls['monthlyFee']"
                        [(ngModel)]="account.base.monthlyFee" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <input size="20" formControlName="fid_monthlyAmount" [(ngModel)]="account.base.fid.monthlyAmount" type="text">
                      </td>
                      <td></td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-5">
                            <p-calendar formControlName="fid_monthlyFeeCycleStart" dateFormat="yy-mm-dd" [(ngModel)]="account.base.fid.monthlyFeeCycleStart"></p-calendar>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar formControlName="fid_monthlyFeeCycleEnd" dateFormat="yy-mm-dd" [(ngModel)]="account.base.fid.monthlyFeeCycleEnd"></p-calendar>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Contract Period</td>
                      <td colspan="4">
                        <div class="ui-g">
                          <div class="ui-g-3">
                            <p-calendar formControlName="fid_contractStart" [(ngModel)]="account.base.fid.contractStart"></p-calendar>
                          </div>
                          <div class="ui-g-1">
                            To
                          </div>
                          <div class="ui-g-3">
                            <p-calendar formControlName="fid_contractEnd" [(ngModel)]="account.base.fid.contractEnd"></p-calendar>
                          </div>
                          <div class="ui-g-5">
                            <p-fileUpload #fileUpload mode="basic" name="fidContractFile[]" customUpload="true" (uploadHandler)="uploadFidContractFile($event)" [auto]="true" chooseLabel="Upload Contract (PDF)"></p-fileUpload>
                            <ul>
                              <li *ngFor="let fileContract of fidfileContract" >
                                <a href="{{fileContract.fileUrl}}" download="{{fileContract.fileName}}" target="_blank">{{fileContract.fileName}}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </p-accordionTab>

                <p-accordionTab header="MTM">
                  <table class="table">
                    <tr>
                      <th>Service Type</th>
                      <th>Service Rate</th>
                      <th>Service Frequency (Days)</th>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox label="General billing" class="align-middle"
                                    formControlName="mtm_generalBilling"
                                    [(ngModel)]="account.base.mtm.generalBilling" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="mtm_generalBillingServiceRateLeg" [(ngModel)]="account.base.mtm.generalBillingServiceRateLeg" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg +
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="mtm_generalBillingServicePercent" [(ngModel)]="account.base.mtm.generalBillingServicePercent" type="text">
                          </div>
                          <div class="ui-g-3">
                            %*Amount
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="mtm_generalBillingServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="mtm_serviceFrequencys[0]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="mtm_serviceFrequencys[0]"> -->
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Contract Period</td>
                      <td colspan="4">
                        <div class="ui-g">
                          <div class="ui-g-3">
                            <p-calendar formControlName="mtm_contractStart" [(ngModel)]="account.base.mtm.contractStart"></p-calendar>
                          </div>
                          <div class="ui-g-1">
                            To
                          </div>
                          <div class="ui-g-3">
                            <p-calendar formControlName="mtm_contractEnd" [(ngModel)]="account.base.mtm.contractEnd"></p-calendar>
                          </div>
                          <div class="ui-g-5">
                            <p-fileUpload #fileUpload mode="basic" name="contractFile[]" customUpload="true" (uploadHandler)="uploadMTMContractFile($event)" [auto]="true" chooseLabel="Upload Contract (PDF)"></p-fileUpload>
                            <ul>
                              <li *ngFor="let fileContract of mtmfileContract" >
                                <a href="{{fileContract.fileUrl}}" download="{{fileContract.fileName}}" target="_blank">{{fileContract.fileName}}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </p-accordionTab>

                <p-accordionTab header="SENTRY">
                  <table class="table">
                    <tr>
                      <th>Service Type</th>
                      <th>Service Rate</th>
                      <th>Service Frequency (Days)</th>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox label="General billing" class="align-middle"
                                    formControlName="sentry_generalBilling"
                                    [(ngModel)]="account.base.sentry.generalBilling" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="sentry_generalBillingServiceRateLeg" [(ngModel)]="account.base.sentry.generalBillingServiceRateLeg" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg +
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="sentry_generalBillingServicePercent" [(ngModel)]="account.base.sentry.generalBillingServicePercent" type="text">
                          </div>
                          <div class="ui-g-3">
                            %*Amount
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="sentry_generalBillingServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="sentry_serviceFrequencys[0]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="sentry_serviceFrequencys[0]"> -->
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Contract Period</td>
                      <td colspan="4">
                        <div class="ui-g">
                          <div class="ui-g-3">
                            <p-calendar formControlName="sentry_contractStart" [(ngModel)]="account.base.sentry.contractStart"></p-calendar>
                          </div>
                          <div class="ui-g-1">
                            To
                          </div>
                          <div class="ui-g-3">
                            <p-calendar formControlName="sentry_contractEnd" [(ngModel)]="account.base.sentry.contractEnd"></p-calendar>
                          </div>
                          <div class="ui-g-5">
                            <p-fileUpload #fileUpload mode="basic" name="contractFile[]" customUpload="true" (uploadHandler)="uploadSentryContractFile($event)" [auto]="true" chooseLabel="Upload Contract (PDF)"></p-fileUpload>

                            <ul>
                              <li *ngFor="let fileContract of sentryfileContract" >
                                <a href="{{fileContract.fileUrl}}" download="{{fileContract.fileName}}" target="_blank">{{fileContract.fileName}}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </p-accordionTab>

                <p-accordionTab header="LOG">
                  <table class="table">
                    <tr>
                      <th>Service Type</th>
                      <th>Service Rate</th>
                      <th>Service Frequency (Days)</th>
                      <th>Cycle</th>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox label="General billing" class="align-middle"
                                    formControlName="log_generalBilling"
                                    [(ngModel)]="account.base.log.generalBilling" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="log_generalBillingServiceRateLeg" [(ngModel)]="account.base.log.generalBillingServiceRateLeg" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg +
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="log_generalBillingServicePercent" [(ngModel)]="account.base.log.generalBillingServicePercent" type="text">
                          </div>
                          <div class="ui-g-3">
                            %*Amount
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="log_generalBillingServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="log_serviceFrequencys[0]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="log_serviceFrequencys[0]"> -->
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g" *ngIf="log_serviceFrequencys[0] !== 'Daily'">
                          <div class="ui-g-5">
                            <p-calendar *ngIf="log_serviceFrequencys[0] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="log_GeneralBillingCycleStart" [(ngModel)]="account.base.log.generalBillingCycleStart"></p-calendar>
                            <p-dropdown *ngIf="log_serviceFrequencys[0] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.log.generalBillingCycleStart" formControlName="weekly_log_GeneralBillingCycleStart"></p-dropdown>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar *ngIf="log_serviceFrequencys[0] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="log_GeneralBillingCycleEnd" [(ngModel)]="account.base.log.generalBillingCycleEnd"></p-calendar>
                            <p-dropdown *ngIf="log_serviceFrequencys[0] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.log.generalBillingCycleEnd" formControlName="weekly_log_GeneralBillingCycleEnd"></p-dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p-checkbox label="Look Back" class="align-middle"
                                    formControlName="log_lookBack"
                                    [(ngModel)]="account.base.log.lookBack" binary="true">
                        </p-checkbox>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <input size="5" formControlName="log_lookBackServiceRateLeg" [(ngModel)]="account.base.log.lookBackServiceRateLeg" type="text">
                          </div>
                          <div class="ui-g-2">
                            /leg +
                          </div>
                          <div class="ui-g-2">
                            <input size="5" formControlName="log_lookBackServicePercent" [(ngModel)]="account.base.log.lookBackServicePercent" type="text">
                          </div>
                          <div class="ui-g-3">
                            %*Amount
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <p-dropdown formControlName="log_lookBackServiceFrequency" [options]="eligibilityFrequencys" [(ngModel)]="log_serviceFrequencys[1]"></p-dropdown>
                            <!-- <input size="20" [ngModelOptions]="{standalone: true}" [(ngModel)]="log_serviceFrequencys[1]"> -->
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="ui-g" *ngIf="log_serviceFrequencys[1] !== 'Daily'">
                          <div class="ui-g-5">
                            <p-calendar *ngIf="log_serviceFrequencys[1] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="log_LookBackCycleStart" [(ngModel)]="account.base.log.lookBackCycleStart"></p-calendar>
                            <p-dropdown *ngIf="log_serviceFrequencys[1] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.log.lookBackCycleStart" formControlName="weekly_log_LookBackCycleStart"></p-dropdown>
                          </div>
                          <div class="ui-g-2">
                            To
                          </div>
                          <div class="ui-g-5">
                            <p-calendar *ngIf="log_serviceFrequencys[1] !== 'Weekly'" dateFormat="yy-mm-dd" formControlName="log_LookBackCycleEnd" [(ngModel)]="account.base.log.lookBackCycleEnd"></p-calendar>
                            <p-dropdown *ngIf="log_serviceFrequencys[1] === 'Weekly'" [options]="dayNames" [(ngModel)]="account.base.log.lookBackCycleEnd" formControlName="weekly_log_LookBackCycleEnd"></p-dropdown>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Contract Period</td>
                      <td colspan="4">
                        <div class="ui-g">
                          <div class="ui-g-3">
                            <p-calendar formControlName="log_contractStart" [(ngModel)]="account.base.log.contractStart"></p-calendar>
                          </div>
                          <div class="ui-g-1">
                            To
                          </div>
                          <div class="ui-g-3">
                            <p-calendar formControlName="log_contractEnd" [(ngModel)]="account.base.log.contractEnd"></p-calendar>
                          </div>
                          <div class="ui-g-5">
                            <p-fileUpload #fileUpload mode="basic" name="contractFile[]" customUpload="true" (uploadHandler)="uploadLogContractFile($event)" [auto]="true" chooseLabel="Upload Contract (PDF)"></p-fileUpload>
                            <ul>
                              <li *ngFor="let fileContract of logfileContract" >
                                <a href="{{fileContract.fileUrl}}" download="{{fileContract.fileName}}" target="_blank">{{fileContract.fileName}}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </p-accordionTab>
              </p-accordion>
            </p-panel>

            <p-panel header="Account Information">
              <div class="ui-g">
                <div class="ui-g-8">
                  <label>
                    <span>User Name</span>
                    <span class="text-danger">*</span>
                  </label>
                  <input type = "text" pInputText
                         formControlName="username" required 
                         [(ngModel)]="account.billingUser.username">
               	  <div class="ui-g-12 form-control-feedback left-padding-0">
  	                <span *ngIf="requiredValidate && signUpForm.controls['username'].errors?.required">User Name is required</span>
  	              </div>
                </div>
                <div class="ui-g-4">
                  <div class="align-middle" style="margin-top: 35px;">
                    <span *ngIf="signUpForm.get('username').status==='PENDING'">Checking ...</span>
                    <div *ngIf="signUpForm.get('username').status==='VALID'" class="text-primary">
                      <i class="text-primary align-middle fa fa-check"></i>
                      <span>User name is available</span>
                    </div>
                    <div *ngIf="signUpForm.get('username').errors?.userTaken" class="text-danger">
                      <i class="text-danger align-middle fa fa-close"></i>
                      Not Available
                    </div>

                    <div class="text-danger"
                         *ngIf="signUpForm.controls['username'].hasError('minlength') ">
                      Must be at least 8 characters!
                    </div>

                  </div>
                </div>

                <div class="ui-g-8">
                  <div class="ui-g-12">
                    <label>Password<span class="text-danger">*</span>:</label>
                    <input  type = "password" formControlName="passwordSalt" required [(ngModel)]="account.billingUser.password" pInputText>
                    <div class="ui-g-12 form-control-feedback left-padding-0">
	                	<span *ngIf="requiredValidate && signUpForm.controls['passwordSalt'].errors?.required">Password is required</span>
	              	</div>
                    <div *ngIf="signUpForm.controls['passwordSalt'].invalid && signUpForm.controls['passwordSalt'].dirty" class="ui-g-12 form-control-feedback left-padding-0">
                      <div class="text-danger"
                           *ngIf="signUpForm.controls['passwordSalt'].hasError('required') || signUpForm.controls['passwordSalt'].hasError('minlength') ">
                        Must be at least 8 characters!
                      </div>
                      <div class="text-danger"
                           *ngIf="signUpForm.controls['passwordSalt'].hasError('required') || signUpForm.controls['passwordSalt'].hasError('hasNumber')">
                        Must contain at least 1 number!
                      </div>
                      <div class="text-danger"
                           *ngIf="signUpForm.controls['passwordSalt'].hasError('required') || signUpForm.controls['passwordSalt'].hasError('hasCapitalCase')">
                        Must contain at least 1 in Capital Case!
                      </div>
                      <div class="text-danger"
                           *ngIf="signUpForm.controls['passwordSalt'].hasError('required') || signUpForm.controls['passwordSalt'].hasError('hasSmallCase')">
                        Must contain at least 1 Letter in Small Case!
                      </div>
                      <div class="text-danger"
                           *ngIf="signUpForm.controls['passwordSalt'].hasError('required') || signUpForm.controls['passwordSalt'].hasError('hasSpecialCharacters')">
                        Must contain at least 1 Special Character!
                      </div>
                    </div>
                  </div>
                  <div class="ui-g-12">
                    <label>Confirm Password:<span class="text-danger">*</span>:</label>
                    <input  type = "password" formControlName="confirmPassword" required [(ngModel)]="account.billingUser.confirmPassword" pInputText>
                    <div class="ui-g-12 form-control-feedback left-padding-0">
	                	<span *ngIf="requiredValidate && signUpForm.controls['confirmPassword'].errors?.required">Confirm Password is required</span>
	              	</div>
                    <div *ngIf="signUpForm.controls['confirmPassword'].dirty" class="ui-g-12 form-control-feedback left-padding-0">
                      <label class="text-danger" *ngIf="signUpForm.controls['confirmPassword'].hasError('NoPassswordMatch')">
                        Password do not match
                      </label>
                    </div>

                  </div>
              	</div>

              	<div class="ui-g-12">
                	Broker account Information:
              	</div>
              	<div class="ui-g-12">
	                <div class="ui-g-12">
	                  <!--<button type="button" pButton icon="fa fa-plus" (click)="addWebInfo()" label="Choose"></button>-->
	                  <p-multiSelect
	                    [options]="types"
	                    [ngModelOptions]="{standalone: true}"
	                    [(ngModel)]="chooseSelected"
	                    (onChange)="addWebInfo($event)">
	                  </p-multiSelect>
	                </div>
              	</div>

                <div class="ui-g-12">
                  <p-table [value]="websiteinfos" [responsive]="true" editMode="cell">
                    <ng-template pTemplate="header">
                      <tr>
                        <th style="font-weight: 700">Website name</th>
                        <th style="font-weight: 700">User name</th>
                        <th style="font-weight: 700">
                          Password
                        </th>
                        <th width="10%" style="font-weight: 700">
                          <!--span>Action</span-->
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                      <tr>
                        <td *ngIf="rowData.websiteNameOthers">
                          <input [ngModelOptions]="{standalone: true}" pInputText [(ngModel)]="rowData.websiteName" type="text">
                        </td>
                        <td *ngIf="!rowData.websiteNameOthers">
                          <input [ngModelOptions]="{standalone: true}" pInputText [(ngModel)]="rowData.websiteName" type="text" readonly>
                        </td>
                        <td>
                          <input [ngModelOptions]="{standalone: true}" pInputText [(ngModel)]="rowData.userName" type="text">
                        </td>
                        <td>
                          <input [ngModelOptions]="{standalone: true}" pInputText [(ngModel)]="rowData.password" type="password">
                        </td>
                        <td>
                          <div class="ui-g-12">
                            <i class="fa ui-icon-remove-circle" (click)="removeWebInfo(rowIndex, rowData)"></i>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>

              </div>
            </p-panel>

            <div class="ui-g">
              <div class="ui-g-3 ui-g-offset-6">
              	<button pButton type="button" class="text-uppercase ml-auto" label="Sign In" routerLink="/login"></button>
              </div>
              <div class="ui-g-3">
                <button pButton type="submit" class="text-uppercase ml-auto" label="Submit" (click)="submitSignUp(signUpDataForm)"></button>
              </div>
            </div>
          </div>

        </div>
      </form>
    </p-card>

  </div>
</div>
