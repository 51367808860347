import { MTM_URL } from '../constant';

export const MTM_MENU = [
  {
    label: 'Claim',
    routerLink: MTM_URL.CLAIM
  }
  //,
  // {
  //   label: 'Report',
  //   routerLink: MTM_URL.REPORT
  // },
  // {
  //   label: 'Invoice',
  //   routerLink: MTM_URL.INVOICE
  // },
  // {
  //   label: 'Help',
  //   routerLink: MTM_URL.HELP
  // }
];
