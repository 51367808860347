import { LOGISTI_CARE_URL } from '../constant';

export const LOGISTI_CARE_MENU = [
  {
    label: 'Claim',
    routerLink: LOGISTI_CARE_URL.CLAIM
  },
  {
    label: 'Look Back',
    routerLink: LOGISTI_CARE_URL.LOOK_BACK
  },
  {
    label: 'Report',
    routerLink: LOGISTI_CARE_URL.REPORT
  },
  {
    label: 'Billing Log',
    routerLink: LOGISTI_CARE_URL.BILLING_LOG
  }
];
