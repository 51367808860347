export enum LoginType {
    BASE_LOGIN = 0,
    BILLER_LOGIN = 1,
    BASE_ADMIN = 4,
    BASE_USER = 5,
    BILLER_ADMIN = 2,
    BILLER_USER = 3,
}

export enum LoginTypeDescription {
  BASE_LOGIN = 'BASE_LOGIN',
  BILLER_LOGIN = 'BILLER_LOGIN',
  BASE_ADMIN = 'BASE_ADMIN',
  BASE_USER = 'BASE_USER',
  BILLER_ADMIN = 'BILLER_ADMIN',
  BILLER_USER = 'BILLER_USER',
}