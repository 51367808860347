export enum SENTRY_URL {
  LOGIN = '/login',
  UPLOAD = '/app/upload',
  BILLER = '/app/upload/biller',
  CLAIM = '/app/sentry/claim',
  REPORT = '/app/sentry/report',
  HELP = '/app/sentry/help',
  SCHEDULER = '/app/sentry/scheduler',
  INVOICE = '/app/sentry/invoice',
  LOGISTICARE = '/app/logisticare',
  SENTRY = '/app/sentry',
  FIDELIS = '/app/fidelis',
  CTS = '/app/cts',
  MY_ACCOUNT = '/app/myaccount',
  PAYMENT_METHOD = '/app/myaccount/payment',
  MY_CONTRACT = '/app/myaccount/contract-detail/info'
}
