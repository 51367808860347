import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import {InvoiceSearchRequestModel} from "@app/_model/invoice-search-request.model";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private readonly URL = `${environment.api.server}/invoices`;

  constructor(private http: HttpClient) { }

  getInvoiceSummary(options: {
    medicaidProviderNum: any,
    invoiceNumber: any,
  }) {
    const URL = `${this.URL}/list`;
    let headers: any
    
    return this.http.get(URL, {
      params: options,
      headers: headers
    });
  }

  getInvoiceNumbers(medicaidProviderNum: any) {
    const URL = `${this.URL}/invoice-numbers`;
    return this.http.get(URL, {
      params: { medicaidProviderNum: medicaidProviderNum }
    });
  }

  getInvoiceDates(medicaidProviderNum: any) {
    const URL = `${this.URL}/invoice-dates`;
    return this.http.get(URL, {
      params: { medicaidProviderNum: medicaidProviderNum }
    });
  }

  downloadExportFile(options: {
    medicaidProviderNum: any,
    invoiceNumber: any,
  }) {
    const URL = `${this.URL}/export`;
    return this.http.get(URL, {
      observe: 'response',
      responseType: 'blob',
      params: options
    });
  }
  
  generateTransferInvoiceData(medicaidProviderNum) {
    const URL = `${this.URL}/generate/transfer-invoice-data`;
    return this.http.get(URL, {
      params: { medicaidProviderNum: medicaidProviderNum }
    });
  }
  
  searchInvoices(params: InvoiceSearchRequestModel) {
  
    params.startDate = moment.utc(params.startDate).local().format('MM/DD/YYYY 00:00:00');
    params.endDate = moment.utc(params.endDate).local().format('MM/DD/YYYY 23:59:59');
    const body = {
      pageable: {
        pageNumber: params.page,
        pageSize: params.size,
      },
      timeRange: { startTime: params.startDate, endDate: params.endDate },
      medicaidProviderNum: params.medicaidProviderNum,
      invoiceNumber: params.invoiceNumber,
      serviceType: params.serviceType,
      paymentStatus: params.paymentStatus,
      sortField: params.sortField,
      sortOrder: params.sortOrder
    }
    const URL = `${this.URL}/invoice-summary`;
    return this.http.post(URL, body);
  }

}
