<div class="opr-loader" *ngIf="isLoading$|async">
  <div class="opr-loader__spinner">
    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="#15523d" />
          <stop offset="100%" stop-color="#a1f2a1" />
        </linearGradient>
      </defs>
      <circle cx="50" cy="50" r="47" stroke="url(#gradient)" stroke-width="6" fill="none"
        transform='rotate(90 50 50)' />
    </svg>
    <span class="loading-info">Loading . . .</span>
  </div>
</div>