import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'opr-contract-dialog',
  templateUrl: './contract-dialog.component.html',
  styleUrls: ['./contract-dialog.component.scss']
})
export class ContractDialogComponent implements OnInit, AfterViewInit {

  @Input()
  data;
  @Input()
  display;
  @Output() displayChange = new EventEmitter();

  constructor() {
  }

  ngAfterViewInit() {
  }

  onClickClose() {
    this.displayChange.emit(false);
  }

  onFormUpdateSuccess() {
    this.displayChange.emit(false);
  }

  ngOnInit(): void {
  }

}
