import {HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Account} from '@app/_model/account.model';
import {User} from '@app/_model/user.model';
import {Base} from '@app/_model/base.model';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import { LoginType } from '@app/_config/constant';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {
  private static readonly URL = environment.api.server;
  constructor(private http: HttpClient) { }

  public getAccountByMedicaidProviderNumber(medicaidNum: string): Observable<Account> {
    const URL = `${MyAccountService.URL}/account/${medicaidNum}`;
    return this.http.get<Account>(URL);
  }

  update(account: Account) {
    //const URL = `${MyAccountService.URL}/billing-user`;
    const URL = `${MyAccountService.URL}/account/update`;
    return this.http.post(URL, account);
  }

  isAccountNameAvail(accountName: string) {
    const URL = `${MyAccountService.URL}/check/${accountName}`;
    return this.http.get(URL);
  }

  // check if username available in update account function
  isAccountNameAvailInUpdate(account: Account) {
    const URL = `${MyAccountService.URL}/account/checkAccountNameAvailInUpdate`;
    return this.http.post(URL, account);
  }

  /**
  * User management, get list of users by medicaidProviderNumber
  */
  public getUserByMedicaidProviderNumber(medicaidNum: string): Observable<User[]> {
    const URL = `${MyAccountService.URL}/billing-user/${medicaidNum}`;
    return this.http.get<User[]>(URL);
  }

  // Get all biller users (both BILLER_USER and BILLER_ADMIN)
  public getBillerUsers(user: User): Observable<User[]> {
    const URL = `${MyAccountService.URL}/billing-user/getBillerUsers`;
    return this.http.post<User[]>(URL, user);
  }

  /** Call update BillingUser API
   * API: billing-user
   * PathParam: userId
   * BodyParam: user: User
  */
  updateBillingUser(user: User) {
    return this.http.put(`${MyAccountService.URL}/billing-user/${user.userId}`, user);
  }

  /** Call create BillingUser API
   * API: billing-user
   * BodyParam: new User
  */
  addBillingUser(user: User) {
    return this.http.post(`${MyAccountService.URL}/billing-user/create`, user);
  }

  // Change password of user in billingUser
  changePassword(user: User) {
    const URL = `${MyAccountService.URL}/billing-user/changePassword`;
    return this.http.post(URL, user);
  }

  // Delete user by username
  deleteBillingUserByUsername(username: string) {
    const URL = `${MyAccountService.URL}/billing-user/deleteBillingUserByUserName/${username}`;
    return this.http.get(URL);
  }

  /** Call check username of BillingUser API
   * API: billing-user/checkUsername
   * BodyParam: username
  */
  isBillingUserNameAvail(username: string) {
    const URL = `${MyAccountService.URL}/billing-user/checkUsername/${username}`;
    return this.http.get(URL);
  }

  // check email available of billing user
  isEmailAvail(email: string) {
    const URL = `${MyAccountService.URL}/billing-user/checkEmail/${email}`;
    return this.http.get(URL);
  }

  public getBillingUserByUsername(username: string): Observable<User> {
    const URL = `${MyAccountService.URL}/billing-user/getUserByUserName/${username}`;
    return this.http.get<User>(URL);
  }
}
