import { NgModule, InjectionToken } from '@angular/core';
import { APP_URL_PERMISSION_CONFIG } from './constant';
import { ConfirmationService } from 'primeng/api';

export const APP_URL_PERMISSION = new InjectionToken<string>('Application URL Permission');
export const DEFAULT_HTTP_TIMEOUT = new InjectionToken<string>('Default Timeout');

@NgModule({
  imports: [],
  declarations: [],
  providers: [
    { provide: APP_URL_PERMISSION, useValue: APP_URL_PERMISSION_CONFIG },
    { provide: DEFAULT_HTTP_TIMEOUT, useValue: 60000 },
    ConfirmationService,
  ],
})
export class ConfigModule { }
