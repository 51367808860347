// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    root: 'https://dev-api.operrbilling.com/bureau-v1-backend',
    server: 'https://dev-api.operrbilling.com/bureau-v1-backend/api/v1',
    scheduler: "https://billing-scheduler-dev.operr.com"
  },
  firebase: {
    apiKey: 'AIzaSyBqpP6b9X2xGE_FwAJh0631-MGmhD_Llfc',
    authDomain: 'operr-v3-dev.firebaseapp.com',
    databaseURL: 'https://operr-v3-dev.firebaseio.com',
    projectId: 'operr-v3-dev',
    storageBucket: 'operr-v3-dev.appspot.com',
    messagingSenderId: '1004958914525',
    appId: '1:1004958914525:web:9fb30c597444c11e'
  },
  env: 'dev'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
