import { InjectionToken } from '@angular/core';
import { Driver } from '@app/_shared/models';
import * as _ from 'lodash';

import { AuthService } from './service';

export const LOGIN_INFO = new InjectionToken<string>('Login Info');
export const LOGIN_TYPE = new InjectionToken<string>('Login Type');

export function loginInfoFactory(authService: AuthService) {
    return authService.getLoginInfo();
}

export function loginTypeFactory(authService: AuthService) {
    return authService.getLoginType();
}

export function getFleetNumbers(drivers) {
    const fleetNumbers: {label: string, value: string}[] = [];
    if (!_.isNil(drivers)) {
      drivers.forEach((driver: Driver) => {
        fleetNumbers.push({label: driver.fleetNumber, value: driver.fleetNumber});
      });
    }
    return fleetNumbers;
  }