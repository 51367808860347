<!--<div class="page-auth">-->
<!--<div class="main-body">-->
<!--<div class="card card-white">-->
<!--<div class="card-content" *ngIf="!showMessage">-->
<!--<div class="logo">-->
<!--Reset Password-->
<!--</div>-->
<!--<div class="alert alert-erro" *ngIf="message">-->
<!--{{message}}-->
<!--</div>-->
<!--<div class="ui-g-12 ui-md-12">-->
<!--<input type="text" pInputText class="w-100" [(ngModel)]="password" placeholder="New Password"-->
<!--[required]="true">-->
<!--</div>-->
<!--<div class="ui-g-12 ui-md-12">-->
<!--<input type="text" pInputText class="w-100" [(ngModel)]="rePassword" placeholder="Confirm Password"-->
<!--[required]="true">-->
<!--</div>-->
<!--<div class="ui-g-12 ui-md-12">-->
<!--<button pButton type="button" class="pull-right green-btn" label="Submit"></button>-->
<!--</div>-->
<!--</div>-->
<!--<div class="card-content" *ngIf="!showMessage">-->
<!--<p>Your Password Reset successfully, click <a [routerLink]="['/login']">here</a> to Login</p>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->


<div class="main-body d-flex justify-content-center">
  <div class="login-card w-100">
    <p-card styleClass="w-100 p-0">
      <form [formGroup]="form">
        <div class="ui-g form-group">
          <div class="ui-g-12">
            <h2>Reset Password</h2>
          </div>

          <div class="ui-g-12">
            <div class="ui-float-label">
              <input type="password" pInputText class="w-100" formControlName="password" [required]="true" (focus)="resetMessage()">
              <label>New Password *</label>
            </div>
          </div>

          <div class="ui-g-12">
            <div class="ui-float-label">
              <input type="password" pInputText class="w-100" formControlName="rePassword" [required]="true" (focus)="resetMessage()">
              <label>Confirm Password *</label>
            </div>
          </div>

          <div *ngIf="password?.invalid && (password?.dirty || password?.touched)" [innerHTML]="getErrorPassword()" class="text-danger">
          </div>

          <div *ngIf="rePassword?.invalid && !password?.invalid && (rePassword?.dirty || rePassword?.touched)" class="text-danger">
            {{ getErrorRePassword() }}
          </div>
          <div *ngIf="errorMessage" class="alert alert-danger w-100">
            {{ errorMessage }}
          </div>

          <div *ngIf="successMessage" class="alert alert-success w-100">
            {{successMessage}}
          </div>

          <div class="ui-g-12">
            <button pButton [disabled]="form.invalid" type="submit" class="pull-right" label="Submit" (click)="onSubmit()"></button>
          </div>
        </div>
      </form>

      <p-footer>
        <div class="footer text-center p-2">
          <a [routerLink]="['/login']">Login</a>
        </div>
      </p-footer>
    </p-card>
  </div>
</div>
