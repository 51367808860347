import { CoreModule } from './_core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './_auth';
import { ConfigModule } from './_config/config.module';
import { NgxsModule } from '@ngxs/store';
import { AppState } from './_core/service';
import { environment } from 'environments/environment';
import { ServiceModule } from './_service/service.module';
import { NotificationComponent, NotificationService } from "@app/_core";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

@NgModule({
  declarations: [
    AppComponent,
    NotificationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ConfigModule,
    NgxsModule.forRoot(
      [AppState],
      { developmentMode: !environment.production }),
    AuthModule.forRoot(),
    CoreModule,
    ServiceModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule
  ],
  providers: [NotificationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
