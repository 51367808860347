export enum FileUploadObjectType {
    VENDOR = 1,
    PA_ROASTER = 2,
    LIMO_TOTAL_JOB = 3,
    TOTAL_JOB = 4,
    DRIVER = 5,
    TO_CLIENT = 6,
    SIGN_OFF = 7,
    INVOICE = 8,
    EPACE_PA = 9,
    LOOKBACK_VENDOR = 10,
    DRIVER_VEHICLE = 11,
    LOOK_BACK_TOTAL_JOB = 12,
    LOOK_BACK_PA_ROASTER = 13,
    OPERR_TOTAL_JOB = 14,
    OPERR_COMPLETED_JOB = 15,
    LOS_PAYMENT = 16
}

export enum FileUploadDescription {
    VENDOR = 'Vendor',
    VENDOR_PDF = 'Vendor (PDF)',
    VENDOR_EXCEL = 'Vendor (Excel)',
    PA_ROASTER = 'PA Roster',
    DRIVER_VEHICLE = 'Driver & Vehicle',
    LIMO_TOTAL_JOB = 'Limo Total Job',
    TOTAL_JOB = 'Total Job',
    LOS_PAYMENT = 'Los Payment',
    DRIVER = 'Driver',
    CTS = 'CTS',
    REMITTANCE = 'Remittance',
    LOG_PA = 'PA',
    EPACE_PA = 'Epace PA',
    LOOKBACK_VENDOR = 'Look Back vendor',
    OPERR_TOTAL_JOB = 'Operr Total Jobs',
    OPERR_COMPLETED_JOBS = "Operr Completed Jobs",
    AUTHORIZATION_INFO = 'Authorization Info',
    PAYMENT = 'Payment'
}

export enum FileUploadBroker {
  ALL = 'ALL',
  MAS = 'MAS',
  DAYCARE = 'DAYCARE',
  LOGISTICARE = 'LOG',
  MTM = 'MTM',
  SENTRY = 'SENTRY',
  FIDELIS = 'FID',
  CTS = 'CTS',
  CENTREK = 'CENTREK',
  MODIVCARE = 'MODIVCARE',
  ALL_BROKERS = 'ALL_BROKERS'
}

export enum FileUploadType {
  PDF = 'PDF',
  CSV = 'CSV',
  EXCEL = 'EXCEL',
  IMAGE = 'IMAGE',
  EDI = 'EDI',
  TXT = 'TXT',
  CTS = 'CTS'
}

export enum Functionality {
  VENDOR_PROCESS = 'VENDOR_PROCESS',
  TOTAL_JOB_PROCESS = 'TOTAL_JOB_PROCESS',
  REMITTANCE_PROCESS = 'REMITTANCE_PROCESS',
  LOG_PA_PROCESS = 'LOG_PA_PROCESS',
  FID_TOTAL_JOB_PROCESS = 'FID_TOTAL_JOB_PROCESS',
  EPACE_PA = 'EPACE_PA',
  CORRECTION_VENDOR_PROCESS = 'CORRECTION_VENDOR_PROCESS',
  CORRECTION_MAS_PAROSTER_PROCESS = 'CORRECTION_MAS_PAROSTER_PROCESS',
  LOG_EPACE_PA = 'LOG_EPACE_PA',
  LOS_PAYMENT = 'LOS_PAYMENT',
  LOOK_BACK_VENDOR_PROCESS = 'LOOK_BACK_VENDOR_PROCESS',
  LOOK_BACK_EPACE_PA = 'LOOK_BACK_EPACE_PA',
  AUTHORIZATION_INFO = 'AUTHORIZATION_INFO',
  PA_ROASTER = 'PA_ROASTER',
  DRIVER_VEHICLE = 'DRIVER_VEHICLE',
  LIMO_TOTAL_JOB = 'LIMO_TOTAL_JOB',
  TOTAL_JOB = 'TOTAL_JOB',
  DRIVER = 'DRIVER',
  OPERR_TOTAL_JOB = 'OPERR_TOTAL_JOB,',
  PAYMENT = 'PAYMENT'
}

export enum FileProcessUploadObjectType {
  VENDOR = 'VENDOR',
  TOTAL_JOB = 'TOTAL_JOB',
  REMITTANCE = 'REMITTANCE'
}

export const fileUploadErrorCode = {
  2 : 'Invoice number Missing'
}
