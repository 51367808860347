<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [dismissableMask]="true"
          [style]="{ height: '800px', minWidth: '800px', width: '1000px' }"
          (onHide)="onClickClose()">
  <p-tabView>
    <p-tabPanel header="Operr Bureau" [headerStyle]="{'outline': 0}">
      <opr-contract-form (updateFormSuccess)="onFormUpdateSuccess()" [formHeight]="550"></opr-contract-form>
    </p-tabPanel>
    <p-tabPanel header="Operr Tel" [headerStyle]="{'outline': 0}">
      <opr-tel-contract-form (updateFormSuccess)="onFormUpdateSuccess()" [formHeight]="550"></opr-tel-contract-form>
    </p-tabPanel>
    <p-tabPanel header="Operr Technologies" [headerStyle]="{'outline': 0}">
      <opr-technology-contract-form (updateFormSuccess)="onFormUpdateSuccess()" [formHeight]="550"></opr-technology-contract-form>
    </p-tabPanel>
  </p-tabView>
</p-dialog>
