import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NotificationService, NotificationType } from '../notification/notification.service';
import { TimeoutError } from 'rxjs';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {

  }

  handleError(error: Error | HttpErrorResponse) {
    const notificationService = this.injector.get(NotificationService);
    const router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
      let messageBody = '';
      switch (error.status) {
        case 0:
          messageBody = 'Server Connection Error';
          break;
        default:
          messageBody = (error.error && error.error.message) ? error.error.message : error.message;
      }

      notificationService.open({type: NotificationType.ERROR, body: messageBody});
      // if (error.status === 401) {
      //     router.navigateByUrl('/login');
      // }

    } else if (error instanceof TimeoutError) {
      notificationService.open({type: NotificationType.ERROR, body: 'Server Connection Timeout'});
    } else {
      console.log(error);
    }
  }
}

