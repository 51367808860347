import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClaimPageType } from '@app/_config/constant';
import { environment } from 'environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimService {
  private readonly URL = environment.api.server;

  pageType: ClaimPageType;

  constructor(private http: HttpClient) {}

  public exportNew837P(options: any): Observable<any> {
    const URL = `${this.URL}/mas/claims/export-new-837p`;
    options.startDate = moment.utc(options.startDate).local().format('MM/DD/YYYY 00:00:00');
    options.endDate = moment.utc(options.endDate).local().format('MM/DD/YYYY 23:59:59');
    let body = {
        timeRange: { startTime: options.startDate, endDate: options.endDate },
        medicaidProviderNum: options.medicaidProviderNum,
        invoiceNumber: options.invoiceNumber,
        hasFinishedFlag: options.hasFinishedFlag,
        finishedFlag: options.finishedFlag,
        searchText: options.searchText,
        loadingTimeout: options.loadingTimeout,
        noPA: options.noPA
    };
    return this.http.post(URL, body, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  invoiceGroupClaims(options?: {
    startDate: any;
    endDate: any;
    page: any;
    size: any;
    medicaidProviderNum: any;
    pageSort: any;
    invoiceNumber?: any;
    searchText?: any;
    legID?: any;
    hasFinishedFlag: boolean;
    finishedFlag: boolean;
    loadingTimeout?: any;
  }) {
    options.startDate = moment.utc(options.startDate).local().format('MM/DD/YYYY 00:00:00');
    options.endDate = moment.utc(options.endDate).local().format('MM/DD/YYYY 23:59:59');
    let body = {};
    if (options.size > 0) {
      body = {
        pageable: {
          pageNumber: options.page,
          pageSize: options.size,
          pageSort: options.pageSort
        },
        timeRange: { startTime: options.startDate, endDate: options.endDate },
        medicaidProviderNum: options.medicaidProviderNum,
        invoiceNumber: options.invoiceNumber,
        searchText: options.searchText,
        hasFinishedFlag: options.hasFinishedFlag,
        finishedFlag: options.finishedFlag,
        loadingTimeout: options.loadingTimeout,
        legID: options.legID
      };
    } else {
      body = {
        timeRange: { startTime: options.startDate, endDate: options.endDate },
        medicaidProviderNum: options.medicaidProviderNum,
        invoiceNumber: options.invoiceNumber,
        searchText: options.searchText,
        hasFinishedFlag: options.hasFinishedFlag,
        finishedFlag: options.finishedFlag,
        loadingTimeout: options.loadingTimeout,
        legID: options.legID
      };
    }
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000')
    };

    return this.http.post(
      `${this.URL}/mas/claims/search/groupByInvoice`,
      body, headerOption
    );
  }

  generate837edi(medicaidProviderNumber, invoiceNumbers) {
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '12000000')
    };
    return this.http.post(`${this.URL}/mas/claims/837edi/generate/${medicaidProviderNumber}`, invoiceNumbers, headerOption);
  }

  updateDriverVehicle(data) {
    data.startDate = moment.utc(data.startDate).local().format('MM/DD/YYYY');
    data.endDate = moment.utc(data.endDate).local().format('MM/DD/YYYY');
    // set max timout is 50s, because it dependence on web scraping not our system.
    const headerOption = {
      headers: new HttpHeaders().set('timeout', 'indefinite')
    };
    return this.http.put<any>(`${this.URL}/total-jobs/driver`, data, headerOption);
  }

  getPaRoster(data: {endDate: string, medicaidProviderNum: string, startDate: string}) {
    const headerOption = {
      headers: new HttpHeaders().set('timeout', `indefinite`)
    };

    return this.http.post<any>(`${this.URL}/total-jobs/roster`, data, headerOption);
  }

  updateBrokerInfo(data: {username: string, password: string, medicaidProviderNum: string, brokerType: string}) {
    return this.http.put<any>(`${this.URL}/bases/brokerInfo`, data);
  }

  getStatusUploading(id: any) {
    const header = new HttpHeaders().set('X-Spinner', 'true');

    return this.http.get(`${this.URL}/upload-progresses/${id}`, {
      headers: header
    });
  }

  getUploadResult(id: any) {
    return this.http.get(`${this.URL}/upload-progresses/result/${id}`);
  }

  deleteFailedRecords(id: any) {
    return this.http.get(`${this.URL}/upload-progresses/delete-failed/${id}`);
  }
}
