import { FIDELIS_URL } from '../constant';

export const FIDELIS_MENU = [
  {
    label: 'Claim',
    routerLink: FIDELIS_URL.CLAIM
  },
  {
    label: 'Payment Report',
    routerLink: FIDELIS_URL.REPORT
  }
];
