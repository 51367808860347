import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {Observable} from 'rxjs/index';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SentrySchedulerService {
  private readonly URL = environment.api.server;


  constructor(private http: HttpClient) {}

  getSchedule(medicaidProviderNum: string) {
    const url = `${this.URL}/sentry/claims/schedule-detail?medicaidProviderNum=${medicaidProviderNum}`;
    return this.http.get(url);
  }

  createSchedule(schedule: any) {
    return this.http.post(`${this.URL}/sentry/claims/schedule`, schedule);
  }

  findAllSchedule() {
    const url = `${this.URL}/sentry/claims/schedules-detail`;
    return this.http.get(url);
  }

  deleteSchedule(schedule: any) {
    const url = `${this.URL}/sentry/claims/schedule-detail`;
    return this.http.post(url, schedule);
  }
}
