import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@app/_core/components';

@Injectable()
export class ConfirmationDialogService {

    constructor(public dialog: MatDialog) {
    }

    public open(options?: { header?: string, message?: string, accept?: (...args: any[]) => void, reject?: (...args: any[]) => void, hasComment?: boolean, height?: string}) {
        const header = (options && options.header) ? options.header : 'Confirmation';
        const message = (options && options.message) ? options.message : '';
        const acceptFn = (options && options.accept) ? options.accept : null;
        const rejectFn = (options && options.reject) ? options.reject : null;
        const hasComment  = (options && options.hasComment) ? options.hasComment: false;
        const height = (options && options.height) ? options.height : '';

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {header: header, message: message, hasComment: hasComment},
            height: height
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && acceptFn) {
                acceptFn(result.comment ? result.comment : '');
            }
            if (!result && rejectFn) {
                rejectFn();
            }
        });
    }

}
