<div class="ui-g ui-fluid h-100">
  <div class="login-form-container">
    <div class="card m-3">
      <div class="ui-g">
        <div class="ui-g-12">
          <div class="text-center">
            <img src="https://www.operr.com/images/operr-logo-centered.png" style="width:140px;height: 140px;">
            <p class="operr-version">OPERR® Transportation Platform v3.0 </p>
            <hr>
            <form name="material_login_form" [formGroup]="loginForm" class="mx-3" (ngSubmit)="onSignUp()">
              <div class="ui-g mx-3 form-group">
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield md-inputfield-box">
                    <input pInputText formControlName="username" type="text"
                      class="ui-inputtext ui-corner-all ui-state-default ui-widget">
                    <label>Username/Email</label>
                  </span>
                </div>
                <div class="ui-g-12 ui-md-12">
                  <span class="md-inputfield md-inputfield-box">
                    <input pInputText type="password" formControlName="password"
                      class="ui-inputtext ui-corner-all ui-state-default ui-widget" [ngClass]="{'invalid-form': failedMessage}" >
                    <label>Password</label>
                  </span>
                  <div *ngIf="failedMessage">
                    <span>
                      <i class="fa ui-icon-info" style="color: red;"></i>
                    </span>
                    <span style="color: red;"> {{failedMessage}}</span>
                  </div>
                </div>
              </div>
              <br>
              <div class="ui-g">
                <div class="ui-g-12">
                  <div *ngIf="message" class="text-danger">
                    {{message}}
                  </div>
                </div>
              </div>
              <br>
              <div class="ui-g">
                <div class="ui-g-12 ui-md-4">
                    <div class="ui-g text-left">
                        <a routerLink="/forgot-password" class="ui-g-12 py-0">Forgot Password?</a>
                        <a routerLink="/forgot-username" class="ui-g-12 py-0">Forgot User Name?</a>
                    </div>
                </div>
                <div class="ui-g-12 ui-md-8">
                  <div class="ui-g">
                    <div class="ui-g-12 ui-md-6">
                      <button type="submit" pButton label="Sign In"></button>
                    </div>
                    <div class="ui-g-12 ui-md-6">
                      <button routerLink="/sign-up" type="button" pButton label="Sign Up"></button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- <p-footer>
                <div class="footer text-center p-2">
                  <a [routerLink]="['/sign-up']" translate style="margin-right: 20px;">sign_up </a>
                  <a [routerLink]="['/forgot-password']"> forgot_password</a>
                </div>
              </p-footer> -->
        </div>
      </div>
    </div>
  </div>
</div>
