import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'opr-forgot-username',
  templateUrl: './forgot-username.component.html',
  styleUrls: ['./forgot-username.component.scss']
})
export class ForgotUsernameComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
