<div class="row" style="height: 100%; width: 99%">
  <div class="col-lg-4">
  </div>
  <div class="col-lg-4" style="height: 100%; padding-top: 7.5%;">  
      <div class="card" style=" min-width: 350px; max-width: 475px; padding-top:20px">
          <div class="row">
              <div class="col-lg-12">
                  <div style="text-align: center">
                      <img src="https://www.operr.com/images/operr-logo-centered.png" style="width:140px;height: 140px;">
                      <p class="operr-version">OPERR® Transportation Platform v3.0 </p>
                  <hr>
                                    <form name="material_login_form">
                      <fieldset>
                          <div style="padding-left: 25px; padding-right:25px;">
                              <div class="row">
                                  <div style="width:100%; text-align: center; padding-bottom: 15px;"><h2>Forgotten Password</h2></div>
                                  <div class="col-lg-12">
                                      <div class="ui-float-label">
                                          <span class="ui-inputgroup-addon"></span>
                                          <input pInputText pInputText class="w-100" type="email" (onChange)="message=''"
                                          [(ngModel)]="email" name="email" [required]="true" (focus)="resetMessage()">
                                          <label>email address *</label>         
                                      </div>                    
                                  </div>
                                  <!-- <div class="col-lg-12">
                                    <div class="ui-float-label">
                                      <input type="password" pInputText class="w-100" [(ngModel)]="email" [required]="true">
                                      <label>New Password *</label>
                                    </div>
                                  </div> -->
                              </div>                              
                              
                              <br>
                              <div [class]="styleClass">{{ message }}</div>
                              <div class="row">
                                  <div class="col-md-6">
                                      <button pButton [disabled]="!email" type="submit" [label]="'Send Email'" (click)="submit()"></button>
                                  </div>
                                  <div class="col-md-6">
                                    <button pButton type="button" [label]="'Cancel'" class="nav-item nav-link-edit" [routerLink]="['/login']"></button>
                                  </div>
                              </div>               
                          </div>
                      </fieldset>
                  </form>
              </div>
              <hr>
              <p-footer>
                  <div class="footer text-center p-2">
                          <a [routerLink]="['/login']">Back to Main Login Window</a>
                  </div>
              </p-footer>
          </div>
      </div>
      <div class="col-lg-4">
      </div>
  </div>
</div>