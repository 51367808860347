import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService} from '../../../_auth/';
import { SelectItem } from 'primeng/api';

@Component({
  selector: 'opr-forgot-password',
  styleUrls: ['./forgot-password.component.scss'],
  templateUrl: './forgot-password.component.html',
})

export class ForgotPasswordComponent {
  email;
  styleClass: string;
  message: string;

  constructor(private authService: AuthService, private router: Router) {
  }

  resetMessage() {
    this.styleClass = '';
    this.message = '';
  }

  submit() {
    const HTTP_STATUS_ERROR = [200, 400];
    if (this.email) {
      this.authService.forgotPassword(this.email).subscribe((res) => {
          this.styleClass = 'alert alert-success';
          this.message = (res as any).message;
        }, (err) => {
          this.styleClass = 'alert alert-danger';
          if (HTTP_STATUS_ERROR.indexOf(err.status) > -1) {
            this.message = (err as any).error.message;
          } else {
            this.message = 'An internal ocur error';
          }
        });
    }
  }
}
