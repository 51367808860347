import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillerService, EligibilityService, ClaimService, HelpService, ReportService, InvoiceService, SignOffService } from './mas';
import { ClaimResolver } from './mas/claim.resolver';
import {WindowRefService} from '@app/_service/window-ref/window-ref.service';
import {FormValidatorService} from '@app/_service/form-validator/form-validator.service';
import {ContractService} from '@app/_service/contract/contract.service';
import {AesUtilService} from '@app/_service/util';
import {BillingLogService} from '@app/_service/mas/billing-log.service';
import {TrackingService} from '@app/_service/tracking/tracking.service';
import {MasScheduleService} from '@app/_service/mas/mas-schedule.service';
import {FileUploadService} from '@app/_service/file-upload/file-upload.service';
import { AppNoticationService } from './app-notification/app-notification.service';
import { SentrySchedulerService } from './sentry/sentry-scheduler.service';
import { DayCareService } from './day-care/day-care.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    BillerService,
    EligibilityService,
    ClaimService,
    ClaimResolver,
    HelpService,
    ReportService,
    InvoiceService,
    SignOffService,
    BillingLogService,
    MasScheduleService,
    WindowRefService,
    FormValidatorService,
    ContractService,
    AesUtilService,
    TrackingService,
    FileUploadService,
    AppNoticationService,
    SentrySchedulerService,
    DayCareService
  ]
})
export class ServiceModule { }
