import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './_auth';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  constructor(
    private authenService: AuthService,
    private translate: TranslateService,
    private cookieService: CookieService) {
    let currentLang = 'en';
    if (this.cookieService.get('_currentLang')) {
      currentLang = this.cookieService.get('_currentLang');
    }
    translate.setDefaultLang(currentLang);
    translate.use(currentLang);
    this.cookieService.set('_currentLang', currentLang);
    this.authenService.setInactiveCounter();
  }

  display = false;

  countdownStart = 15;
  private timerSub: Subscription;
  private userActivitySub: Subscription;
  private timer$ = timer(0, 1000);

  ngOnInit() {
    this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');

    this.userActivitySub = this.authenService.getUserActivitySubject().subscribe((isInactive: boolean) => {
      if (isInactive && this.authenService.getToken()) {
        this.displayCountDown();
      }
    });
  }

  ngOnDestroy(): void {
    this.userActivitySub.unsubscribe();
  }

  @HostListener('window:mousemove')
  @HostListener('window:keydown')
  @HostListener('window:mousewheel')
  @HostListener('window:mousedown')
  @HostListener('window:touchstart')
  @HostListener('window:touchmove')
  @HostListener('window:scroll')
  resetCounter() {
    this.authenService.resetInactiveCounter();
  }

  displayCountDown() {
    this.display = true;
  }

  accept() {
    console.log('Accept');
    this.display = false;
    this.stopCountdown();
  }

  forceLogout() {
    console.log('Reject');
    this.display = false;
    this.authenService.logout();
    this.stopCountdown();
  }

  onShow() {
    console.log('Dialog show');
    this.startCountdown();
  }

  startCountdown() {
    this.countdownStart = 15;
    this.timerSub = this.timer$.subscribe(t => {
      if (this.countdownStart === 0) {
        this.forceLogout();
      } else {
        console.log(this.countdownStart);
        this.countdownStart--;
      }
    });
  }

  stopCountdown() {
    this.timerSub.unsubscribe();
  }
}
