import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {ContractStatus} from '@app/_config/constant/contract-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  private static readonly URL = environment.api.server;

  constructor(private http: HttpClient) {
  }

  createContract(contract: any) {
    const URL = `${ContractService.URL}/contract`;
    return this.http.post(URL, contract);
  }

  updateContract(contract: any) {
    const URL = `${ContractService.URL}/contract`;
    return this.http.put(URL, contract);
  }

  upload(fileToUpload: File) {
    const url = `${ContractService.URL}/contract/upload`;
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload);
    return this.http.post(url, formData);
  }

  getContract(contractStatus: string, pageNumber: number, pageSize: number) {
    const url = `${ContractService.URL}/contract?contractStatus=${contractStatus}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get(url);
  }

  getContractDetail(medicaidProviderNum: string) {
    const url = `${ContractService.URL}/contract/contractDetail?medicaidProviderNum=${medicaidProviderNum}`;
    return this.http.get(url);
  }

  updateContractStatus(medicaidProviderNum: any, contractStatus: ContractStatus) {
    const url = `${ContractService.URL}/contract/${medicaidProviderNum}/contractStatus`;
    return this.http.put(url, contractStatus);
  }

  downloadContract(medicaidProviderNum: string) {
    const URL = `${ContractService.URL}/contract/${medicaidProviderNum}/export`;
    return this.http.get(URL, {responseType: 'blob'});
  }
}
