import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/_auth';
import { LoginType, AppUrl } from '@app/_config/constant';

@Component({
  selector: 'opr-page-404',
  templateUrl: './page-404.component.html',
  styleUrls: ['./page-404.component.scss']
})
export class Page404Component implements OnInit {

  public defaultLink: string;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    switch (this.authService.getLoginType()) {
      case LoginType.BILLER_ADMIN:
      case LoginType.BILLER_USER:
        this.defaultLink = AppUrl.BILLER;
        break;
      case LoginType.BASE_ADMIN:
      case LoginType.BASE_USER:
        this.defaultLink = AppUrl.ELIGIBILITY;
        break;
    }
  }

}
