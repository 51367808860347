import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService} from '../../../_auth/';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { matchOtherValidator } from '@app/_core/service/validation/match-other-validation';

@Component({
  selector: 'opr-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetCodeData: any;
  errorMessage;
  successMessage;
  pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#$%^&*()+=_-{}\[\]|:;'"?/<>,.]){8,}/;
  form = this.fb.group({
    password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.pattern)]],
    rePassword: ['', [Validators.required, matchOtherValidator('password')]]
  });

  constructor(private route: ActivatedRoute, private authService: AuthService, private fb: UntypedFormBuilder) {
    this.route.queryParams.subscribe((params) => {
      this.resetCodeData = JSON.parse(atob(params.code));
    });
  }

  ngOnInit() {
  }

  get password() {
    return this.form.get('password');
  }

  get rePassword() {
    return this.form.get('rePassword');
  }

  getErrorPassword() {
    const messErr = `<p>Passwords must:</p>
    <ul>
      <li>Be a minimum of eight (8) characters in length</li>
      <li>Contain at least one (1) characters from four (4) of the following categories:
        <ul>
          <li> Uppercase letter(A-Z)</li>
          <li> Lowercase letter (a-z)</li>
          <li> Digit (0-9)</li>
          <li> Special character(~\`!@#$%^&*()+=_-{}[]\|:'"?/<>,.)</li>)
        </ul>
      </li>
    </ul>`;
    return this.password.hasError('required') ? 'Require' :
    this.password.hasError('minlength') ? messErr :
    this.password.hasError('pattern') ? messErr
     : '';
  }

  getErrorRePassword() {
    return this.rePassword.hasError('required') ? 'Require' :
    this.rePassword.hasError('matchOther') ? 'Passwords not matching' : '' ;
  }

  resetMessage() {
    this.successMessage = null;
    this.errorMessage = null;
  }


  onSubmit() {
    const HTTP_STATUS_ERROR = [200, 400];
    if (this.resetCodeData && this.resetCodeData.resetCode) {
      this.authService.resetPassword(this.resetCodeData.resetCode, this.password.value)
        .subscribe((res) => {
          this.successMessage = res ? res.message : '';
        } , (err) => {
          if (HTTP_STATUS_ERROR.indexOf(err.status) > -1) {
            this.errorMessage = err.error.message;
          } else {
            this.errorMessage = 'An internal ocur error';
          }
        });
    }
  }
}