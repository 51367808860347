
export class Claim_Services {
  eligibility: boolean;
  eligibilityServiceRateUnder: number;
  eligibilityServiceRateOver: number;
  eligibilityServiceOver: number;
  eligibilityCycleStart: any;
  eligibilityCycleEnd: any;

  signOff: boolean;
  signOffServiceRate: number;
  signOffCycleStart: any;
  signOffCycleEnd: any;

  generalBilling: boolean;
  generalBillingServiceRateLeg: number;
  generalBillingServicePercent: number;
  generalBillingCycleStart: any;
  generalBillingCycleEnd: any;

  correction: boolean;
  correctionServiceRateLeg: number;
  correctionServicePercent: number;
  correctionCycleStart: any;
  correctionCycleEnd: any;

  lookBack: boolean;
  lookBackServiceRateLeg: number;
  lookBackServicePercent: number;
  lookBackCycleStart: any;
  lookBackCycleEnd: any;

  congestionReport: boolean;
  congestionReportServiceRateUnder: number;
  congestionReportServiceRateOver: number;
  congestionReportServiceOver: number;

  monthlyFee: boolean;
  monthlyAmount: number;
  monthlyFeeCycleStart: any;
  monthlyFeeCycleEnd: any;

  tlcReport: boolean;
  tlcReportServiceRateUnder: number;
  tlcReportServiceRateOver: number;
  tlcReportServiceOver: number;

  serviceFrequencys: any[];

  contractStart: any;
  contractEnd: any;
  contractFiles: any[];
}
