import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {SignaturePad} from 'angular2-signaturepad';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ContractService} from '@app/_service/contract/contract.service';
import {ContractType} from '@app/_config/constant/contract-type.enum';
import {NotificationService, NotificationType} from '@app/_core/service';
import * as _ from 'lodash';

import {Observable} from 'rxjs';
import {Subject} from 'rxjs';
import {ContractStatus} from '@app/_config/constant/contract-status.enum';
import {AuthService} from '@app/_auth';
import {saveAs as importedSaveAs} from 'file-saver';

@Component({
  selector: 'opr-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss']
})
export class ContractFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('licenseeSignaturePad') licenseeSignaturePad: SignaturePad;
  @ViewChild('licensorSignaturePad') licensorSignaturePad: SignaturePad;
  @Output() updateFormSuccess = new EventEmitter();
  @Input() data;
  @Input() formHeight;
  formGroup: UntypedFormGroup;
  signaturePadOptions = {
    minWidth: 2,
    canvasWidth: 400,
    canvasHeight: 200,
  };
  ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  showSignature = false;
  signatureUrl;
  enableSaveBtn = true;

  egibilities = [{}];
  signOffs = [{}];
  generalBillingProcessLegs = [{}];
  generalBillingPaidAmounts = [{}];
  correctionProcessLegs = [{}];
  correctionPaidAmounts = [{}];
  lookbackProcessLegs = [{}];
  lookbackPaidAmounts = [{}];
  congestionReports = [{}];
  tlcReports = [{}];


  useEligibility = false;
  useSignOff = false;
  useGeneralBilling = false;
  useGeneralBillingProcessLegs = false;
  useGeneralBillingPaidAmount = false;
  useCorrection = false;
  useCorrectionProcessLegs = false;
  useCorrectionPaidAmount = false;

  useLookback = false;
  useLookbackProcessLegs = false;
  useLookbackPaidAmount = false;
  useCongestionReport = false;
  useTLCReport = false;
  interval;

  isValidTermData = false;

  contractStatus: ContractStatus = ContractStatus.NEW;
  loginType;

  statuses = ContractStatus;
  constructor(private fb: UntypedFormBuilder, private contractService: ContractService, private notificationService: NotificationService, private authService: AuthService) {
    this.loginType = this.authService.getLoginType();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data'] && changes['data'].previousValue !== changes['data'].currentValue) {
      this.formGroup.patchValue({
        effectiveDate: this.data.effectiveDate? new Date(this.data.effectiveDate): null,
        licensee: this.data.licensee,
        licenseeCorporation: this.data.licenseeCorporation,
        licenseePlaceOfBusiness: this.data.licenseePlaceOfBusiness,
        licenseeMedicaidId: this.data.licenseeMedicaidId,
        licenseeAuthorizedOfficer: this.data.licenseeAuthorizedOfficer,
        licensorAuthorizedOfficer: this.data.licensorAuthorizedOfficer,
        licenseeName: this.data.licenseeName,
        licenseeAddress: this.data.licenseeAddress,
        licenseeEmail: this.data.licenseeEmail,
        licensorEmail: this.data.licensorEmail,
        licenseePhone: this.data.licenseePhone,
        licensorPhone: this.data.licensorPhone,
        licenseeDirectPhone: this.data.licenseeDirectPhone,
        licensorDirectPhone: this.data.licensorDirectPhone,
        licenseeDate: this.data.licenseeDate? new Date(this.data.licenseeDate): null ,
        licensorDate: this.data.licensorDate ? new Date(this.data.licensorDate): null,
        termStartDate:  this.data.termStartDate? new Date(this.data.termStartDate):null,
        termPeriod: this.data.termPeriod,
        termEndDate: this.data.termEndDate? new Date(this.data.termEndDate):null,
        renewalTime: this.data.renewalTime,
        renewalNoticeTime: this.data.renewalNoticeTime,
        terminationNoticeTime: this.data.terminationNoticeTime,
        paymentWithin: this.data.paymentWithin,
        latePaymentPenalty: this.data.latePaymentPenalty,
        city: this.data.city,
        state: this.data.state,
        zipcode: this.data.zipcode,
      });

      this.egibilities = this.updateDateInfo(this.data.egibilities);
      this.signOffs = this.updateDateInfo( this.data.signOffs);
      this.generalBillingProcessLegs =  this.updateDateInfo(this.data.generalBillingProcessLegs);
      this.generalBillingPaidAmounts =  this.updateDateInfo(this.data.generalBillingPaidAmounts);
      this.correctionProcessLegs =  this.updateDateInfo(this.data.correctionProcessLegs);
      this.correctionPaidAmounts =  this.updateDateInfo(this.data.correctionPaidAmounts);
      this.lookbackProcessLegs =  this.updateDateInfo(this.data.lookbackProcessLegs);
      this.lookbackPaidAmounts =  this.updateDateInfo(this.data.lookbackPaidAmounts);
      this.congestionReports =  this.updateDateInfo(this.data.congestionReports);
      this.tlcReports =  this.updateDateInfo(this.data.tlcReports);


      this.useEligibility = this.data.useEligibility;
      this.useSignOff = this.data.useSignOff;
      this.useGeneralBilling = this.data.useGeneralBilling;
      this.useGeneralBillingProcessLegs = this.data.useGeneralBillingProcessLegs;
      this.useGeneralBillingPaidAmount = this.data.useGeneralBillingPaidAmount;
      this.useCorrection = this.data.useCorrection;
      this.useCorrectionProcessLegs = this.data.useCorrectionProcessLegs;
      this.useCorrectionPaidAmount = this.data.useCorrectionPaidAmount;

      this.useLookback = this.data.useLookback;
      this.useLookbackProcessLegs = this.data.useLookbackProcessLegs;
      this.useLookbackPaidAmount = this.data.useLookbackPaidAmount;
      this.useCongestionReport = this.data.useCongestionReport;
      this.data.useTLCReport = this.data.useTLCReport;

      if (this.data.licenseeSignatureUrl) {
        this.showSignature = true;
        this.signatureUrl = this.data.licenseeSignatureUrl;
      }

        this.contractStatus = this.data.contractStatus;
      // this.enableSaveBtn = this.data.contractStatus ==='NEW' || (this.data.contractStatus ==='ALLOW_UPDATE');
    }
  }

  updateDateInfo(items){
    _.forEach(items, item => {
      if (item.from){
        item.from = new Date(item.from);
      }
      if (item.to){
        item.to = new Date(item.from);
      }
    })
    return items;
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  clearSignature() {
    this.licenseeSignaturePad.clear();
  }

  allowEdit(){
    this.contractService.updateContractStatus(this.data.licenseeMedicaidId, ContractStatus.ALLOW_UPDATE).subscribe(res =>{
      this.notificationService.open({type: NotificationType.SUCCESS, body: 'Successfully allow base admin to edit the contract'});

    });
  }

  save() {
    if (!this.checkValidTermData()) {
      this.notificationService.open({type: NotificationType.ERROR, body: 'Term data is missing'});

      return;
    }

    if (this.contractStatus && this.contractStatus.toString() === 'ALLOW_UPDATE'){
      this.updateContract();
    } else {
      const file: any = this.licenseeSignaturePad.toDataURL();

      if (!file) {
        return;
      }
      const blob: any = this.dataURItoBlob(file);
      this.contractService.upload(blob).subscribe((res: any) => {
        console.log('File Uploaded Url: ' + res.uploadedFileUrl);
        console.log('Calling create contract API...');
        const body = this.buildRequestBody();
        body.licenseeSignatureUrl = res.uploadedFileUrl;
        this.signatureUrl = res.uploadedFileUrl;
        this.contractService.createContract(body).subscribe(res => {
          console.log('Successfully created contract');
          this.notificationService.open({type: NotificationType.SUCCESS, body: 'Successfully created the contract'});
          this.enableSaveBtn = false;
          this.downloadContractInPdf();
        });
      });
    }
  }

  updateContract(){
    const body = this.buildRequestBody();
    this.contractService.updateContract(body).subscribe(res => {
      console.log('Successfully updated contract');
      this.notificationService.open({type: NotificationType.SUCCESS, body: 'Successfully updated the contract'});
      this.enableSaveBtn = false;
      this.downloadContractInPdf();
    });
  }

  buildRequestBody(){
    const body = this.formGroup.value;
    body.contractType = ContractType.SERVICE_BUREAU;
    body.licenseeSignatureUrl = this.signatureUrl;
    body.egibilities = this.egibilities;
    body.signOffs = this.signOffs;
    body.generalBillingProcessLegs = this.generalBillingProcessLegs;
    body.generalBillingPaidAmounts = this.generalBillingPaidAmounts;
    body.correctionProcessLegs = this.correctionProcessLegs;
    body.correctionPaidAmounts = this.correctionPaidAmounts;
    body.lookbackProcessLegs = this.lookbackProcessLegs;
    body.lookbackPaidAmounts = this.lookbackPaidAmounts;
    body.congestionReports = this.congestionReports;
    body.tlcReports = this.tlcReports;


    body.useEligibility = this.useEligibility;
    body.useSignOff = this.useSignOff;
    body.useGeneralBilling = this.useGeneralBilling;
    body.useGeneralBillingProcessLegs = this.useGeneralBillingProcessLegs;
    body.useGeneralBillingPaidAmount = this.useGeneralBillingPaidAmount;
    body.useCorrection = this.useCorrection;
    body.useCorrectionProcessLegs = this.useCorrectionProcessLegs;
    body.useCorrectionPaidAmount = this.useCorrectionPaidAmount;

    body.useLookback = this.useLookback;
    body.useLookbackProcessLegs = this.useLookbackProcessLegs;
    body.useLookbackPaidAmount = this.useLookbackPaidAmount;
    body.useCongestionReport = this.useCongestionReport;
    body.useTLCReport = this.useTLCReport;

    return body;
  }


  checkValidTermData() {
    return this.isValidTermDetails(this.egibilities) || this.isValidTermDetails(this.signOffs)
      || this.isValidTermDetails(this.generalBillingProcessLegs) || this.isValidTermDetails(this.generalBillingPaidAmounts, true)
      || this.isValidTermDetails(this.correctionProcessLegs) || this.isValidTermDetails(this.correctionPaidAmounts, true)
      || this.isValidTermDetails(this.lookbackProcessLegs) || this.isValidTermDetails(this.lookbackPaidAmounts, true)
      || this.isValidTermDetails(this.congestionReports) || this.isValidTermDetails(this.tlcReports);
  }

  isValidTermDetails(termDetails, isPaidAmountTerm?) {
    const terms = _.filter(termDetails, termDetail => termDetail.numberOfTrip);
    if (!terms || terms.length === 0) {
      return false;
    }
    for (let i = 0; i < terms.length; i++) {
      const term = terms[i] as any;
      if (isPaidAmountTerm) {
        if (!term.numberOfTrip || !term.rangeChargeAmount || !term.from || !term.to) {
          return false;
        }
      } else {
        if (!term.numberOfTrip || !term.chargeAmount || !term.rangeChargeAmount || !term.from || !term.to) {
          return false;
        }
      }
    }
    return true;
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.data){
      this.contractStatus = this.data.contractStatus;
    }

    this.interval = setInterval(() => {
      this.isValidTermData = this.checkValidTermData();
    }, 1000);
  }

  buildForm() {
    this.formGroup = this.fb.group({
      effectiveDate: [null, Validators.required],
      licensee: [null, Validators.required],
      licenseeCorporation: [null, Validators.required],
      licenseePlaceOfBusiness: [null, Validators.required],
      licenseeMedicaidId: [null, Validators.required],

      licenseeName: [null, Validators.required],
      licenseeAddress: [null, Validators.required],
      licenseeEmail: [null, Validators.required],
      licensorEmail: [null],
      licenseePhone: [null, Validators.required],
      licensorPhone: [null],
      licenseeAuthorizedOfficer: [null, Validators.required],
      licensorAuthorizedOfficer: [null],
      licenseeDate: [new Date(), Validators.required],
      licensorDate: [new Date()],
      licenseeDirectPhone: [null, Validators.required],
      licensorDirectPhone: [null],
      termStartDate: [null, Validators.required],
      termPeriod: [null, Validators.required],
      termEndDate: [null, Validators.required],
      renewalTime: [null, Validators.required],
      renewalNoticeTime: [null, Validators.required],
      terminationNoticeTime: [null, Validators.required],
      paymentWithin: [null, Validators.required],
      latePaymentPenalty: [null, Validators.required],

      city: [null, Validators.required],
      state: [null, Validators.required],
      zipcode: [null, Validators.required],

    });
  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  addEgibility() {
    this.egibilities.push({});
  }

  addSignOff() {
    this.signOffs.push({});
  }

  addGeneralBillingProcessLeg() {
    this.generalBillingProcessLegs.push({});
  }

  addGeneralBillingPaidAmount() {
    this.generalBillingPaidAmounts.push({});
  }

  addCorrectionProcessLeg() {
    this.correctionProcessLegs.push({});
  }

  addCorrectionPaidAmount() {
    this.correctionPaidAmounts.push({});
  }

  addLookbackProcessLeg() {
    this.lookbackProcessLegs.push({});
  }

  addLookbackPaidAmount() {
    this.lookbackPaidAmounts.push({});
  }

  addCongestionReport() {
    this.congestionReports.push({});
  }

  addTLCReport() {
    this.tlcReports.push({});
  }


  removeEgibility(index) {
    this.egibilities.splice(index, 1);
  }

  removeSignOff(index) {
    this.signOffs.splice(index, 1);
  }

  removeGeneralBillingProcessLeg(index) {
    this.generalBillingProcessLegs.splice(index, 1);
  }

  removeGeneralBillingPaidAmount(index) {
    this.generalBillingPaidAmounts.splice(index, 1);
  }

  removeCorrectionProcessLeg(index) {
    this.correctionProcessLegs.splice(index, 1);
  }

  removeCorrectionPaidAmount(index) {
    this.correctionPaidAmounts.splice(index, 1);
  }

  removeLookbackProcessLeg(index) {
    this.lookbackProcessLegs.splice(index, 1);
  }

  removeLookbackPaidAmount(index) {
    this.lookbackPaidAmounts.splice(index, 1);
  }

  removeCongestionReport(index) {
    this.congestionReports.splice(index, 1);
  }

  removeTLCReport(index) {
    this.tlcReports.splice(index, 1);
  }

  downloadContractInPdf() {
    this.contractService.downloadContract(this.data.licenseeMedicaidId).subscribe(data => {
      var blob = new Blob([data], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var win = window.open(url, '_blank');
      win.focus();
    });
  }

  unescape(str: string): string {
    return str.replace(/%(?![\da-f]{2})/gi, function () {
      // If the match is not a valid percent-encoded string, return the original match
      return '%25';
    }).replace(/%([\da-f]{2})/gi, function (match, code) {
      // Convert the percent-encoded string to its corresponding character
      return String.fromCharCode(parseInt(code, 16));
    });
  }
}
