<div class="signature-container" style="overflow-y: auto;-webkit-overflow-scrolling: auto;" [style.height.px]="formHeight">
  <form [formGroup]="formGroup">
      <div class="pull-right" style="margin-right: 50px">
        <button pButton type="submit" class="ui-button-success" (click)="downloadContractInPdf()" label="Export"></button>
      </div>
    <div class="ui-g">
      <div class="ui-g-12 ui-md-12">
        <p align="center">
          <strong>SOFTWARE LICENSE AGREEMENT</strong>
        </p>
        <p>
          This Software License Agreement (“Agreement” or “License”) is entered into
          as of  <p-calendar formControlName="effectiveDate" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          (“Effective Date”) by and between Operr
          Technologies, Inc. and/or Operr Service Bureau, Inc. are hereinafter
          referred to as “Operr” or Operr Technologies, Inc. and its affiliates
          (“Licensor”) and <input pInputText formControlName="licensee" type="text" label="______________________________________________" style="text-align: center; width: 300px">
          (“Licensee”)
        </p>
        <p>
          Licensor is a New York Corporation with its principal place of business at
          130-30 31st Ave., Ste. 801, Flushing, NY 11354. Operr Service Bureau, Inc.
          is licensed by the New York State Department of Health to provide billing
          services for third parties.
        </p>
        <p>
          Licensee is a <input pInputText formControlName="licenseeCorporation" type="text" label="__________________" style="text-align: center; width: 300px"> Corporation, with its principal place of
          business at
          <input pInputText formControlName="licenseePlaceOfBusiness" type="text" label="______________________________________________________________________________" style="text-align: center; width: 500px">
        </p>
        <p>
          WHEREAS Licensee is desirous of using Licensor’s Software, and Licensee
          hereby agrees that all use of the Software is subject to the following
          terms stipulated in the documentation and this Agreement.
        </p>
        <p>
          NOW THEREFORE, Licensee and Licensor agree to the following:
        </p>
        <p>
          <a name="_Hlk9592220"></a>
          <a name="_Hlk9592957">
            <strong>1. </strong>
            <strong>Scope, Ownership, Limited License, and Restrictions</strong>
          </a>
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          1.1 <u>Scope</u>
        </p>
        <p>
          Licensor’s Software allows Licensee to prepare and submit Medicaid
          reimbursement claims to governmental agencies. The Licensee expressly
          agrees that it will use the Software only for the preparation and
          submission of its own Medicaid reimbursement claims under Licensee’s New
          York State Medicaid Identification Number: <input pInputText formControlName="licenseeMedicaidId" type="text" label="_____________________" style="text-align: center">.
        </p>
        <p>
          1.2 <u>Ownership</u>
        </p>
        <p>
          Licensee agrees that Licensor owns and will retain all title, interest,
          proprietary and business methods and rights, including patent, copyright,
          trade secret, trademark, and other Intellectual Proprietary rights, in the
          original and all copies of the Software and any improvements, whether
          invented by Licensor, Licensee, Licensee’s employees, contractors, or
          agents.
        </p>
        <p>
          Licensee does not acquire any rights, express or implied, in the Software
          or any improvements or to any component(s) thereof, other than those
          specified in this License. Licensor may use, sell, assign, transfer, and
          license copies of and rights relating to the Software to third parties free
          from any claim of the Licensee.
        </p>
        <p>
          1.3 <u>Limited License and Restrictions</u>
        </p>
        <p>
          Licensee acknowledges that the license granted hereunder is limited and
          will be used on a designated system. Licensee will not assign, sell,
          license, re-license, sublicense, rent, lease, publish, display, distribute,
          permit unauthorized use, or otherwise transfer this License, Software,
          documentation or any Improvements to any person or entity for any purpose
          or permit to use the Software by third-party, training, commercial
          time-sharing, service bureau use, or for any use not explicitly authorized
          by this License.
        </p>
        <p>
          <strong>2. </strong>
          <strong>Warranties and Disclaimers and Restrictions</strong>
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          2.1 <u>Warranty</u><strong></strong>
        </p>
        <p>
          Licensor warrants that the License granted hereunder will operate by the
          Documentation provided along with the License (without any modification).
        </p>
        <p>
          2.2 <u>Disclaimers</u><strong></strong>
        </p>
        <p>
          THE WARRANTIES OUTLINED IN THIS SECTION ARE EXCLUSIVE AND INSTEAD OF ALL
          OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
          IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
          Licensor does not warrant that the Software will operate in combinations or
          that the operation of the Software will be uninterrupted or error-free.
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          2.3 <u>Restrictions<strong></strong></u>
        </p>
        <p>
          The Licensee will not assign the License or this Agreement or give the
          Software either in part or as a whole to another individual or entity. If
          Licensee grants a security interest in the Software or service to any third
          party, without prior written approval from Licensor, the third party will
          not have any right or interest in the License granted under this Agreement
          and the Licensee will be liable for damages. The rights and remedies
          available to the Licensee under this Agreement are non-transferable and
          cannot be availed by any third party, which is not a party to this
          Agreement.
        </p>
        <p>
          Licensee is prohibited from copying, modifying, altering, adapt, translate,
          reverse engineer, disassemble, or create derivative works based upon the
          Software, in whole or in part.
        </p>
        <p>
          <strong>3. </strong>
          <strong>Term, Termination, Renewal.</strong>
        </p>
        <p>
          3.1 <u>Term</u><strong></strong>
        </p>
        <p>
          This License will start upon the date the last party executes <p-calendar formControlName="termStartDate" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> this License and will continue for <input pInputText class="align-center" formControlName="termPeriod" type="number" label="_______" style="width: 50px">
          year after the Start Date
          <p-calendar formControlName="termEndDate" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
        </p>
        <p>
          3.2 <u>Renewal<strong></strong></u>
        </p>
        <p>
          This Agreement will be renewed automatically for additional periods of <input pInputText class="align-center" formControlName="renewalTime" type="number" label="_______" style="width: 50px"> year unless the Licensee or Licensor gives notice to the other party at
          least <input pInputText class="align-center" formControlName="renewalNoticeTime" type="number" label="_______" style="width: 50px"> days before the end of the End Date.
        </p>
        <p>
          3.3 <u>Termination<strong></strong></u>
        </p>
        <p>
          Licensee must provide written notice by certified mail to Licensor <input pInputText class="align-center" formControlName="terminationNoticeTime" type="number" label="_______" style="width: 50px"> days before the termination of the Agreement.
        </p>
        <p>
          In case of one party’s uncured material breach of this Agreement, the
          offended party will have the right to terminate this Agreement and to seek
          the remedies provided for herein, by giving the offending party 30 days’
          notice of its intention to terminate.
        </p>
        <p>
          Upon termination of this Agreement, Licensee will immediately stop using
          Licensor’s Software. Licensee’s obligation under this Agreement until the
          Licensee returned, surrendered, and/or destroying the Software and all
          related documents.
        </p>
        <p>
          <strong>4. </strong>
          <strong>Fees and Payment Structure: </strong>
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          Fees And Payment Structure Depending On The Type Of Service - Exhibit A.
        </p>
        <p>
          <strong></strong>
        </p>
        <p>
          4.1 Invoices will be issued according to the terms outlined in this
          Agreement. All invoices are payable by check or bank transfer; the fee
          excludes any taxes, shipping and/or insurance charges, and any bank
          transfer fees.
        </p>
        <p>
          4.2 Monthly bundled recurring service charges will become due on the 1    <sup>st</sup> day of each month, or at the time Licensee receives its
          billed payments, whichever occurs earlier.
        </p>
        <p>
          4.3 The full invoice amount must be settled within <input pInputText formControlName="paymentWithin" class="align-center" type="number" label="_______" style="width: 50px"> days of
          issuance of the invoice (to be issued at 11:00 am EST on the first day of
          each month).
        </p>
        <p>
          4.3.1 If payment is not received by Licensor accordingly within the  five
          (5) days set forth above, Client will be assessed a $  <input pInputText formControlName="latePaymentPenalty" class="align-center" type="number" label="_______" style="width: 50px"> (the “Late
          Payment Penalty”).
        </p>
        <p>
          4.3.2 If Licensor receives no payment within five (5) days after the Late
          Payment Penalty is assessed, Licensor reserves the right to cancel this
          License and/or Agreement immediately and take necessary legal action
          depending on the situation and according to any applicable law. Licensee
          agrees to pay attorney fees, collection fees, court costs, and any other
          expenses incurred in the collection of any charges under this License
          and/or Agreement or otherwise in enforcing the terms of this License and/or
          Agreement.
        </p>
        <p>
          <strong>5. </strong>
          <strong>No Assignment</strong>
        </p>
        <p>
          Licensee will not assign this Agreement or any of the rights granted by
          Licensor hereunder, in whole or in part, without the prior written consent
          of Licensor, and any attempt to do so will be void. This Agreement is
          binding on and will inure to the benefit of the parties and their
          respective successors and permitted assigns.
        </p>
        <p>
          <strong>6. </strong>
          <strong>Confidentiality</strong>
        </p>
        <p>
          Licensee agrees that Licensee will not disclose to any third party the
          Software or any portion thereof, any technical, product, or business
          information, or any information that Vendor identifies as confidential
          (collectively, “Confidential Information”) related to the Software without
          the prior written consent of Vendor. Licensee will maintain the
          confidentiality of all Confidential Information and will not use it for any
          purpose other than the performance of this Agreement.
        </p>
        <p>
          Licensee agrees to protect all personally identifiable or aggregate patient
          or other medical information defined as Protected Health Information
          ("PHI") in the Health Insurance Portability and Accountability Act of 1996
          or its relevant regulations ("HIPAA").
        </p>
        <p>
          <strong>7. </strong>
          <strong>Limitation of Liability</strong>
        </p>
        <p>
          Unless otherwise expressly stated in this License, and whether or not
          Licensor has been advised of the possibility of such loss, Licensor will
          not be liable in contract, tort (including but not limited to warranty,
          negligence or strict liability) or otherwise for (a) indirect, special,
          incidental, exemplary or consequential loss or damages; (b) loss of
          revenue, profits, business or goodwill; (c) loss of availability; or (d)
          damage to, loss of or corruption of data, however, caused, which arises out
          of or in connection with this License, the Software (including but not
          limited to Use or inability to use the Software), Services, any Purchase
          Order or any Maintenance and Support. The maximum liability of Licensor
          will be equivalent to License Fee paid by the Licensee and in no event will
          the liability of Licensor exceed the amount of License Fee paid to the
          Licensor.
        </p>
        <p>
          Licensee will take responsibility to provide true and accurate data for
          billing services. Licensor will not take responsibility for verifying
          whether billing data is true or not, which is the sole responsibility of
          the Licensee. The Licensee acknowledges that it constitutes fraud to
          provide false billing data and assumes legal responsibility for any false
          data provided to Licensor.
        </p>
        <p>
          <strong>8. </strong>
          <strong>Severability</strong>
        </p>
        <p>
          If anyone or more of the provisions contained in this Agreement will, for
          any reason, be held to be invalid, illegal, or unenforceable in any
          respect, then to the maximum extent permitted by law, such invalidity,
          illegality, or unenforceability will not affect any other provision of this
          Agreement.
        </p>
        <p>
          <strong>9. </strong>
          <strong>Disputes</strong>
        </p>
        <p>
          Any controversy or claim including, but not limited to, loss or damage
          arising out of or relating to this Agreement, or the breach thereof, will
          be settled by arbitration administered by the American Arbitration
          Association within the City of New York. The parties may agree in writing
          to waive this provision in favor of litigation in New York State courts,
          County of Queens. Such waiver must be mutually approved in writing.
        </p>
        <p>
          <strong>10. </strong>
          <strong>Attorney’s Fees</strong>
        </p>
        <p>
          In the event of legal action brought by either party, the prevailing party
          will be entitled to reimbursement of actual legal fees and related expenses
        </p>
        <p>
          <strong>11. </strong>
          <strong>Agreement</strong>
        </p>
        <p>
          The terms outlined in this Agreement constitute the entire Agreement. This
          Agreement supersedes all existing agreements between Licensor and Licensee.
        </p>
        <p>
          <strong>12. </strong>
          <strong>Modification</strong>
        </p>
        <p>
          This Agreement will be modified or amended at the sole discretion of
          Licensor.
        </p>
        <p>
          <strong>13. </strong>
          <strong>Effectiveness</strong>
        </p>
        <p>
          Upon Licensee and Licensor’s signatures, the above Agreement is effective
          immediately.
        </p>
        <table border="0" cellspacing="0" cellpadding="0">
          <tbody>
          <tr>
            <td width="300" valign="top">
              <p align="center">
                <strong>LICENSEE</strong>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p align="center">
                <strong>LICENSOR</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <input pInputText formControlName="licenseeName" type="text" label="_______________________________" style="width: 400px">

            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <input pInputText type="text" value="Operr Technologies, Inc. and its affiliates" style="width: 400px" disabled>

              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Name/Corporation</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Name/Corporation</em>
              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <input pInputText formControlName="licenseeAddress" type="text" label="_______________________________" style="width: 400px">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <input pInputText type="text" value=" 130-30 31st Avenue, Ste. 801" style="width: 400px" disabled>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Address</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Address</em>
              </p>
            </td>
          </tr>

          <tr>
            <td width="300" valign="top">
              <input pInputText formControlName="city" type="text" label="_______________________________" style="width: 400px">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <input pInputText type="text" value=" Flushing" style="width: 400px" disabled>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>City</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>City</em>
              </p>
            </td>
          </tr>

          <tr>
            <td width="300" valign="top">
              <input pInputText formControlName="state" type="text" label="_______________________________" style="width: 400px">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <input pInputText type="text" value=" NY" style="width: 400px" disabled>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>State</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>State</em>
              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <input pInputText formControlName="zipcode" type="text" label="_______________________________" style="width: 400px">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <input pInputText type="text" value=" 11354" style="width: 400px" disabled>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Zip</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Zip</em>
              </p>
            </td>
          </tr>

          <tr>
            <td width="300" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <input pInputText formControlName="licenseePhone" type="text" label="_______________________________" style="width: 400px" >
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <input pInputText type="text"  value="(800) 668-6906" style="width: 400px" disabled>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Phone</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Phone</em>
              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <input pInputText type="text" formControlName="licenseeAuthorizedOfficer" label="_______________________________" style="width: 400px">

            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <input pInputText type="text" formControlName="licensorAuthorizedOfficer" label="_______________________________" style="width: 400px" disabled>

            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Authorized Officer (Print Name):</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Authorized Officer (Print Name):</em>
              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <signature-pad #licenseeSignaturePad [options]="signaturePadOptions" *ngIf="!showSignature"></signature-pad>
              <img [src]="signatureUrl" *ngIf="showSignature" width="400px" height="200px">
              <input pInputText type="text" label="_______________________________" style="width: 400px">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <signature-pad #licensorSignaturePad [options]="signaturePadOptions"></signature-pad>

              <input pInputText type="text"  label="_______________________________" style="width: 400px" disabled>

            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Signature:</em> <button class="margin-20" pButton type="button" label="Clear" (click)="clearSignature()"></button>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Signature:</em>

              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <input pInputText type="email" formControlName="licenseeEmail" label="_______________________________" style="width: 400px">

            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <input pInputText type="email" formControlName="licensorEmail" label="_______________________________" style="width: 400px" disabled>

            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Email:</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Email:</em>
              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <input pInputText type="text" formControlName="licenseeDirectPhone" label="_______________________________" style="width: 400px">

            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <input pInputText type="text" formControlName="licensorDirectPhone" label="_______________________________" style="width: 400px" disabled>

            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Direct</em>
                Phone #:
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Direct Phone #:</em>
              </p>
            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <!--<input pInputText type="date" formControlName="licenseeDate" label="_______________________________" style="width: 400px">-->
              <p-calendar formControlName="licenseeDate" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'440px'}" [inputStyle]="{'width':'400px'}" [showTime]="true"></p-calendar>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <!--<input pInputText type="date" formControlName="licensorDate" label="_______________________________" style="width: 400px">-->
              <p-calendar formControlName="licensorDate" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'440px'}" [inputStyle]="{'width':'400px'}" [showTime]="true"></p-calendar>

            </td>
          </tr>
          <tr>
            <td width="300" valign="top">
              <p>
                <em>Date:</em>
              </p>
            </td>
            <td width="18" valign="top">
            </td>
            <td width="18" valign="top">
            </td>
            <td width="288" valign="top">
              <p>
                <em>Date:</em>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
        <br clear="all"/>
        <p align="center">
          <strong>
            Exhibit A - Fees And Payment Structure Depending On The Type Of Service
          </strong>
        </p>
        <p>
          <strong>Definitions: </strong>
        </p>
        <p>
          <em>“leg” </em>
          means the operation of a vehicle from one departure point to the next
          scheduled arrival point.
        </p>
        <p>
          <em>“trip” </em>
          means a combination of multiple legs, for example, a trip can consist of
          two legs, A to B and B to A. Furthermore, a trip can consist of two or more
          legs, A to C, C to B, B to A.
        </p>
        <p>
          <em>“paid amount”</em>
          means the amount that Licensee receives from the governmental agency or
          insurance company.
        </p>
        <p>
          “<em>Service Fee”</em> means the fee payable to Licensor by Licensee as set
          out in the Fees And Payment Structure.
        </p>

      </div>
    </div>
  </form>
  <div>
        <br>
        <p>
          <p-checkbox binary="true" [(ngModel)]="useEligibility"></p-checkbox> <strong>Eligibility:</strong>
        </p>
        <p *ngFor="let eligibily of egibilities; let i=index">
          If every day the number of trips under <input pInputText type="number" [(ngModel)]="eligibily.numberOfTrip" label="___" style="width: 50px;text-align: center"  [disabled]="!useEligibility"> trips, Licensor will charge <input pInputText type="number" [(ngModel)]="eligibily.chargeAmount" label="___" style="width: 50px;text-align: center"  [disabled]="!useEligibility">.
          If more than <input pInputText type="number" [(ngModel)]="eligibily.numberOfTrip" label="___" style="width: 50px;text-align: center"  [disabled]="!useEligibility"> trips are checked on eligibility, Licensor will charge <input pInputText type="number" [(ngModel)]="eligibily.rangeChargeAmount" label="___" style="width: 50px;text-align: center"  [disabled]="!useEligibility">, from <p-calendar [(ngModel)]="eligibily.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useEligibility" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="eligibily.from" [(ngModel)]="eligibily.to" [disabled]="!useEligibility" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="egibilities.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeEgibility(i)" [disabled]="!useEligibility"></button>
          <button *ngIf="i==egibilities.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addEgibility()" [disabled]="!useEligibility"></button>
        </p>


        <br>
        <p>
          <p-checkbox binary="true" [(ngModel)]="useSignOff"></p-checkbox><strong>Sign Off: </strong>
        </p>
        <p *ngFor="let signOff of signOffs; let i=index">
          If every day the number of legs under <input pInputText type="number" [(ngModel)]="signOff.numberOfTrip" label="___" style="width: 50px;text-align: center"  [disabled]="!useSignOff"> legs, Licensor will charge <input pInputText type="number" [(ngModel)]="signOff.chargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useSignOff">.
          If more than <input pInputText type="number" [(ngModel)]="signOff.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useSignOff"> legs are checked on sign off, Licensor will charge <input pInputText type="number" [(ngModel)]="signOff.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useSignOff">, from <p-calendar [(ngModel)]="signOff.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useSignOff" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="signOff.from" [(ngModel)]="signOff.to" [disabled]="!useSignOff" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="signOffs.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeSignOff(i)" [disabled]="!useSignOff"></button>
          <button *ngIf="i==signOffs.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addSignOff()"  [disabled]="!useSignOff"></button>
        </p>


        <br>
        <p>
          <p-checkbox binary="true" [(ngModel)]="useGeneralBilling"></p-checkbox><strong>General Billing: </strong>
        </p>
        <p>Select one service fee of two ways as below:</p>
        <p style="margin-left: 20px"><p-checkbox binary="true" [(ngModel)]="useGeneralBillingProcessLegs" [disabled]="!useGeneralBilling"></p-checkbox> Based on process legs </p>
        <p *ngFor="let generalBillingProcessLeg of generalBillingProcessLegs; let i=index" >
          If every day the number of legs under <input pInputText type="number" [(ngModel)]="generalBillingProcessLeg.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useGeneralBillingProcessLegs"> legs, Licensor will charge <input pInputText type="number" [(ngModel)]="generalBillingProcessLeg.chargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useGeneralBillingProcessLegs">.
          If more than <input pInputText type="number" [(ngModel)]="generalBillingProcessLeg.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useGeneralBillingProcessLegs"> legs are checked on sign off, Licensor will charge <input pInputText type="number" [(ngModel)]="generalBillingProcessLeg.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useGeneralBillingProcessLegs">, from <p-calendar [(ngModel)]="generalBillingProcessLeg.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useGeneralBillingProcessLegs" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="generalBillingProcessLeg.from" [(ngModel)]="generalBillingProcessLeg.to" [disabled]="!useGeneralBillingProcessLegs" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="generalBillingProcessLegs.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeGeneralBillingProcessLeg(i)" [disabled]="!useGeneralBillingProcessLegs"></button>
          <button *ngIf="i==generalBillingProcessLegs.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addGeneralBillingProcessLeg()" [disabled]="!useGeneralBillingProcessLegs"></button>
        </p>
        <p style="margin-left: 20px"><p-checkbox binary="true" [(ngModel)]="useGeneralBillingPaidAmount"  [disabled]="!useGeneralBilling"></p-checkbox> Based on paid amount </p>
        <p *ngFor="let generalBillingPaidAmount of generalBillingPaidAmounts; let i=index">
          Operr will charge sevice fee based on paid amount <input pInputText type="number" [(ngModel)]="generalBillingPaidAmount.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useGeneralBillingPaidAmount"> %, Licensor will charge <input pInputText type="number" [(ngModel)]="generalBillingPaidAmount.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useGeneralBillingPaidAmount">, from <p-calendar [(ngModel)]="generalBillingPaidAmount.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useGeneralBillingPaidAmount" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="generalBillingPaidAmount.from" [(ngModel)]="generalBillingPaidAmount.to" [disabled]="!useGeneralBillingPaidAmount" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="generalBillingPaidAmounts.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeGeneralBillingPaidAmount(i)" [disabled]="!useGeneralBillingPaidAmount"></button>
          <button *ngIf="i==generalBillingPaidAmounts.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addGeneralBillingPaidAmount()" [disabled]="!useGeneralBillingPaidAmount"></button>
        </p>

        <br>
        <p>
          <p-checkbox binary="true" [(ngModel)]="useCorrection"></p-checkbox><strong>Correction:</strong>
        </p>
        <p>Select one service fee of two ways as below:</p>
        <p style="margin-left: 20px"><p-checkbox binary="true" [(ngModel)]="useCorrectionProcessLegs" [disabled]="!useCorrection"></p-checkbox> Based on process legs </p>
        <p *ngFor="let correctionProcessLeg of correctionProcessLegs; let i=index">
          If every day the number of legs under <input pInputText type="number" [(ngModel)]="correctionProcessLeg.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useCorrectionProcessLegs"> legs, Licensor will charge <input pInputText type="number" [(ngModel)]="correctionProcessLeg.chargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useCorrectionProcessLegs">.
          If more than <input pInputText type="number" [(ngModel)]="correctionProcessLeg.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useCorrectionProcessLegs"> trips are checked on sign off, Licensor will charge <input pInputText type="number" [(ngModel)]="correctionProcessLeg.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useCorrectionProcessLegs">, from <p-calendar [(ngModel)]="correctionProcessLeg.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useCorrectionProcessLegs" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="correctionProcessLeg.from" [(ngModel)]="correctionProcessLeg.to" [disabled]="!useCorrectionProcessLegs" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="correctionProcessLegs.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeCorrectionProcessLeg(i)" [disabled]="!useCorrectionProcessLegs"></button>
          <button *ngIf="i==correctionProcessLegs.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addCorrectionProcessLeg()" [disabled]="!useCorrectionProcessLegs"></button>
        </p>
        <p style="margin-left: 20px"><p-checkbox binary="true" [(ngModel)]="useCorrectionPaidAmount" [disabled]="!useCorrection"></p-checkbox> Based on paid amount </p>
        <p *ngFor="let correctionPaidAmount of correctionPaidAmounts; let i=index">
          Operr will charge sevice fee based on paid amount <input pInputText type="number" [(ngModel)]="correctionPaidAmount.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useCorrectionPaidAmount"> %, Licensor will charge <input pInputText type="number" [(ngModel)]="correctionPaidAmount.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useCorrectionPaidAmount">, from <p-calendar [(ngModel)]="correctionPaidAmount.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useCorrectionPaidAmount" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="correctionPaidAmount.from" [(ngModel)]="correctionPaidAmount.to" [disabled]="!useCorrectionPaidAmount" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="correctionPaidAmounts.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeCorrectionPaidAmount(i)" [disabled]="!useCorrectionPaidAmount"></button>
          <button *ngIf="i==correctionPaidAmounts.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addCorrectionPaidAmount()" [disabled]="!useCorrectionPaidAmount"></button>
        </p>

        <br>
        <p>
          <p-checkbox binary="true" [(ngModel)]="useLookback"></p-checkbox><strong>Look Back: </strong>
        </p>
        <p>Select one service fee of two ways as below:</p>
        <p style="margin-left: 20px"><p-checkbox binary="true" [(ngModel)]="useLookbackProcessLegs" [disabled]="!useLookback"></p-checkbox> Based on process legs </p>
        <p *ngFor="let lookbackProcessLeg of lookbackProcessLegs; let i=index">
          If every day the number of legs under <input pInputText type="number" [(ngModel)]="lookbackProcessLeg.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useLookbackProcessLegs"> legs, Licensor will charge <input pInputText type="number" [(ngModel)]="lookbackProcessLeg.chargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useLookbackProcessLegs">.
          If more than <input pInputText type="number" [(ngModel)]="lookbackProcessLeg.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useLookbackProcessLegs"> legs are checked on sign off, Licensor will charge <input pInputText type="number" [(ngModel)]="lookbackProcessLeg.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useLookbackProcessLegs">, from <p-calendar [(ngModel)]="lookbackProcessLeg.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useLookbackProcessLegs" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="lookbackProcessLeg.from" [(ngModel)]="lookbackProcessLeg.to" [disabled]="!useLookbackProcessLegs" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="lookbackProcessLegs.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeLookbackProcessLeg(i)" [disabled]="!useLookbackProcessLegs"></button>
          <button *ngIf="i==lookbackProcessLegs.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addLookbackProcessLeg()" [disabled]="!useLookbackProcessLegs"></button>
        </p>
        <p style="margin-left: 20px"><p-checkbox binary="true" [(ngModel)]="useLookbackPaidAmount" [disabled]="!useLookback"></p-checkbox> Based on paid amount </p>
        <p *ngFor="let lookbackPaidAmount of lookbackPaidAmounts; let i=index">
          Operr will charge sevice fee based on paid amount <input pInputText type="number" [(ngModel)]="lookbackPaidAmount.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useLookbackPaidAmount"> %, Licensor will charge <input pInputText type="number" [(ngModel)]="lookbackPaidAmount.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useLookbackPaidAmount">, from <p-calendar [(ngModel)]="lookbackPaidAmount.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useLookbackPaidAmount" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="lookbackPaidAmount.from" [(ngModel)]="lookbackPaidAmount.to" [disabled]="!useLookbackPaidAmount" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="lookbackPaidAmounts.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeLookbackPaidAmount(i)" [disabled]="!useLookbackPaidAmount"></button>
          <button *ngIf="i==lookbackPaidAmounts.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addLookbackPaidAmount()" [disabled]="!useLookbackPaidAmount"></button>
        </p>
        <p>
          <strong></strong>
        </p>

        <br>
        <p>
          <p-checkbox  binary="true"  [(ngModel)]="useCongestionReport"></p-checkbox> <strong>Congestion report: </strong>
        </p>
        <p *ngFor="let congestionReport of congestionReports; let i=index">
          If every day the number of legs under <input pInputText type="number" [(ngModel)]="congestionReport.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useCongestionReport"> legs, Licensor will charge <input pInputText type="number" [(ngModel)]="congestionReport.chargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useCongestionReport">.
          If more than <input pInputText type="number" [(ngModel)]="congestionReport.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useCongestionReport"> legs are checked on sign off, Licensor will charge <input pInputText type="number" [(ngModel)]="congestionReport.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useCongestionReport">, from <p-calendar [(ngModel)]="congestionReport.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useCongestionReport" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="congestionReport.from" [(ngModel)]="congestionReport.to" [disabled]="!useCongestionReport" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="congestionReports.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeCongestionReport(i)" [disabled]="!useCongestionReport"></button>
          <button *ngIf="i==congestionReports.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addCongestionReport()" [disabled]="!useCongestionReport"></button>
        </p>

        <br>
        <p>
          <p-checkbox  binary="true"  [(ngModel)]="useTLCReport"></p-checkbox><strong>TLC report: </strong>
        </p>
        <p *ngFor="let tlcReport of tlcReports; let i=index">
          If every day the number of legs under <input pInputText type="number" [(ngModel)]="tlcReport.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useTLCReport"> trips, Licensor will charge <input pInputText type="number" [(ngModel)]="tlcReport.chargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useTLCReport">.
          If more than <input pInputText type="number" [(ngModel)]="tlcReport.numberOfTrip" label="___" style="width: 50px;text-align: center" [disabled]="!useTLCReport"> legs are checked on sign off, Licensor will charge <input pInputText type="number" [(ngModel)]="tlcReport.rangeChargeAmount" label="___" style="width: 50px;text-align: center" [disabled]="!useTLCReport">, from <p-calendar [(ngModel)]="tlcReport.from" [showIcon]="true" [readonlyInput]="true"  [disabled]="!useTLCReport" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar> to <p-calendar [minDate]="tlcReport.from" [(ngModel)]="tlcReport.to" [disabled]="!useTLCReport" [showIcon]="true" [readonlyInput]="true" [style]="{'width':'150px'}" [inputStyle]="{'width':'100px'}"></p-calendar>
          <button *ngIf="tlcReports.length > 1" class="delete-button" pButton type="button" icon="fa fa-minus" (click)="removeTLCReport(i)" [disabled]="!useTLCReport"></button>
          <button *ngIf="i==tlcReports.length-1" class="margin-20" pButton type="button" icon="fa fa-plus" (click)="addTLCReport()" [disabled]="!useTLCReport"></button>
        </p>
      </div>


</div>
<p-footer>
  <div class="pull-right" style="margin-top: 30px">
    <button *ngIf="(!contractStatus || contractStatus.toString() === 'ALLOW_UPDATE')" pButton type="submit" class="ui-button-success" (click)="save()" label="Submit"
            [disabled]="formGroup.invalid || !enableSaveBtn || !isValidTermData"></button>

    <button *ngIf="((loginType ===1 || loginType===2) && contractStatus && (contractStatus.toString()==='NEW' || contractStatus.toString()==='IN_REVIEW'))" pButton type="submit" class="ui-button-success" (click)="allowEdit()" label="Release"
    ></button>
  </div>
</p-footer>
