import {Component, OnInit, OnDestroy} from '@angular/core';
import {Validators, UntypedFormBuilder} from '@angular/forms';
import {AuthService} from '@app/_auth';
import {Router} from '@angular/router';
import {LoginType, AppUrl} from '@app/_config/constant';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'opr-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  message = '';
  failedMessage;

  showContractDialog = false;

  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private router: Router,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.translate.use(this.translate.currentLang ? this.translate.currentLang : 'en');
  }

  defaultNavigate() {
    this.router.navigate([AppUrl.UPLOAD_FILE_PROCESS]);
    // switch (this.authService.getLoginType()) {
    //   case LoginType.BILLER_ADMIN:
    //   case LoginType.BILLER_USER:
    //     this.router.navigate([AppUrl.UPLOAD_FILE_PROCESS]);
    //     break;
    //   case LoginType.BASE_ADMIN:
    //   case LoginType.BASE_USER:
    //     this.router.navigate([AppUrl.MAS]);
    //     break;
    // }
  }

  onSignUp() {
    this.failedMessage = null;
    if (this.loginForm.valid) {
      this.authService.login(this.loginForm.value).subscribe(
        success => {
          this.defaultNavigate();
        },
        error => {
          console.log(error);
          switch (error.status) {
            case 0:
              this.message = 'Server Connection Error';
              break;
            case 404:
              this.message = 'Incorrect Username/Password';
              break;
            case 500:
              this.message = 'Internal Server Error';
              break;
            case 401:
              this.failedMessage = 'Wrong password. Try again or click Forgot password to reset it.';
              break;
            default:
              this.message = error.error || error.message;
          }
        });
    } else {
      this.message = '';
    }
  }

  openContractDialog() {
    this.showContractDialog = true;
  }

  onContractDialogDisplayChanged(showContract) {
    this.showContractDialog = showContract;
  }

  ngOnDestroy() {
  }

}
