export enum FIDELIS_URL {
  LOGIN = '/login',
  UPLOAD = '/app/upload',
  BILLER = '/app/upload/biller',
  CLAIM = '/app/fidelis/claim',
  CLAIM_RATE = '/app/fidelis/claim/rate',
  REPORT = '/app/fidelis/report',
  HELP = '/app/fidelis/help',
  INVOICE = '/app/fidelis/invoice',
  LOGISTICARE = '/app/logisticare',
  MTM = '/app/mtm',
  SENTRY = '/app/sentry',
  FIDELIS = '/app/fidelis',
  MY_ACCOUNT = '/app/myaccount',
  PAYMENT_METHOD = '/app/myaccount/payment',
  MY_CONTRACT = '/app/myaccount/contract-detail/info'
}
