import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomHttpHeaders } from './custom-http-headers.enum';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingIndicatorService } from '../service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  currentRequest = 0;

  constructor(private loadingIndicatorService: LoadingIndicatorService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.get(CustomHttpHeaders.DISABLE_LOADING_SPINNER)) {
      const loadingTimeout = req.headers.get('loadingTimeout');
      this.currentRequest++;
      this.loadingIndicatorService.setStatus(true, +loadingTimeout);
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (!req.headers.get(CustomHttpHeaders.DISABLE_LOADING_SPINNER)) {
          this.currentRequest--;
          if (this.currentRequest === 0) {
            this.loadingIndicatorService.setStatus(false);
          }
        }
      })
    );
  }
}
