import { MenuLabel } from './menu-label.enum';
import { AppUrl } from '../constant';

export const SIDE_NAV_MENU = [
  // Internal Menu
  {
    label: MenuLabel.FILE_TRANSFER, icon: 'dashboard', routerLink: AppUrl.FILE_TRANSFER, badgeStyleClass: 'file-transfer'
  },
  {
    label: MenuLabel.UPLOAD_FILE_PROCESS, icon: 'dashboard', routerLink: AppUrl.UPLOAD_FILE_PROCESS, badgeStyleClass: 'upload'
  },
  {
    label: MenuLabel.TRANSPORTATION, icon: 'dashboard', badgeStyleClass: 'transportation',
    items: [
      {
        label: MenuLabel.MAS,
        icon: 'dashboard',
        routerLink: AppUrl.MAS,
        badgeStyleClass: 'mas'
      },
      {
        label: MenuLabel.LOGISTICARE,
        icon: 'dashboard',
        routerLink: AppUrl.LOGISTICARE,
        badgeStyleClass: 'logisticare'
      },
      {
        label: MenuLabel.MTM,
        icon: 'dashboard',
        routerLink: AppUrl.MTM,
        badgeStyleClass: 'mtm'
      },
      {
        label: MenuLabel.SENTRY,
        icon: 'dashboard',
        routerLink: AppUrl.SENTRY,
        badgeStyleClass: 'sentry'
      },
      {
        label: MenuLabel.FIDELIS,
        icon: 'dashboard',
        routerLink: AppUrl.FIDELIS,
        badgeStyleClass: 'sentry'
      },
      {
        label: MenuLabel.CTS,
        icon: 'dashboard',
        routerLink: AppUrl.CTS,
        badgeStyleClass: 'cts'
      },
      {
        label: MenuLabel.CENTREK,
        icon: 'dashboard',
        routerLink: AppUrl.CENTREK,
        badgeStyleClass: 'centrek'
      },
      {
        label: MenuLabel.MODIVCARE,
        icon: 'dashboard',
        routerLink: AppUrl.MODIVCARE,
        badgeStyleClass: 'modivcare'
      }
    ]
  },
  {
    label: MenuLabel.INVOICE, icon: 'dashboard', routerLink: AppUrl.APP_INVOICE, badgeStyleClass: 'invoice'
  },
  {
    label: MenuLabel.DAYCARE, icon: 'dashboard', routerLink: AppUrl.DAYCARE, badgeStyleClass: 'day-care'
  },
  {
    label: MenuLabel.MY_ACCOUNT, icon: 'dashboard', badgeStyleClass: 'myaccount',
    items: [
      {
        label: 'View Account',
        icon: 'dashboard',
        routerLink: AppUrl.MY_ACCOUNT
      },{
        label: 'View Account',
        icon: 'dashboard',
        routerLink: AppUrl.BASE_SEARCH
      },
      {
        label: 'User',
        icon: 'account_box',
        routerLink: AppUrl.BILLING_USER
      },
      {
        label: 'ChangePassword',
        icon: 'security',
        routerLink: AppUrl.CHANGE_PASSWORD
      },
      {
        label: 'Payment Method',
        icon: 'dashboard',
        routerLink: AppUrl.PAYMENT_METHOD
      },
      {
        label: 'My Contract',
        icon: 'dashboard',
        routerLink: AppUrl.MY_CONTRACT
      },
      {
        label: 'User Tracking',
        icon: 'dashboard',
        routerLink: AppUrl.TRACKING
      },
      {
        label: MenuLabel.DRIVER_LIST,
        icon: 'dashboard',
        routerLink: AppUrl.DRIVER_LIST
      }
    ]
  }
  // {
  //   label: MenuLabel.ADMINISTRATIVE, icon: 'settings', routerLink: AppUrl.ADMINISTRATIVE,
  //   items: [
  //     {
  //       label: MenuLabel.ADMINISTRATIVE_ADMIN,
  //       icon: 'person',
  //       routerLink: AppUrl.ADMINISTRATIVE_ADMIN,
  //     },

  //     {
  //       label: MenuLabel.ADMINISTRATIVE_DOCUMENT_TEMPLATE,
  //       icon: 'book',
  //       routerLink: AppUrl.ADMINISTRATIVE_DOCUMENT_TEMPLATE,
  //     },
  //   ],
  // },
]
