import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { of, Subject } from 'rxjs';
import { ClaimPageType } from '@app/_config/constant';
import { mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClaimResolver implements Resolve<any> {

  dataSourceSubject = new Subject<any>();
  dataSource$ = this.dataSourceSubject.asObservable();

  constructor(private confirmService: ConfirmationService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.confirmService.confirm({
      message: 'Do you need sign off service?',
      accept: () => {
        this.dataSourceSubject.next(ClaimPageType.NEEDED);
      },
      reject: () => {
        this.dataSourceSubject.next(ClaimPageType.NOT_NEEDED);
      }
    });

    return this.dataSource$.pipe(
      take(1),
      mergeMap(e => {
        return of(e);
      })
    )
  }

}
