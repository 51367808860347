import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '@app/_auth';
import {tap} from 'rxjs/operators';

@Injectable()
export class AddHttpHeaderInterceptor implements HttpInterceptor {

  private authService: AuthService;

  constructor(private injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.authService = this.injector.get(AuthService);

    // Get the auth header from the service.
    const authHeader = this.authService.getToken();

    if (authHeader) {
      req = req.clone({headers: req.headers.set('Authorization', `${authHeader}`)});
    }
    return next.handle(req).pipe(
      tap((ev: HttpResponse<any>) => {
          this.authService.setToken(ev.headers && ev.headers.get('authorization'));
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            this.authService.redirectLogin();
            //   this.router.navigate([AppUrl.LOGIN]);
          }
        }
      )
    );
  }
}
