import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {FileUploadObjectType} from "@app/_config/constant";

@Injectable({
  providedIn: 'root'
})
export class AppNoticationService {
  private static readonly URL = environment.api.server;

  constructor(private http: HttpClient) {
  }

  listNotication() {
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000').set('X-Spinner', 'true')
    };
    return this.http.get(`${AppNoticationService.URL}/notication`, headerOption);
  }

  ackNotification() {
    const headerOption = {
      headers: new HttpHeaders().set('timeout', '1200000').set('X-Spinner', 'true')
    };
    return this.http.get(`${AppNoticationService.URL}/notication/ack`, headerOption);
  }

}
