import { Directive, ElementRef, Inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import * as _ from 'lodash';

import { LoginType } from '@app/_config/constant';
import { LOGIN_TYPE } from '../helper';

@Directive({
    selector: '[oprPermissionAllowed]',
})
export class PermissionAllowedDirective implements OnInit {
    @Input()
    set oprPermissionAllowed(val: any) {
        this.permissions = val;
        this.updateView();
    }

    private permissions: LoginType | LoginType[];

    constructor(private element: ElementRef, private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef, @Inject(LOGIN_TYPE) private loginType: LoginType) {
    }

    ngOnInit() {
    }

    private updateView() {
        if (_.isArray(this.permissions) && _.indexOf(this.permissions, this.loginType) > -1) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else if (this.loginType === this.permissions) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
