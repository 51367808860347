import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'environments/environment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  private readonly URL = environment.api.root;

  constructor(private http: HttpClient) { }

  getActionTracking(options: any) {
    options.timeRange.startTime = moment
      .utc(options.timeRange.startTime)
      .local()
      .format('MM/DD/YYYY 00:00:00');
    options.timeRange.endDate = moment
      .utc(options.timeRange.endDate)
      .local()
      .format('MM/DD/YYYY 23:59:59');
    return this.http.post(`${this.URL}/api/v1/tracking/search`, options);
  }

  getUsers() {
    return this.http.get(`${this.URL}/api/v1/tracking/getUsers`);
  }

  createPaymentLogs(options: any) {
    return this.http.post(`${this.URL}/api/v1/mas/billing/payment`, options);
  }
}
