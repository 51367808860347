import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as _ from 'lodash';

@Injectable()
export class AesUtilService {
  readonly password = 'OPERR-V3';
  readonly keySize = 128;
  readonly ivSize = 128;
  readonly iterations = 65536;

  constructor() {
  }

  encrypt(text: string): string | undefined {
    if (!_.isString(text)) {
      return;
    }
    const salt = Array(40).fill(0).map(() => _.random(15).toString(16)).join('');
    const iv = Array(32).fill(0).map(() => _.random(15).toString(16)).join('');

    const key = CryptoJS.PBKDF2(this.password, CryptoJS.enc.Hex.parse(salt), {
      keySize: this.keySize / 32,
      iterations: this.iterations,
    });

    const encrypted = CryptoJS.AES.encrypt(text, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    return CryptoJS.enc.Hex.parse(salt + iv + encrypted.ciphertext.toString()).toString(CryptoJS.enc.Base64);
  }

  decrypt(encrypted: string): string | undefined {
    if (!_.isString(encrypted)) {
      return;
    }

    const packet = CryptoJS.enc.Base64.parse(encrypted);
    const salt = packet.toString().slice(0, 40);
    const iv = packet.toString().slice(40, 72);
    const cipherText = packet.toString().slice(72);

    const key = CryptoJS.PBKDF2(this.password, CryptoJS.enc.Hex.parse(salt), {
      keySize: this.keySize / 32,
      iterations: this.iterations,
    });

    const decrypted = CryptoJS.AES.decrypt({
      ciphertext: CryptoJS.enc.Hex.parse(cipherText),
      salt: '',
    } as any, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

}
